import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Cookies from "universal-cookie";

import Appbar from "./Features/Appbar/Appbar";
import SessionExpired from "./Features/Dialog/SessionExpired";

import DigitalTwin from "./Pages/DigitalTwin/DigitalTwin";
import Map from "./Pages/Map/Map";

import Components from "./Static/Data/_Common/Components";

import theme from "./Theme/BaseTheme";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";

import {
  RemoveCookies,
  IsSessionExpired,
} from "./Helper/CookieAndNav/CookieAndNav";
import { useSelector } from "react-redux";
import DigitalTwinsList from "./Pages/DigitalTwinsList/DigitalTwinList";
import AddConfiguration from "./PagesContent/DigitalTwinList/AddConfiguration";
import BatteryOnBoardingForm from "./PagesContent/DigitalTwinList/BatteryOnBoardingForm";

function App() {
  const cookies = new Cookies();

  const [LoggedIn, setLoggedIn] = useState(false);
  const isSessionExpired = useSelector((state) => state.SessionExpired.value);

  function CheckIfSessionExpired() {
    if (isSessionExpired) {
      IsSessionExpired();
    }
  }

  CheckIfSessionExpired();

  useEffect(() => {
    CheckIfSessionExpired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSessionExpired,
    window.performance.getEntriesByType("navigation")[0].type,
  ]);

  if (
    (!cookies.get("access_token") && !cookies.get("name")) ||
    cookies.get("pagesMeta") === undefined ||
    cookies.get("finalCall")
  ) {
    if (cookies.get("finalCall")) {
      RemoveCookies();
    }

    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/reset_password" element={<ResetPassword />} />

            <Route
              path="/"
              exact
              element={<Login setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="*"
              element={<Login setLoggedIn={setLoggedIn} LoggedIn={LoggedIn} />}
            />
          </Routes>
        </ThemeProvider>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Appbar appbarLinks={cookies.get("pagesMeta")} />
        <Routes>
          <Route path="/specificBattery" element={<DigitalTwin />} />

          {cookies.get("pagesMeta").map((item, index) => {
            if (cookies.get("pages").includes(item["mykey"])) {
              if (
                item["queryParam"] &&
                Object.keys(item["queryParam"]).length !== 0
              ) {
                const queryParam = Object.entries(item["queryParam"]);
                const path =
                  item["to"] + "/" + queryParam[0][0] + "=" + queryParam[0][1];
                const Component = Components[item["mykey"]];
                const permissions = item.permissions;
                return (
                  <Route
                    path={path}
                    exact
                    strict
                    element={
                      <Component
                        batteryType={queryParam[0][1]}
                        permissions={permissions}
                      />
                    }
                    key={index}
                  />
                );
              } else {
                const path = item["to"];
                const Component = Components[item["mykey"]];
                const permissions = item.permissions;
                return (
                  <Route
                    path={path}
                    exact
                    element={
                      <Component
                        pagesContent={cookies.get("pagesMeta")}
                        permissions={permissions}
                      />
                    }
                    key={index}
                  />
                );
              }
            }
          })}
          <Route path="*" element={<Map />} />
          <Route
            path="/digitalTwin/add-config"
            exact
            strict
            element={
              <AddConfiguration
                pagesContent={cookies.get("pagesMeta")}
                permissions={
                  cookies
                    .get("pagesMeta")
                    ?.find((x) => x.mykey === "DigitalTwin")?.permissions
                }
              />
            }
          />
          <Route
            path="/digitalTwin/add-battery"
            exact
            strict
            element={
              <BatteryOnBoardingForm
                pagesContent={cookies.get("pagesMeta")}
                permissions={
                  cookies
                    .get("pagesMeta")
                    ?.find((x) => x.mykey === "DigitalTwin")?.permissions
                }
              />
            }
          />
        </Routes>
        {isSessionExpired && (
          <SessionExpired isSessionExpired={isSessionExpired} />
        )}
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
