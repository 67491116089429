import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Typography, Box, IconButton } from "@mui/material";
import { Tooltip } from "antd";
import { Brightness1 } from "@mui/icons-material";

import {
  DateFormatString,
  formatDateWithoutYear,
  monthDictionary,
} from "../../Helper/DatePicker/DateFormatters";
import {
  convertToZoneDate,
  getChartTimeStamp,
} from "../../Helper/DatePicker/DateConverter";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useDispatch } from "react-redux";
import { SetSessionExpired } from "../../Actions";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Close } from "@mui/icons-material";

import moment from "moment";
import Loader from "../../Helper/Loaders/Loader";
import "./ApexMultiYAxis.css";
import "./charts.css";
import FindTheSeverity from "../../Helper/Operations/FindTheSeverity";
import SlickCarouselPackMetrics from "../../Features/Carousel/SlickCarouselPackMetrics";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { getGranularityData, getPackComparision } from "../../Api/DigitalTwin";
import { combineWithUnderscore } from "../../Helper/Formatters/TextFormatter";

function ApexMultiYAxis(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = decryptTheParams();
  const newCity = localStorage.getItem("LocationFilter")?.split(",");

  const statusClicked = props.statusClicked;
  const setStatusClicked = props.setStatusClicked;

  const [batteryPackMetrics, setBatteryPackMetrics] = useState({
    batteryStatus: [],
    soc: [],
    temperature: [],
    timestamp: [],
    voltage: [],
    current: [],
    latitude: [],
    longitude: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [batteryAlertsList, setBatteryAlertsList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  const [colors, setColors] = useState([
    statusClicked.Current ? ThemeProperties.Current : "transparent",
    statusClicked.Voltage ? ThemeProperties.Voltage : "transparent",
    statusClicked.SOC ? ThemeProperties.SOC : "transparent",
    statusClicked.Temperature ? ThemeProperties.Max_Temperature : "transparent",
  ]);

  const [annotation, showAnnotation] = useState(true);
  const handleToggleAnno = (event) => {
    showAnnotation(event.target.checked);
  };

  const [modalData, setModalData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

  const [divStyle, setDivStyle] = useState({
    top: 0,
    left: 0,
    height: "180px",
    width: "350px",
    padding: "10px",
  });

  const [mounted, setMounted] = useState(false);

  function handleStatusClicked(status) {
    setColors([
      status === "Current (A)" ? ThemeProperties.Current : "transparent",
      status === "Voltage (V)" ? ThemeProperties.Voltage : "transparent",
      status === "Current (A)" ? ThemeProperties.SOC : "transparent",
      status === "Temperature (°C)"
        ? ThemeProperties.Max_Temperature
        : "transparent",
    ]);
    if (
      statusClicked.Voltage &&
      statusClicked.Current &&
      statusClicked.SOC &&
      statusClicked.Temperature
    ) {
      setStatusClicked({
        Voltage: status === "Voltage (V)" ? true : !statusClicked.Voltage,
        Current: status === "Current (A)" ? true : !statusClicked.Current,
        SOC: status === "SOC (%)" ? true : !statusClicked.SOC,
        Temperature:
          status === "Temperature (°C)" ? true : !statusClicked.Temperature,
      });
    } else {
      setStatusClicked({
        Voltage:
          status === "Voltage (V)" &&
          (statusClicked.Current ||
            statusClicked.SOC ||
            statusClicked.Temperature)
            ? !statusClicked.Voltage
            : statusClicked.Voltage,
        Current:
          status === "Current (A)" &&
          (statusClicked.Voltage ||
            statusClicked.SOC ||
            statusClicked.Temperature)
            ? !statusClicked.Current
            : statusClicked.Current,
        SOC:
          status === "SOC (%)" &&
          (statusClicked.Current ||
            statusClicked.Voltage ||
            statusClicked.Temperature)
            ? !statusClicked.SOC
            : statusClicked.SOC,
        Temperature:
          status === "Temperature (°C)" &&
          (statusClicked.Current || statusClicked.SOC || statusClicked.Voltage)
            ? !statusClicked.Temperature
            : statusClicked.Temperature,
      });
    }
  }

  useEffect(() => {
    setColors([
      statusClicked.Current ? ThemeProperties.Current : "transparent",
      statusClicked.Voltage ? ThemeProperties.Voltage : "transparent",
      statusClicked.SOC ? ThemeProperties.SOC : "transparent",
      statusClicked.Temperature
        ? ThemeProperties.Max_Temperature
        : "transparent",
    ]);
  }, [statusClicked]);

  function Legends() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 1,
        }}
      >
        {[
          {
            status: "Current (A)",
            color: ThemeProperties.Current,
            target: statusClicked.Current,
          },
          {
            status: "Voltage (V)",
            color: ThemeProperties.Voltage,
            target: statusClicked.Voltage,
          },
          {
            status: "SOC (%)",
            color:
              props.hierarchy.level === "module" ||
              props.hierarchy.level === "cell"
                ? "#ececec"
                : ThemeProperties.SOC,
            target: statusClicked.SOC,
          },
          {
            status: "Temperature (°C)",
            color: ThemeProperties.Max_Temperature,
            target: statusClicked.Temperature,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              cursor:
                item.status === "SOC (%)" &&
                (props.hierarchy.level === "module" ||
                  props.hierarchy.level === "cell")
                  ? "default"
                  : "pointer",
            }}
            onClick={() => {
              if (
                item.status === "SOC (%)" &&
                (props.hierarchy.level === "module" ||
                  props.hierarchy.level === "cell")
              ) {
              } else {
                handleStatusClicked(item.status);
              }
            }}
          >
            {item.status === "SOC (%)" &&
            (props.hierarchy.level === "module" ||
              props.hierarchy.level === "cell") ? (
              <Tooltip title={"SOC disabled for cell and module"}>
                <Brightness1
                  style={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    color: "#ececec",
                  }}
                />
              </Tooltip>
            ) : (
              <Brightness1
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  color: item.color,
                  opacity: item.target ? 1 : 0.4,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }

  function handleClosePopup() {
    setPopupVisible(false);
    let temp = {
      ...queryParams,
      alertFirstSeenOn: "",
    };
    encryptTheParams(temp, navigate, true);
  }

  function mountTheChart(
    current,
    voltage,
    soc,
    temperature,
    batteryStatus,
    timestamp,
    latitude,
    longitude,
    newAnnot,
    newData
  ) {
    const handleShowModal = (
      x,
      y,
      xData,
      yData,
      borderColor,
      textData,
      alertType
    ) => {
      const obj = newAnnot.find((item) => new Date(item.x).getTime() === xData);

      setDivStyle({
        ...divStyle,
        top: "250px",
        left: x - 400,
      });
      setModalData(obj.data);
    };

    var my_date = DateFormatString(props.startDate);

    const unique = [
      ...new Map(newAnnot.map((item) => [item.x, item])).values(),
    ];

    const new_series = [
      {
        name: "Current (A)",
        data: [],
      },
      {
        name: "Voltage (V)",
        data: [],
      },
      {
        name: "SOC (%)",
        data: [],
      },
      {
        name: "Temperature (°C)",
        data: [],
      },
    ];

    if (statusClicked.Current) {
      new_series[0]["data"] = current;
    }
    if (statusClicked.Voltage) {
      new_series[1]["data"] = voltage;
    }
    if (statusClicked.SOC) {
      new_series[2]["data"] = soc;
    }
    if (statusClicked.Temperature) {
      new_series[3]["data"] = temperature;
    }

    setChartState({
      text: "",
      series: new_series,
      options: {
        chart: {
          animations: {
            enabled: false,
          },
          type: "line",
          zoom: {
            enabled: true,
            type: "x",
            minZoom: 10,
          },
          resetIcon: {
            offsetX: -10,
            offsetY: 5,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: "zoom",
            // autoSelected: false,
          },
          events: {
            beforeZoom: function (chart, { xaxis }) {
              if (props.granular === "Raw Data") {
                const MIN_ZOOM_RANGE = 15 * 60 * 1000; // 15 minutes in milliseconds
                let min = xaxis.min;
                let max = xaxis.max;

                if (max - min >= 86400000) {
                  return {
                    xaxis: {
                      tickPlacement: "on",
                      min: new Date(props.startDate).getTime() - 19800000,
                      max:
                        new Date(props.startDate).getTime() +
                        86400000 -
                        19800000,
                      labels: {
                        format: "h TT",
                      },
                    },
                  };
                }

                if (max - min < MIN_ZOOM_RANGE) {
                  let newmax = min + MIN_ZOOM_RANGE;

                  return {
                    xaxis: {
                      tickPlacement: "on",
                      min: min,
                      max: newmax,
                      labels: {
                        format: "h:mm TT",
                      },
                    },
                  };
                } else {
                  return {
                    xaxis: {
                      tickPlacement: "on",
                      min: min,
                      max: max,
                      labels: {
                        format: "h:mm TT",
                      },
                    },
                  };
                }
              }
            },

            beforeResetZoom: function (chartContext, opts) {
              if (props.granular === "Raw Data") {
                return {
                  xaxis: {
                    tickPlacement: "on",
                    min: new Date(props.startDate).getTime() - 19800000,
                    max:
                      new Date(props.startDate).getTime() + 86400000 - 19800000,
                    labels: {
                      format: "h TT",
                    },
                  },
                };
              }
            },
            click: function (event, chartContext, config) {
              if (event?.target?.parentNode?.id) {
                config.config.annotations.points.forEach((annot) => {
                  // Comparing id of svg element
                  // eslint-disable-next-line eqeqeq
                  if (annot?.customSVG?.id == event?.target?.parentNode?.id) {
                    handleShowModal(
                      event.x,
                      event.y,
                      annot.x,
                      annot.y,
                      annot.label.borderColor,
                      annot.customSVG.text
                    );
                    setPopupVisible(true);
                  }
                });
              } else {
                setPopupVisible(false);
              }
            },
          },
        },
        annotations: {
          xaxis: [
            ...unique.map((data) => {
              return {
                x: new Date(data.x).getTime(),
                borderColor: "#ececec",
                borderWidth: 2,
                width: "100px",

                label: {
                  borderColor: "#ececec",
                  borderWidth: 2,
                  offsetY: data.y,
                  orientation: "horizontal",
                  style: {
                    color: "#000",
                    fill: "#fff",
                    cssClass: "yAxisAnnot",
                    padding: {
                      left: 7,
                      right: 7,
                      top: 5,
                      bottom: 5,
                    },
                  },
                  borderRadius: "50%",
                },
              };
            }),
          ],
          points: [
            ...unique.map((item, key) => {
              return {
                x: new Date(item.x).getTime(),
                y: item.y,

                marker: {
                  size: 8,
                  fillColor: "#fff",
                  strokeColor: item.borderColor,
                  // radius: 1,
                  cssClass: "apexcharts-custom-class",
                  cursor: "pointer",
                },

                label: {
                  borderColor: item.color,
                  offsetY: 20,
                  style: {
                    color: "#fff",
                    background: "#FF4560",
                    cursor: "pointer",
                  },
                  size: 8,

                  borderRadius: "100%",

                  cssClass: "annotClass",
                  // text:"0",
                },

                customSVG: {
                  SVG: `<svg id=${key} width='18' height='18' viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg' version='1.1' >
                  
                  <circle cx="64" cy="64" r="50" fill="#fff" stroke="#000" stroke-width="2"/>
                  <text text-anchor="middle" x="50%" y="50%" dy=".35em" font-family="sans-serif" font-size="80px" fill="#000" >${item.text}</text>
                  
                  </svg>`,
                  cssClass: "svgClass",
                  offsetX: -9,
                  offsetY: -9,
                  pointValue: item.x,
                  id: key,
                  text: item.text,
                },
              };
            }),
          ],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2.5,
          curve: "smooth",
          // dashArray: [0, 2, 5, 8],
        },
        grid: {
          padding: {
            top: 10,
            left: -41,
            right: -37,
          },
        },
        title: {
          text: undefined,
        },
        colors: colors,
        markers: {
          size: 0,
          strokeWidth: 0,
          hover: {
            size: 4,
            sizeOffset: 1,
          },
        },
        yaxis: [
          {
            seriesName: "Current (A)",
            axisTicks: {
              show: true,
              borderType: "solid",
              color: ThemeProperties.c_axis_labels_color,
              offsetX: 3,
            },
            min: props?.current?.min,
            max: props?.current?.max,
            tickAmount: 5,
            axisBorder: {
              show: true,
              color: statusClicked.Current
                ? ThemeProperties.c_axis_labels_color
                : ThemeProperties.grey,
              offsetX: -3,
            },
            labels: {
              style: {
                colors: statusClicked.Current
                  ? [ThemeProperties.c_axis_labels_color]
                  : [ThemeProperties.grey],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -50,
            },
            title: {
              text: "Current (A)",
              offsetX: 11,
              style: {
                color: statusClicked.Current
                  ? ThemeProperties.Current
                  : "rgba(79, 68, 224,0.2)",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "Voltage (V)",
            axisTicks: {
              show: true,
              borderType: "solid",
              color: ThemeProperties.c_axis_labels_color,
            },
            min: props?.voltage?.min,
            max: props?.voltage?.max,
            tickAmount: 5,
            axisBorder: {
              show: true,
              color: statusClicked.Voltage
                ? ThemeProperties.c_axis_labels_color
                : ThemeProperties.grey,
              // offsetX:-10
            },
            labels: {
              style: {
                colors: statusClicked.Voltage
                  ? [ThemeProperties.c_axis_labels_color]
                  : [ThemeProperties.grey],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -34,
            },
            title: {
              text: "Voltage (V)",
              offsetX: 9,
              style: {
                color: statusClicked.Voltage
                  ? ThemeProperties.Voltage
                  : "rgba(255, 117, 87,0.2)",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "SOC (%)",
            max: props?.soc?.max,
            min: props?.soc?.min,
            tickAmount: 5,
            opposite: true,
            axisTicks: {
              show: true,
              borderType: "solid",
              color:
                props.hierarchy.level === "module" ||
                props.hierarchy.level === "cell"
                  ? "#ececec"
                  : ThemeProperties.c_axis_labels_color,
            },
            axisBorder: {
              show: true,
              color:
                props.hierarchy.level === "module" ||
                props.hierarchy.level === "cell"
                  ? "#ececec"
                  : statusClicked.SOC
                    ? ThemeProperties.c_axis_labels_color
                    : ThemeProperties.grey,
            },
            labels: {
              style: {
                colors:
                  props.hierarchy.level === "module" ||
                  props.hierarchy.level === "cell"
                    ? [ThemeProperties.darkerGrey]
                    : statusClicked.SOC
                      ? [ThemeProperties.c_axis_labels_color]
                      : [ThemeProperties.grey],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -47,
            },
            title: {
              text: "SOC (%)",
              offsetX: -12,
              style: {
                color:
                  props.hierarchy.level === "module" ||
                  props.hierarchy.level === "cell"
                    ? ThemeProperties.darkerGrey
                    : statusClicked.SOC
                      ? ThemeProperties.SOC
                      : "rgba(248, 188, 59,0.2)",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
          },
          {
            seriesName: "Temperature (°C)",
            max: props?.temperature?.max,
            min: props?.temperature?.min,
            tickAmount: 5,
            opposite: true,
            axisTicks: {
              show: true,
              borderType: "solid",
              color: ThemeProperties.c_axis_labels_color,
            },
            axisBorder: {
              show: true,
              color: statusClicked.Temperature
                ? ThemeProperties.c_axis_labels_color
                : ThemeProperties.grey,
            },
            labels: {
              style: {
                colors: statusClicked.Temperature
                  ? [ThemeProperties.c_axis_labels_color]
                  : [ThemeProperties.grey],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -30,
            },
            title: {
              text: "Temperature (°C)",
              offsetX: -1,
              style: {
                color: statusClicked.Temperature
                  ? ThemeProperties.Max_Temperature
                  : "rgba(34, 144, 135,0.2)",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
          },
        ],
        xaxis: {
          type: props.granular === "Raw Data" ? "datetime" : "category",
          // tickAmount: 12,
          tickPlacement: "on",
          categories: timestamp,
          tooltip: {
            enabled: false,
          },
          title: {
            text: undefined,
          },
          labels: {
            show: true,
            datetimeUTC: false,
            rotateAlways: false,
            rotate: 0,
            // align: "center",
            style: {
              colors: ThemeProperties.c_axis_labels_color,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            offsetX: 0,
            offsetY: 0,
            format: props.granular === "Raw Data" ? "h TT" : undefined,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: ThemeProperties.c_axis_labels_color,
          },
          axisBorder: {
            show: true,
            color: ThemeProperties.c_axis_labels_color,
            height: 0.8,
          },

          min:
            props.granular === "Raw Data"
              ? new Date(my_date).getTime()
              : undefined,
          max:
            props.granular === "Raw Data"
              ? new Date(my_date).getTime() + 86400000
              : undefined,
        },
        tooltip: {
          followCursor: true,

          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data =
              props.granular === "Raw Data"
                ? moment(w.globals.lastXAxis.categories[dataPointIndex]).format(
                    "DD MMM'YY, hh:mm:ss A"
                  )
                : w.globals.categoryLabels[dataPointIndex];

            let current, volt, soc, temp;
            let currentClass, voltClass, socClass, tempClass;
            let currentFlexClass, voltFlexClass, socFlexClass, tempFlexClass;
            let currentDotClass, voltDotClass, socDotClass, tempDotClass;
            let currentBrClass, voltBrClass, socBrClass, tempBrClass;

            if (series[0][dataPointIndex] === undefined) {
              current = 0;
              currentClass = "displaying_None";
              currentDotClass = "displaying_None";
              currentFlexClass = "displaying_None";
              currentBrClass = "displaying_Br_None";
            } else {
              current = series[0][dataPointIndex];
              currentClass = "text_container";
              currentDotClass = "dot_purple";
              currentFlexClass = "flex_center";
              currentBrClass = "displaying_Br";
            }
            if (series[1][dataPointIndex] === undefined) {
              volt = 0;
              voltClass = "displaying_None";
              voltDotClass = "displaying_None";
              voltFlexClass = "displaying_None";
              voltBrClass = "displaying_Br_None";
            } else {
              volt = series[1][dataPointIndex];
              voltClass = "text_container";
              voltDotClass = "dot_red";
              voltFlexClass = "flex_center";
              voltBrClass = "displaying_Br";
            }
            if (series[2][dataPointIndex] === undefined) {
              soc = 0;
              socClass = "displaying_None";
              socDotClass = "displaying_None";
              socFlexClass = "displaying_None";
              socBrClass = "displaying_Br_None";
            } else {
              soc = series[2][dataPointIndex];
              socClass = "text_container";
              socDotClass = "dot_yellow";
              socFlexClass = "flex_center";
              socBrClass = "displaying_Br";
            }
            if (series[3][dataPointIndex] === undefined) {
              temp = 0;
              tempClass = "displaying_None";
              tempDotClass = "displaying_None";
              tempFlexClass = "displaying_None";
              tempBrClass = "displaying_Br_None";
            } else {
              temp = series[3][dataPointIndex];
              tempClass = "text_container";
              tempDotClass = "dot_green";
              tempFlexClass = "flex_center";
              tempBrClass = "displaying_Br";
            }

            if (
              props.hierarchy.level === "module" ||
              props.hierarchy.level === "cell"
            ) {
              let tCurrent = current === null ? "" : current?.toFixed(1),
                tVolt = volt === null ? "" : volt?.toFixed(3),
                tTemp = temp === null ? "" : temp?.toFixed(1);
              return (
                "<div >" +
                "<span>" +
                '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
                data +
                "</div>" +
                "</span>" +
                // "</br>" +

                '<div class="tooltip_container2">' +
                `<span class=${currentBrClass}></span>` +
                `<span class=${currentFlexClass}>` +
                `<span class=${currentDotClass}></span>` +
                `<span class=${currentClass}>&nbsp; Current (A) <b>: ` +
                tCurrent +
                "</b></span>" +
                "</span>" +
                `<span class=${voltBrClass}></span>` +
                `<span class=${voltFlexClass}>` +
                `<span class=${voltDotClass}></span>` +
                `<span class=${voltClass}>&nbsp; Voltage (V) <b>: ` +
                tVolt +
                "</b></span>" +
                "</span>" +
                `<span class=${tempBrClass}></span>` +
                `<span class=${tempFlexClass}>` +
                `<span class=${tempDotClass}></span>` +
                `<span class=${tempClass}>&nbsp; Temperature (°C)<b>: ` +
                tTemp +
                "</b></span>" +
                "</span>" +
                "</div>" +
                "</div>"
              );
            } else {
              let tCurrent = current === null ? "" : current?.toFixed(2),
                tVolt = volt === null ? "" : volt?.toFixed(3),
                tSoc = soc === null ? "" : soc?.toFixed(1),
                tTemp = temp === null ? "" : temp?.toFixed(1);

              return (
                "<div >" +
                "<span>" +
                '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
                data +
                "</div>" +
                "</span>" +
                // "</br>" +

                '<div class="tooltip_container2">' +
                `<span class=${currentBrClass}></span>` +
                `<span class=${currentFlexClass}>` +
                `<span class=${currentDotClass}></span>` +
                `<span class=${currentClass}>&nbsp; Current (A) <b>: ` +
                tCurrent +
                "</b></span>" +
                "</span>" +
                `<span class=${voltBrClass}></span>` +
                `<span class=${voltFlexClass}>` +
                `<span class=${voltDotClass}></span>` +
                `<span class=${voltClass}>&nbsp; Voltage (V) <b>: ` +
                tVolt +
                "</b></span>" +
                "</span>" +
                `<span class=${socBrClass}></span>` +
                `<span class=${socFlexClass}>` +
                `<span class=${socDotClass}></span>` +
                `<span class=${socClass}>&nbsp; SOC (%)<b>: ` +
                tSoc +
                "</b></span>" +
                "</span>" +
                `<span class=${tempBrClass}></span>` +
                `<span class=${tempFlexClass}>` +
                `<span class=${tempDotClass}></span>` +
                `<span class=${tempClass}>&nbsp; Temperature (°C) <b>: ` +
                tTemp +
                "</b></span>" +
                "</span>" +
                "</div>" +
                "</div>"
              );
            }
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        legend: {
          show: false,
        },
      },
    });

    setMounted(true);
  }

  useEffect(() => {
    let queryParams = decryptTheParams();

    const packID =
      props.hierarchy.level === "pack"
        ? combineWithUnderscore(
            queryParams["batteryID"],
            props.hierarchy.selectedPack.value,
            props.hierarchy.selectedModule.value,
            props.hierarchy.selectedCell.value
          )
        : "";

    setBatteryAlertsList({
      data: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    setBatteryPackMetrics({
      batteryStatus: [],
      soc: [],
      temperature: [],
      timestamp: [],
      voltage: [],
      current: [],
      latitude: [],
      longitude: [],
      responseStatus: { code: -1, msg: "" },
    });

    if (
      props.granular === "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") === -1
    ) {
      getPackComparision(
        props.batteryID,
        props.hierarchy.selectedPack.value,
        props.hierarchy.selectedModule.value,
        props.hierarchy.selectedCell.value,
        props.hierarchy.level,
        ["temperature", "current", "soc", "voltage"],
        [props.startDate, props.startDate]
      ).then((res) => {
        let tempStatus = [],
          tempSoc = [],
          tempTemperature = [],
          tempTimestamp = [],
          tempVoltage = [],
          tempLat = [],
          tempLong = [],
          tempCurrent = [];
        // let csvData = [];
        if (res?.responseStatus?.code === 200) {
          res?.response?.batteryData?.forEach((item) => {
            tempSoc.push(
              item["metricsArray"].find((i) => i.type === "soc")?.value === null
                ? null
                : item["metricsArray"].find((i) => i.type === "soc")?.value
            );
            tempCurrent.push(
              item["metricsArray"].find((i) => i.type === "current")?.value ===
                undefined
                ? null
                : item["metricsArray"].find((i) => i.type === "current")?.value
            );
            tempVoltage.push(
              item["metricsArray"].find((i) => i.type === "voltage")?.value ===
                undefined
                ? null
                : item["metricsArray"].find((i) => i.type === "voltage")?.value
            );
            tempTemperature.push(
              item["metricsArray"].find((i) => i.type === "temperature")
                ?.value === undefined
                ? null
                : item["metricsArray"].find((i) => i.type === "temperature")
                    ?.value
            );
            tempStatus.push("");

            tempTimestamp.push(getChartTimeStamp(item.batteryDateTime));

            // tempLat.push(item.latitude);
            // tempLong.push(item.longitude);
          });
          setBatteryPackMetrics({
            batteryStatus: tempStatus,
            soc: tempSoc,
            temperature: tempTemperature,
            timestamp: tempTimestamp,
            voltage: tempVoltage,
            current: tempCurrent,
            latitude: tempLat,
            longitude: tempLong,
            responseStatus: {
              code: res?.responseStatus.code,
              msg: res?.responseStatus.msg,
            },
          });
        } else {
          if (res?.responseStatus?.code === -2)
            dispatch(SetSessionExpired(true));
          setBatteryPackMetrics({
            batteryStatus: [],
            soc: [],
            temperature: [],
            timestamp: [],
            voltage: [],
            current: [],
            latitude: [],
            longitude: [],
            responseStatus: {
              code: res?.responseStatus.code,
              msg: res?.responseStatus.msg,
            },
          });
        }
      });
    } else if (
      props.granular !== "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") !== -1
    ) {
      let date = props.startDate.split(":");
      getGranularityData(
        props.batteryID,
        props.hierarchy.selectedPack.value,
        props.hierarchy.selectedModule.value,
        props.hierarchy.selectedCell.value,
        props.hierarchy.level,
        ["SOC", "temperature", "CURRENT", "VOLTAGE"],
        [date[0], date[1]],
        props.granular,
        props.operationType
      ).then((res) => {
        let tempStatus = [],
          tempSoc = [],
          tempTemperature = [],
          tempTimestamp = [],
          tempVoltage = [],
          tempCurrent = [];
        if (res?.responseStatus?.code === 200) {
          res?.response?.batteryData?.forEach((item) => {
            let tempX =
              props.granular === "Daily"
                ? formatDateWithoutYear(
                    convertToZoneDate(item["range"]["startDate"])
                  )
                : props.granular === "Monthly"
                  ? monthDictionary.find(
                      (o) => o.name === item["range"]["startDate"].split("-")[1]
                    )["value"]
                  : [
                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["startDate"])
                        ) + " - ",
                      ],

                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["endDate"])
                        ),
                      ],
                    ];
            tempSoc.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "soc")?.value ===
                null
                  ? null
                  : item["metricsArray"].find((i) => i.type === "soc")?.value,
            });
            tempTemperature.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "temperature")
                  ?.value === undefined
                  ? null
                  : item["metricsArray"].find((i) => i.type === "temperature")
                      ?.value,
            });
            tempVoltage.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "voltage")
                  ?.value === undefined
                  ? null
                  : item["metricsArray"].find((i) => i.type === "voltage")
                      ?.value,
            });
            var tempCurrentItem = item["metricsArray"].find(
              (i) => i.type === "current"
            );
            tempCurrent.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "current")
                  ?.value === undefined
                  ? null
                  : item["metricsArray"].find((i) => i.type === "current")
                      ?.value,
            });
            tempTimestamp.push(tempX);
          });

          setBatteryPackMetrics({
            batteryStatus: tempStatus,
            soc: tempSoc,
            temperature: tempTemperature,
            timestamp: tempTimestamp,
            voltage: tempVoltage,
            current: tempCurrent,
            latitude: [],
            longitude: [],
            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus?.msg,
            },
          });
        } else {
          if (res?.responseStatus?.code === -2)
            dispatch(SetSessionExpired(true));
          res?.response?.batteryData?.forEach((item) => {
            let tempX =
              props.granular === "Daily"
                ? formatDateWithoutYear(
                    convertToZoneDate(item["range"]["startDate"])
                  )
                : props.granular === "Monthly"
                  ? monthDictionary.find(
                      (o) => o.name === item["range"]["startDate"].split("-")[1]
                    )["value"]
                  : [
                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["startDate"])
                        ) + " - ",
                      ],

                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["endDate"])
                        ),
                      ],
                    ];

            tempSoc.push({
              x: tempX,
              y: null,
            });
            tempCurrent.push({
              x: tempX,
              y: null,
            });
            tempVoltage.push({
              x: tempX,
              y: null,
            });
            tempTemperature.push({
              x: tempX,
              y: null,
            });
            tempStatus.push({
              x: tempX,
              y: null,
            });

            tempTimestamp.push(tempX);

            // tempLat.push(item.latitude);
            // tempLong.push(item.longitude);
          });
          setBatteryPackMetrics({
            batteryStatus: tempStatus,
            soc: tempSoc,
            temperature: tempTemperature,
            timestamp: tempTimestamp,
            voltage: tempVoltage,
            current: tempCurrent,
            latitude: [],
            longitude: [],
            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus.msg,
            },
          });
        }
      });
    }
    // .then(() => {
    //   getTableBatteries(
    //     -1,
    //     newOrg,
    //     newCity,
    //     newZone,
    //     newStatus,
    //     newPack,
    //     newIOT,
    //     [packID],

    //     "",
    //     "",
    //     "",
    //     "",
    //     queryParams["batteryID"],
    //     "",
    //     "Device ID",

    //     1,
    //     "errorTime",
    //     "desc",
    //     999999
    //   ).then((res) => {
    //     if (res.responseStatus.code === 200) {
    //       setBatteryAlertsList({
    //         data: res.response.errorList,
    //         responseStatus: {
    //           code: res.responseStatus.code,
    //           msg: res.responseStatus.msg,
    //         },
    //       });
    //     } else {
    //       if (res.responseStatus.code === -2)
    //         dispatch(SetSessionExpired(true));

    //       setBatteryAlertsList({
    //         data: [],
    //         responseStatus: {
    //           code: res.responseStatus.code,
    //           msg: res.responseStatus.msg,
    //         },
    //       });
    //     }
    //   });
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.startDate,
    props.granular,
    props.operationType,
    props.hierarchy.selectedPack,
    props.hierarchy.selectedModule,
    props.hierarchy.selectedCell,
  ]);

  useEffect(() => {
    setMounted(false);
    setPopupVisible(false);
    if (
      batteryPackMetrics.responseStatus.code === 200 ||
      batteryPackMetrics.responseStatus.code === 1999
    ) {
      var start = new Date(`${props.startDate} 00:00:00`);
      var end = new Date(`${props.startDate} 23:59:59`);

      var slices = {},
        buckets = [],
        finalBuckets = [];
      var count = 0;
      // var moment;

      const minutesBucket = 15 * 60 * 1000;

      while (end >= start) {
        start = new Date(start.getTime() + minutesBucket); // in minutes
        slices[count] = start;
        count++;
      }
      buckets = Object.values(slices);
      finalBuckets.push({
        start: new Date(start.getTime() - 86400000),
        end: new Date(start.getTime() + minutesBucket - 86400000),
      });
      for (var i = 0; i < buckets.length - 1; i = i + 1) {
        finalBuckets.push({
          start: buckets[i],
          end: buckets[i + 1],
          data: [],
        });
      }

      let data = batteryAlertsList.data;

      let newData = [];
      data.forEach((item) => {
        newData.push({
          ...item,
          insertionTime: moment(getChartTimeStamp(item.errorTime)).format(
            "YYYY-MM-DD HH:mm"
          ),
        });
      });
      finalBuckets.forEach((item) => {
        let finalBucketData = item.data ? item.data : [];
        for (var i = 0; i < newData.length; i++) {
          if (
            new Date(newData[i]["insertionTime"]).getTime() <=
              item.end.getTime() &&
            new Date(newData[i]["insertionTime"]).getTime() >
              item.start.getTime()
          ) {
            finalBucketData.push({
              alertDescription: newData[i]["errorDescription"],
              alertStatus: newData[i]["errorStatus"],
              statusChangedTime: newData[i]["statusChangedTime"],
              itemID: newData[i]["itemID"],
              severity: FindTheSeverity(newData[i]["severity"]),
              insertionTime: newData[i]["insertionTime"],
            });
          }
        }

        let filtered = finalBucketData?.sort(function (a, b) {
          return new Date(a.insertionTime) - new Date(b.insertionTime);
        });
        item.data = filtered;
      });

      const actualAnnotations = finalBuckets.filter(
        (item) => item.data?.length > 0
      );
      let nreAnnot = [];
      actualAnnotations.forEach((item) => {
        const isPresent = item.data.findIndex(
          (i) =>
            i.insertionTime ===
            moment(getChartTimeStamp(props.alertFirstSeenOn)).format(
              "YYYY-MM-DD HH:mm"
            )
        );

        nreAnnot.push({
          x: item.start,
          y: 22,
          color: ThemeProperties.black,
          text: item.data?.length,
          data: item.data,
          borderColor: isPresent === -1 ? "black" : "red",
        });
      });
      // if (annotation)
      //   mountTheChart(
      //     batteryPackMetrics.current,
      //     batteryPackMetrics.voltage,
      //     batteryPackMetrics.soc,
      //     batteryPackMetrics.temperature,
      //     batteryPackMetrics.batteryStatus,
      //     batteryPackMetrics.timestamp,
      //     batteryPackMetrics.latitude,
      //     batteryAlertsList.longitude,
      //     nreAnnot,
      //     newData
      //   );
      // else
      mountTheChart(
        batteryPackMetrics.current,
        batteryPackMetrics.voltage,
        batteryPackMetrics.soc,
        batteryPackMetrics.temperature,
        batteryPackMetrics.batteryStatus,
        batteryPackMetrics.timestamp,
        batteryPackMetrics.latitude,
        batteryAlertsList.longitude,
        [],
        []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batteryPackMetrics.responseStatus.code,
    batteryAlertsList.data,
    statusClicked,
    colors,
    annotation,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    queryParams["alertFirstSeenOn"],
  ]);

  useEffect(
    () => () => {
      setMounted(false);
      setChartState({
        text: "",
        series: [],
        options: {},
      });
      setBatteryPackMetrics({
        batteryStatus: [],
        soc: [],
        temperature: [],
        timestamp: [],
        voltage: [],
        current: [],
        latitude: [],
        longitude: [],
        responseStatus: { code: -1, msg: "" },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div id="key-metrics-pack">
      {(batteryPackMetrics.responseStatus.code === 200 ||
        batteryPackMetrics.responseStatus.code === 1999) &&
      mounted ? (
        <div>
          <div style={{ position: "relative", height: "400px" }}>
            {batteryPackMetrics.responseStatus.code === 1999 && (
              <div
                style={{
                  position: "absolute",
                  left: "41.5%",
                  top: "46%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ececec",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="textSubtitle">
                    No data found for current selection
                  </Typography>
                </div>
              </div>
            )}
            <Chart
              options={chartState.options}
              series={chartState.series}
              type="line"
              height="100%"
              width="100%"
              id={
                props.granular === "Raw Data"
                  ? "hideAlternateLabel"
                  : "donthide"
              }
            />
            {/* <div className="topBox_packMetrics"></div> */}
            {popupVisible && (
              <div className="popUpContainer" style={divStyle}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <div></div>
                  <IconButton onClick={() => handleClosePopup()}>
                    <Close className="closeIcon" />
                  </IconButton>
                </div>
                <div className="popupContent">
                  <Box>
                    <SlickCarouselPackMetrics modalData={modalData} />
                  </Box>
                </div>
              </div>
            )}
          </div>
          <Box>{Legends()}</Box>
        </div>
      ) : (
        <div style={{ height: "400px" }}>
          {batteryPackMetrics.responseStatus.code === -1 ? (
            <Loader />
          ) : (
            <>
              {(batteryPackMetrics.responseStatus.code === 1999 &&
                props.granular === "Raw Data") ||
                (batteryPackMetrics.responseStatus.code === 404 && (
                  <>
                    <div style={{ position: "relative", height: "400px" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "41.5%",
                          top: "46%",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#ececec",
                            padding: "10px",
                            borderRadius: "20px",
                          }}
                        >
                          <Typography variant="textSubtitle">
                            No data found for selected date
                          </Typography>
                        </div>
                      </div>
                      <Chart
                        options={{
                          chart: {
                            type: "line",
                            zoom: {
                              enabled: false,
                            },
                            toolbar: {
                              show: false,
                              offsetY: -20,
                              tools: {
                                download: false,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: [],
                              },
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            width: 0,
                            curve: "smooth",
                            // dashArray: [0, 2, 5, 8],
                          },
                          grid: {
                            padding: {
                              top: 10,
                              left: -35,
                              right: -37,
                            },
                          },
                          title: {
                            text: undefined,
                          },
                          colors: [
                            ThemeProperties.Current,
                            ThemeProperties.Voltage,
                            ThemeProperties.SOC,
                            ThemeProperties.Max_Temperature,
                          ],
                          markers: {
                            size: 0,
                          },
                          yaxis: [
                            {
                              seriesName: "Current (A)",
                              axisTicks: {
                                show: true,
                                borderType: "solid",
                                color: ThemeProperties.c_axis_labels_color,
                                offsetX: 3,
                              },
                              min: props?.current?.min,
                              max: props?.current?.max,
                              tickAmount: 5,
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                                offsetX: -3,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -45,
                              },
                              title: {
                                text: "Current (A)",
                                offsetX: 11,
                                style: {
                                  color: ThemeProperties.Current,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                            {
                              seriesName: "Voltage (V)",
                              axisTicks: {
                                show: true,
                                borderType: "solid",
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              min: props?.voltage?.min,
                              max: props?.voltage?.max,
                              tickAmount: 5,
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                                // offsetX:-10
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -30,
                              },
                              title: {
                                text: "Voltage (V)",
                                offsetX: 5,
                                style: {
                                  color: ThemeProperties.Voltage,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                            {
                              seriesName: "SOC (%)",
                              max: props?.soc?.max,
                              min: props?.soc?.min,
                              tickAmount: 5,
                              opposite: true,
                              axisTicks: {
                                show: true,
                                borderType: "solid",
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -47,
                              },
                              title: {
                                text: "SOC (%)",
                                offsetX: -12,
                                style: {
                                  color: ThemeProperties.SOC,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                            },
                            {
                              seriesName: "Temperature (°C)",
                              max: props?.temperature?.max,
                              min: props?.temperature?.min,
                              tickAmount: 5,
                              opposite: true,
                              axisTicks: {
                                show: true,
                                borderType: "solid",
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -30,
                              },
                              title: {
                                text: "Temperature (°C)",
                                offsetX: -1,
                                style: {
                                  color: ThemeProperties.Max_Temperature,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                            },
                          ],
                          xaxis: {
                            categories: [
                              "12 AM",
                              "1 AM",
                              "2 AM",
                              "3 AM",
                              "4 AM",
                              "5 AM",
                              "6 AM",
                              "7 AM",
                              "8 AM",
                              "9 AM",
                              "10 AM",
                              "11 AM",
                              "12 PM",
                              "1 PM",
                              "2 PM",
                              "3 PM",
                              "4 PM",
                              "5 PM",
                              "6 PM",
                              "7 PM",
                              "8 PM",
                              "9 PM",
                              "10 PM",
                              "11 PM",
                              "12 AM",
                            ],

                            // tickAmount:7,

                            tooltip: {
                              enabled: false,
                            },
                            title: {
                              text: undefined,
                              // offsetX: 0,
                              // offsetY: 0,
                              // style: {
                              //   colors: [ThemeProperties.c_axis_title_color],
                              //   fontSize: ThemeProperties.c_axis_title,
                              //   fontFamily: "Roboto",
                              //   fontWeight: ThemeProperties.c_axis_title_weight,
                              // },
                            },
                            labels: {
                              show: true,
                              rotate: 0,
                              datetimeUTC: false,
                              rotateAlways: false,
                              align: "center",
                              style: {
                                colors: ThemeProperties.c_axis_labels_color,
                                fontSize: ThemeProperties.c_axis_labels,
                                fontFamily: "Roboto",
                                fontWeight:
                                  ThemeProperties.c_axis_labels_weight,
                              },
                              offsetX: 0,
                              offsetY: 0,
                              format: "h TT",
                            },
                            axisTicks: {
                              show: true,
                              borderType: "solid",
                              color: ThemeProperties.c_axis_labels_color,
                            },
                            axisBorder: {
                              show: true,
                              color: ThemeProperties.c_axis_labels_color,
                              height: 0.8,
                            },

                            // min: new Date(my_date).getTime(),
                            // max: new Date(my_date).getTime()+86400000
                          },
                          tooltip: {
                            enabled: false,
                            enabledOnSeries: undefined,
                            shared: true,
                            followCursor: false,
                            intersect: false,
                            inverseOrder: false,
                            custom: undefined,
                            fillSeriesColor: false,
                            theme: false,
                            style: {
                              style: {
                                colors: [ThemeProperties.c_axis_labels_color],
                                fontSize: ThemeProperties.c_axis_labels,
                                fontFamily: "Roboto",
                                fontWeight:
                                  ThemeProperties.c_axis_labels_weight,
                              },
                            },
                            onDatasetHover: {
                              highlightDataSeries: false,
                            },
                            x: {
                              show: true,
                              format: "dd MMM yyyy hh:mm:ss TT",
                              formatter: undefined,
                              title: {
                                formatter: (seriesName) => seriesName,
                              },
                            },
                            y: [
                              {
                                seriesName: "Current (A)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "Voltage (V)",
                                formatter: function (value) {
                                  return ": " + Math.round(value);
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "SOC (%)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "Temperature (°C)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                            ],
                            z: {
                              formatter: undefined,
                              title: "Size: ",
                            },
                            marker: {
                              show: true,
                            },

                            fixed: {
                              enabled: false,
                              position: "topRight",
                              offsetX: 0,
                              offsetY: 0,
                            },
                          },
                          axisBorder: {
                            show: false,
                          },
                          axisTicks: {
                            show: false,
                          },
                          legend: {
                            show: false,
                            // colors: [ThemeProperties.c_legend_labels_color],
                            // fontSize: ThemeProperties.c_legend_labels,
                            // fontFamily: "Roboto",
                            // fontWeight: ThemeProperties.c_legend_labels_weight,

                            // offsetY: 7,
                          },
                        }}
                        series={[
                          {
                            name: "Current (A)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "Voltage (V)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "SOC (%)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "Temperature (°C)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                        ]}
                        type="line"
                        height="100%"
                        width="100%"
                        id="hideAlternateLabel"
                      />
                    </div>
                    {/* <Box sx={{height:"60px"}}> */}
                    {/* <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: ThemeProperties.c_axis_title,
                          fontFamily: "Roboto",
                          fontWeight: ThemeProperties.c_axis_title_weight,
                          color: ThemeProperties.c_axis_title_color,
                        }}
                      >
                        Time
                      </Typography> */}
                    {/* {Legends()} */}
                    {/* </Box> */}
                  </>
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ApexMultiYAxis;
