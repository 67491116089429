import React, { useEffect, useState } from "react";
import DashboardMain from "./DashboardMain";
import { getPagesComponents } from "../../Api/Api";
import { Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  socFilter,
  socFilter1,
  socFilter2,
  partner,
  partner1,
  partner2,
} from "../../Static/Data/_Common/TempFilters";
import copy from "copy-to-clipboard";
import { Box, Typography, IconButton } from "@mui/material";
// import { Button, Input, Checkbox } from "antd";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch } from "react-redux";
import {
  SetIsBatteryIsPartnerIsNull,
  SetDashboardVolt,
  SetDashboardCategory,
  SetDashboardCity,
  SetDashboardZone,
  SetDashboardStatus,
  SetDashboardColumns,
  SetDashboardHiddenColumns,
  SetDashboardType,
  SetDashboardStartDate,
  SetDashboardEndDate,
  SetDashboardBatteryID,
  SetDashboardPartner,
  SetDashboardTabValue,
  SetDashboardBatteryPartnerSelect,
  SetDashboardSeverity,
  SetDashboardIOT,
  SetDashboardPack,
  SetDashboardStack,
} from "../../Actions/Dashboard";
import { SetSessionExpired } from "../../Actions";
import Events from "../../Analytics/Events";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import SecondsFormatter from "../../Helper/Formatters/SecondsFormatter";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import NoInternet from "../../Features/Dialog/NoInternet";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import FindTheSeverity from "../../Helper/Operations/FindTheSeverity";

function getActualArrayValues(data)
{
  let arr =
  data.length === 0 ? [] : data.split(",");
  return arr
}

function Dashboard(props) {
  const dispatch = useDispatch();
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    searchTypeFilter: {},
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });
  // const searchInput = useRef(null);
  const tempSocFilter = [...socFilter],
    tempPartnerFilter = [...partner],
    tempSocFilter1 = [...socFilter1],
    tempPartnerFilter1 = [...partner1],
    tempSocFilter2 = [...socFilter2],
    tempPartnerFilter2 = [...partner2];
  tempSocFilter.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempSocFilter1.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter1.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempSocFilter2.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });
  tempPartnerFilter2.forEach((item) => {
    if (item.isChecked) {
      item.isChecked = false;
    }
  });

  function renderBatteryIDCopy(item,name) {
    return (
      <>
      {item && <>
        <Typography variant="tableRow">{item}</Typography>
        <Tooltip title={"Click to copy"}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              copy(item);
              Events(`Copied ${name}`);
            }}
          >
            <ContentCopyIcon
              sx={{
                fontSize: "13px",
                cursor: "pointer",
                "&:hover": {
                  color: ThemeProperties.purple,
                },
              }}
            />
          </IconButton>
        </Tooltip>
        </>}
      </>
    );
  }

  useEffect(() => {
    const newBatteryType =
      props.batteryType === undefined || props.batteryType === ""
        ? ""
        : props.batteryType;
    setTimeout(() => {
      getPagesComponents("Alerts").then((res) => {
        dispatch(SetDashboardType(newBatteryType));
        dispatch(
          SetDashboardStack([
            {
              alertCode: "sft_temp_chrg",
              alertDescription: "High temperature during charging",
              itemIDs: [],
            },
            {
              alertCode: "sft_temp_drv",
              alertDescription: "High temperature inside vehicle",
              itemIDs: [],
            },
            {
              alertCode: "sft_temp_idl",
              alertDescription: "High temperature in warehouse",
              itemIDs: [],
            },
            {
              alertCode: "sft_volt",
              alertDescription: "Over voltage protection breach",
              itemIDs: [],
            },
            {
              alertCode: "sft_cc",
              alertDescription: "Cold charging",
              itemIDs: [],
            },
            {
              alertCode: "sft_cur_chrg",
              alertDescription: "Abnormally high current during charging",
              itemIDs: [],
            },
            {
              alertCode: "sft_cur_drv",
              alertDescription: "Abnormally high current during driving",
              itemIDs: [],
            },
            {
              alertCode: "sft_cur_fluc_chrg",
              alertDescription: "High current fluctuation during charging",
              itemIDs: [],
            },
            {
              alertCode: "sft_cur_high_soc",
              alertDescription: "High current at full voltage during charging",
              itemIDs: [],
            },

            {
              alertCode: "ops_low_volt_vehicle",
              alertDescription: "Low voltage in vehicle",
              itemIDs: [],
            },
            {
              alertCode: "ops_low_volt_warehouse",
              alertDescription: "Low voltage in warehouse",
              itemIDs: [],
            },
            {
              alertCode: "ops_ml_dd",
              alertDescription: "Potential deep discharge",
              itemIDs: [],
            },
            {
              alertCode: "ops_not_ping",
              alertDescription: "Battery not pinging",
              itemIDs: [],
            },

            {
              alertCode: "vln_fc",
              alertDescription: "Field charging",
              itemIDs: [],
            },
            {
              alertCode: "vln_nc",
              alertDescription: "Night charging",
              itemIDs: [],
            },
            {
              alertCode: "vln_uc",
              alertDescription: "Unauthorised charging",
              itemIDs: [],
            },
            {
              alertCode: "vln_ufc",
              alertDescription: "Unauthorised field charging",
              itemIDs: [],
            },
            // {
            //   alertCode: "vln_ic",
            //   alertDescription: "Incomplete Charging",
            //   itemIDs: [],
            // },
          ])
        );
        if (res && res.responseStatus.code === 200) {
          const filters = [
            {
              itemName: "Severity",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Super Critical",
                  value: "4",
                },
                {
                  key: "Critical",
                  value: "3",
                },
                {
                  key: "High",
                  value: "2",
                },
                {
                  key: "Low",
                  value: "1",
                },
              ],
            },
            {
              itemName: "Status",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Idle",
                  value: "0",
                },
                {
                  key: "Charging",
                  value: "4",
                },
                {
                  key: "Discharging",
                  value: "2",
                },
              ],
            },
            {
              itemName: "City",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Delhi",
                  value: "delhi",
                },
                {
                  key: "Faridabad",
                  value: "faridabad",
                },
                {
                  key: "Ghaziabad",
                  value: "ghaziabad",
                },
                {
                  key: "Gurgaon",
                  value: "gurgaon",
                },
                {
                  key: "Jaipur",
                  value: "jaipur",
                },
                {
                  key: "Kanpur",
                  value: "Kanpur",
                },
                {
                  key: "Karnal",
                  value: "Karnal",
                },
                {
                  key: "Lucknow",
                  value: "Lucknow",
                },
                {
                  key: "Meerut",
                  value: "Meerut",
                },
                {
                  key: "Noida",
                  value: "Noida",
                },
                {
                  key: "Panipat",
                  value: "Panipat",
                },
                {
                  key: "Sonipat",
                  value: "Sonipat",
                },
              ],
            },
            {
              itemName: "Zone",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Mayur Vihar",
                  value: "Mayur Vihar",
                },
                {
                  key: "Noida",
                  value: "Noida",
                },
                {
                  key: "South Delhi",
                  value: "South Delhi",
                },
                {
                  key: "Jankapuri Kirtinagar",
                  value: "Jankapuri Kirtinagar",
                },
                {
                  key: "Rohini",
                  value: "Rohini",
                },
                {
                  key: "Jahagirpuri",
                  value: "Jahagirpuri",
                },
                {
                  key: "Bawana",
                  value: "Bawana",
                },
                {
                  key: "Central Delhi",
                  value: "Central Delhi",
                },
                {
                  key: "Faridabad",
                  value: "Faridabad",
                },
                {
                  key: "Shahadra",
                  value: "Shahadra",
                },
                {
                  key: "Anand Vihar",
                  value: "Anand Vihar",
                },
                {
                  key: "Gurgaon",
                  value: "Gurgaon",
                },
              ],
            },

            {
              itemName: "IOT",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Wynncom",
                  value: "Wynncom",
                },
                {
                  key: "Intellicar",
                  value: "INTELLICAR",
                },
              ],
            },
            {
              itemName: "Pack",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Livguard",
                  value: "Livguard",
                },
              ],
            },
          ];

          let newFilterWithData = [];
          filters.forEach(
            (
              item //res.response.response.filter.filtersWithData
            ) => newFilterWithData.push(item["itemName"])
          );
          const filteredArray = newFilterWithData;

          const queryParams = decryptTheParams();

          for (var value of Object.keys(queryParams)) {
            if (filteredArray.includes(value)) {
              if (value === "Volt") {
                dispatch(SetDashboardVolt(+queryParams[value]));
              } else if (value === "City") {
                dispatch(SetDashboardCity(getActualArrayValues(queryParams[value])));
              } else if (value === "Zone") {
                dispatch(SetDashboardZone(getActualArrayValues(queryParams[value])));
              } else if (value === "Status") {
                dispatch(SetDashboardStatus(getActualArrayValues(queryParams[value])));
              } else if (value === "Severity") {
                dispatch(SetDashboardSeverity(getActualArrayValues(queryParams[value])));
              } else if (value === "IOT") {
                dispatch(SetDashboardIOT(getActualArrayValues(queryParams[value])));
              } else if (value === "Pack") {
                dispatch(SetDashboardPack(getActualArrayValues(queryParams[value])));
              }
            } else {
              if (value === "startDate") {
                dispatch(SetDashboardStartDate(queryParams[value]));
              } else if (value === "endDate") {
                dispatch(SetDashboardEndDate(queryParams[value]));
              } else if (value === "tabValue") {
                dispatch(SetDashboardTabValue(+queryParams[value]));
              } else if (value === "batteryPartnerSelect") {
                dispatch(SetDashboardBatteryPartnerSelect(queryParams[value]));
              } else if (value === "batteryID") {
                dispatch(SetDashboardBatteryID(queryParams[value]));
              } else if (value === "partner") {
                dispatch(SetDashboardPartner(queryParams[value]));
              } else if (value === "IsBatteryIsPartnerIsNull") {
                queryParams[value] === "null"
                  ? dispatch(SetIsBatteryIsPartnerIsNull(null))
                  : dispatch(SetIsBatteryIsPartnerIsNull(queryParams[value]));
              } else if (value === "Alert Type") {
                dispatch(SetDashboardCategory(queryParams[value]));
              }
            }
          }
          setPagesContent({
            ...pagesContent,
            pageHeader: props.batteryType
              ? res.response.response.textInfo[props.batteryType]["pageHeader"]
              : res.response.response.textInfo["pageHeader"],
            filtersWithData: filters, //res.response.response.filter.filtersWithData
            searchTypeFilter: { //res.response.response.filter.searchTypeFilter
              "items": [
                  "Battery ID",
                  "Occupant"
              ],
              "itemName": "Select"
          },
            filteredArray: filteredArray,
            batteryType: newBatteryType,
            alertType: [
              {
                itemName: "All",
                items: [
                  {
                    alertCode: "sft_temp_chrg",
                    alertDescription: "High temperature during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_temp_drv",
                    alertDescription: "High temperature inside vehicle",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_temp_idl",
                    alertDescription: "High temperature in warehouse",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_volt",
                    alertDescription: "Over voltage protection breach",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cc",
                    alertDescription: "Cold charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_chrg",
                    alertDescription: "Abnormally high current during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_drv",
                    alertDescription: "Abnormally high current during driving",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_fluc_chrg",
                    alertDescription:
                      "High current fluctuation during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_high_soc",
                    alertDescription:
                      "High current at full voltage during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },

                  {
                    alertCode: "ops_low_volt_vehicle",
                    alertDescription: "Low voltage in vehicle",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                  {
                    alertCode: "ops_low_volt_warehouse",
                    alertDescription: "Low voltage in warehouse",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                  {
                    alertCode: "ops_ml_dd",
                    alertDescription: "Potential deep discharge",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                  {
                    alertCode: "ops_not_ping",
                    alertDescription: "Battery not pinging",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                  {
                    alertCode: "vln_fc",
                    alertDescription: "Field charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  },
                  {
                    alertCode: "vln_nc",
                    alertDescription: "Night charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  },
                  {
                    alertCode: "vln_uc",
                    alertDescription: "Unauthorised charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  },
                  {
                    alertCode: "vln_ufc",
                    alertDescription: "Unauthorised field charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  }
                  // {
                  //   alertCode: "vln_ic",
                  //   alertDescription: "Incomplete Charging",
                  //   type: "Violations",
                  //   color: "#A1E9FF",
                  //   textColor: "#000",
                  // },
                ],
              },
              {
                itemName: "Safety Risk",
                items: [
                  {
                    alertCode: "sft_temp_chrg",
                    alertDescription: "High temperature during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_temp_drv",
                    alertDescription: "High temperature inside vehicle",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_temp_idl",
                    alertDescription: "High temperature in warehouse",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_volt",
                    alertDescription: "Over voltage protection breach",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cc",
                    alertDescription: "Cold charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_chrg",
                    alertDescription: "Abnormally high current during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_drv",
                    alertDescription: "Abnormally high current during driving",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_fluc_chrg",
                    alertDescription:
                      "High current fluctuation during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                  {
                    alertCode: "sft_cur_high_soc",
                    alertDescription:
                      "High current at full voltage during charging",
                    type: "Safety Risk",
                    color: "#CF0000",
                    textColor: "#fff",
                  },
                ],
              },
              {
                itemName: "Operational Issue",
                items: [
                  {
                    alertCode: "ops_low_volt_vehicle",
                    alertDescription: "Low voltage in vehicle",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                  {
                    alertCode: "ops_low_volt_warehouse",
                    alertDescription: "Low voltage in warehouse",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                  {
                    alertCode: "ops_ml_dd",
                    alertDescription: "Potential deep discharge",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                  {
                    alertCode: "ops_not_ping",
                    alertDescription: "Battery not pinging",
                    type: "Operational Issue",
                    color: "#FFD168",
                    textColor: "#000",
                  },
                ],
              },
              {
                itemName: "Violation",
                items: [
                  {
                    alertCode: "vln_fc",
                    alertDescription: "Field charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  },
                  {
                    alertCode: "vln_nc",
                    alertDescription: "Night charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  },
                  {
                    alertCode: "vln_uc",
                    alertDescription: "Unauthorised charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  },
                  {
                    alertCode: "vln_ufc",
                    alertDescription: "Unauthorised field charging",
                    type: "Violations",
                    color: "#A1E9FF",
                    textColor: "#000",
                  },
                  // {
                  //   alertCode: "vln_ic",
                  //   alertDescription: "Incomplete Charging",
                  //   type: "Violations",
                  //   color: "#A1E9FF",
                  //   textColor: "#000",
                  // },
                ],
              },
            ],
            mounted: true,
          });
        } else if (res && res.responseStatus.code === -2) {
          dispatch(SetSessionExpired(true));
        }
      });

      // const handleSearch = (
      //   selectedKeys,
      //   confirm,
      //   dataIndex,
      //   tabvalue,
      //   close
      // ) => {
      //   const tempColumns = [...columns];
      //   tempColumns[tabvalue]["columns"].forEach((col) => {
      //     if (col.dataIndex === dataIndex) {
      //       col.checkedOptions = [...selectedKeys];
      //     }
      //   });
      //   dispatch(SetDashboardColumns([...tempColumns]));
      //   close();
      // };
      // const handleReset = (
      //   setSelectedKeys,
      //   confirm,
      //   filters,
      //   dataIndex,
      //   tabvalue,
      //   close
      // ) => {
      //   const tempColumns = [...columns];
      //   filters.forEach((ele) => {
      //     ele.isChecked = false;
      //   });
      //   tempColumns[tabvalue]["columns"].forEach((col) => {
      //     if (col.dataIndex === dataIndex) {
      //       col.checkedOptions = [];
      //     }
      //   });
      //   setSelectedKeys([]);
      //   dispatch(SetDashboardColumns([...tempColumns]));
      //   close();
      // };
      // const onChangeFilter = (
      //   ele,
      //   dataIndex,
      //   selectedKeys,
      //   setSelectedKeys,
      //   filters
      // ) => {
      //   if (ele.isChecked) {
      //     filters.forEach((element) => {
      //       if (element === ele) {
      //         ele.isChecked = false;
      //       }
      //     });

      //     const arr =
      //       selectedKeys.length > 0
      //         ? selectedKeys.filter((item) => item !== ele.value)
      //         : [];

      //     setSelectedKeys(arr);
      //   } else {
      //     filters.forEach((element) => {
      //       if (element === ele) {
      //         ele.isChecked = true;
      //       }
      //     });

      //     if (selectedKeys[0] === undefined) {
      //       setSelectedKeys([(selectedKeys[0] = "")]);
      //     }
      //     setSelectedKeys([...selectedKeys, ele.value]);
      //   }
      //   var isFilterEmpty = true;
      //   filters.forEach((ele) => {
      //     if (ele.isChecked === true) {
      //       isFilterEmpty = false;
      //     }
      //   });

      //   if (isFilterEmpty) {
      //     setSelectedKeys([]);
      //   }
      // };

      // const handleSelectAllFilters = (
      //   e,
      //   filters,
      //   selectedKeys,
      //   setSelectedKeys,
      //   dataIndex
      // ) => {
      //   if (e.target.checked) {
      //     const arr = [];

      //     if (selectedKeys[0] === undefined) {
      //       setSelectedKeys([(selectedKeys[0] = "")]);
      //     }
      //     filters
      //       .filter((ele) => ele.value.includes(selectedKeys[0]))
      //       .forEach((ele, key) => {
      //         ele.isChecked = true;
      //         arr.push(ele.value);
      //       });

      //     setSelectedKeys([...selectedKeys, ...arr]);
      //   } else {
      //     filters.forEach((ele) => {
      //       ele.isChecked = false;
      //     });
      //     setSelectedKeys([]);
      //   }
      // };

      // let checker = (arr) => arr.every((v) => v === true);

      // const getColumnSearchProps = (dataIndex, tabvalue) => ({
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //     close,
      //     filters,
      //   }) => (
      //     <div
      //       style={{
      //         padding: 8,
      //       }}
      //       // onKeyDown={(e) => e.stopPropagation()}
      //       onClick={(e) => e?.stopPropagation()}
      //     >
      //       <Input
      //         ref={searchInput}
      //         placeholder={`Search ${dataIndex}`}
      //         value={selectedKeys[0]}
      //         onChange={(e) =>
      //           setSelectedKeys([
      //             (selectedKeys[0] = e.target.value),
      //             ...selectedKeys.splice(1),
      //           ])
      //         }
      //         style={{
      //           marginBottom: 8,
      //           display: "block",
      //         }}
      //       />

      //       {filters.filter((ele) =>
      //         ele.value.includes(
      //           selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //         )
      //       ).length != 0 ? (
      //         <div>
      //           <Checkbox
      //             onClick={(e) =>
      //               handleSelectAllFilters(
      //                 e,
      //                 filters,
      //                 selectedKeys,
      //                 setSelectedKeys,
      //                 dataIndex
      //               )
      //             }
      //             checked={
      //               filters.filter((ele) =>
      //                 ele.value.includes(
      //                   selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //                 )
      //               ).length != 0
      //                 ? checker(
      //                     filters
      //                       .filter((ele) =>
      //                         ele.value.includes(
      //                           selectedKeys[0] === undefined
      //                             ? ""
      //                             : selectedKeys[0]
      //                         )
      //                       )
      //                       .forEach((ele) => ele.isChecked === true)
      //                   )
      //                 : false
      //             }
      //           >
      //             Select all Items
      //           </Checkbox>

      //           <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
      //             {filters
      //               .filter((ele) =>
      //                 ele.value.includes(
      //                   selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //                 )
      //               )
      //               .forEach((ele, key) => {
      //                 return (
      //                   <>
      //                     <div>
      //                       <Checkbox
      //                         key={key}
      //                         checked={ele.isChecked}
      //                         onChange={(e) =>
      //                           onChangeFilter(
      //                             ele,
      //                             dataIndex,
      //                             selectedKeys,
      //                             setSelectedKeys,
      //                             filters
      //                           )
      //                         }
      //                       >
      //                         {ele.value}
      //                       </Checkbox>
      //                       <br></br>
      //                     </div>
      //                   </>
      //                 );
      //               })}
      //           </div>

      //           <div className="dropDownBottom">
      //             <Button
      //               onClick={() =>
      //                 handleReset(
      //                   setSelectedKeys,
      //                   confirm,
      //                   filters,
      //                   dataIndex,
      //                   tabvalue,
      //                   close
      //                 )
      //               }
      //             >
      //               Reset
      //             </Button>
      //             <Button
      //               type="primary"
      //               // disabled={selectedKeys.length>1?false:true}
      //               onClick={() =>
      //                 handleSearch(
      //                   selectedKeys,
      //                   confirm,
      //                   dataIndex,
      //                   tabvalue,
      //                   close
      //                 )
      //               }
      //             >
      //               Apply
      //             </Button>
      //           </div>
      //         </div>
      //       ) : (
      //         <p style={{ textAlign: "center" }}>No Options Found</p>
      //       )}
      //     </div>
      //   ),

      //   filterMultiple: true,

      //   onFilter: (value, record) => {
      //     if (value.length != 0) {
      //       return record[dataIndex]
      //         .toString()
      //         .toLowerCase()
      //         .includes(value.toLowerCase());
      //     }
      //   },

      //   onFilterDropdownOpenChange: (visible) => {
      //     const tempColumns = [...columns];
      //     if (!visible) {
      //       tempColumns[tabvalue]["columns"].forEach((col) => {
      //         if (col.dataIndex === dataIndex) {
      //           col.filters.forEach((item) => {
      //             if (col.checkedOptions.includes(item.text)) {
      //               item.isChecked = true;
      //             } else {
      //               item.isChecked = false;
      //             }
      //           });
      //         }
      //       });
      //       dispatch(SetDashboardColumns([...tempColumns]));
      //     } else {
      //       setTimeout(() => searchInput.current?.select(), 100);
      //     }
      //   },
      //   filterResetToDefaultFilteredValue: true,
      //   filterIcon: (filtered) => (
      //     <FilterAltIcon
      //       style={{
      //         color:
      //           columns[tabvalue]["columns"].filter(
      //             (element) => element.dataIndex === dataIndex
      //           )[0]["checkedOptions"].length > 0
      //             ? ThemeProperties.purple
      //             : undefined,
      //       }}
      //     />
      //   ),
      //   render: (text) => text,
      // });

      const columns = [
        {
          name: "Active Columns",
          columns: [
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Device ID
                </Typography>
              ),
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Battery ID
                </Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
              ),
            },
           
            
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Live"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeLiActive",
              align:"center",
              width: 80,
              render: (lastPingedTime) => (
                <CircleRoundedIcon
                  sx={{ color: ( new Date().getTime() - new Date(convertUTCtoZone(lastPingedTime)).getTime() ) / (1000 * 60) < 5 ? "green":"#A9A9A9", fontSize: "14px" }}
                />
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 110,
              uniqueKey: "socActive",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "currentActive",
              render: (currentPack) => (
                <Typography variant="tableRow">{(currentPack)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Volt (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 90,
              uniqueKey: "voltagePackActive",
              render: (voltagePack) => (
                <Typography variant="tableRow">{(voltagePack/1000).toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "temperature", "Temperature (°C)"),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureActive",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Severity
                </Typography>
              ),
              name: "Severity",
              dataIndex: "severity",
              key: "severity",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 130,
              uniqueKey: "severityActive",
              render: (severity) => (
                <Typography variant="tableRow">
                  {FindTheSeverity(severity)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "alertTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "alertTime",
              key: "alertTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "insertionTimeActive",
              width: 170,
              render: (alertTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(alertTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeActive",
              width: 170,
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Occupant
                </Typography>
              ),
              name: "Occupant",
              dataIndex: "partner",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              //filters: tempPartnerFilter,
              //...getColumnSearchProps("partner", 0),
              checkedOptions: [],
              //filterSearch: true,
              width: 170,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerActive",
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Lat/Long
                </Typography>
              ),
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "latitudeActive",
              width: 170,
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  City
                </Typography>
              ),
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityActive",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Zone
                </Typography>
              ),
              name: "Zone",
              dataIndex: "zone",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "zoneActive",
              width: 170,
              render: (zone) => (
                <Typography variant="tableRow">{zone}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Status
                </Typography>
              ),
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusActive",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">{batteryStatus}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  IOT
                </Typography>
              ),
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotActive",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Pack
                </Typography>
              ),
              name: "Pack",
              dataIndex: "batteryVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "packActive",
              width: 170,
              render: (batteryVendor) => (
                <Typography variant="tableRow">{batteryVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Alert Type
                </Typography>
              ),
              name: "Alert Type",
              dataIndex: "alertType",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "alertTypeActive",
              width: 170,
              render: (alertType) => (
                <Typography variant="tableRow">{alertType}</Typography>
              ),
            },

            {
              name: "",
            },
          ],
        },
        {
          name: "Open Columns",
          columns: [

            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Device ID
                </Typography>
              ),
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              sorter: false,
              uniqueKey: "batteryIDOpen",
              filterSearch: false,
              width: 170,
              fixed: "left",
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Battery ID
                </Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDOpen",
              render: (assetID) => (
                <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Live"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeLiOpen",
              align:"center",
              width: 80,
              render: (lastPingedTime) => (
                <CircleRoundedIcon
                sx={{ color: ( new Date().getTime() - new Date(convertUTCtoZone(lastPingedTime)).getTime() ) / (1000 * 60) < 5 ? "green":"#A9A9A9", fontSize: "14px" }}
                />
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              filterMode: "tree",
              //filterSearch: true,
              //filters: tempSocFilter1,
              //...getColumnSearchProps("socBMS", 1),
              checkedOptions: [],
              uniqueKey: "socOpen",
              width: 110,
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "currentOpen",
              render: (currentPack) => (
                <Typography variant="tableRow">{(currentPack)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Volt (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 90,
              uniqueKey: "voltagePackOpen",
              render: (voltagePack) => (
                <Typography variant="tableRow">{(voltagePack/1000).toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "temperature", "Temperature (°C)"),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureOpen",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Severity
                </Typography>
              ),
              name: "Severity",
              dataIndex: "severity",
              key: "severity",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 130,
              uniqueKey: "severityOpen",
              render: (severity) => (
                <Typography variant="tableRow">
                  {FindTheSeverity(severity)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Action Taken By
                </Typography>
              ),
              name: "Action Taken By",

              dataIndex: "name",
              key: "name",
              sorter: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "nameOpen",
              width: 170,
              render: (name) => (
                <Typography variant="tableRow">{name}</Typography>
              ),
              // width: "30%",
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "actionTime", "Action Taken On"),
              name: "Action Taken On",
              dataIndex: "actionTime",
              key: "actionTime",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "actionTimeOpen",
              width: 170,
              render: (actionTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(actionTime)}
                </Typography>
              ),
              // width: "30%",
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeOpen",
              width: 170,
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
              // width: "20%",
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Occupant
                </Typography>
              ),
              dataIndex: "partner",
              name: "Partner",
              sorter: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempPartnerFilter1,
              //...getColumnSearchProps("partner", 1),
              checkedOptions: [],
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerOpen",
              width: 170,
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
              // width: "30%",
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Lat/Long
                </Typography>
              ),
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "latitudeOpen",
              width: 170,
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
              // width: "20%",
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  City
                </Typography>
              ),
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityOpen",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Zone
                </Typography>
              ),
              name: "Zone",
              dataIndex: "zone",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "zoneOpen",
              width: 170,
              render: (zone) => (
                <Typography variant="tableRow">{zone}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Status
                </Typography>
              ),
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusOpen",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">{batteryStatus}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  IOT
                </Typography>
              ),
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotActive",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Pack
                </Typography>
              ),
              name: "Pack",
              dataIndex: "batteryVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "packOpen",
              width: 170,
              render: (batteryVendor) => (
                <Typography variant="tableRow">{batteryVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Alert Type
                </Typography>
              ),
              name: "Alert Type",
              dataIndex: "alertType",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "alertTypeOpen",
              width: 170,
              render: (alertType) => (
                <Typography variant="tableRow">{alertType}</Typography>
              ),
            },

            {
              name: "",
            },
          ],
        },
        {
          name: "Resolved Columns",
          columns: [
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Device ID
                </Typography>
              ),
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              sorter: false,
              uniqueKey: "batteryIDResolved",
              filterSearch: false,
              fixed: "left",
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Battery ID
                </Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDResolved",
              render: (assetID) => (
                <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
              ),
            },

            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Live"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedLiResolved",
              align:"center",
              width: 80,
              render: (lastPingedTime) => (
                <CircleRoundedIcon
                sx={{ color: ( new Date().getTime() - new Date(convertUTCtoZone(lastPingedTime)).getTime() ) / (1000 * 60) < 5 ? "green":"#A9A9A9", fontSize: "14px" }}
                />
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempSocFilter2,
              //...getColumnSearchProps("socBMS", 2),
              checkedOptions: [],
              width: 110,
              uniqueKey: "socResolved",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "resolvedActive",
              render: (currentPack) => (
                <Typography variant="tableRow">{(currentPack)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Volt (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 90,
              uniqueKey: "voltagePackResolved",
              render: (voltagePack) => (
                <Typography variant="tableRow">{(voltagePack/1000).toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "temperature", "Temperature (°C)"),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureResolved",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Severity
                </Typography>
              ),
              name: "Severity",
              dataIndex: "severity",
              key: "severity",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 130,
              uniqueKey: "severityResolved",
              render: (severity) => (
                <Typography variant="tableRow">
                  {FindTheSeverity(severity)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "statusChangedTime",
                  "Resolved On"
                ),
              name: "Resolved On",
              dataIndex: "statusChangedTime",
              key: "statusChangedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "statusChangedTimeResolved",
              render: (statusChangedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(statusChangedTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "alertTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "alertTime",
              key: "alertTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "lastPingedTimeActive",
              render: (alertTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(alertTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "deltaTime", "Resolution Time"),
              name: "Resolution Time",
              dataIndex: "deltaTime",
              key: "deltaTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 150,
              uniqueKey: "deltaTimeResolved",
              render: (deltaTime) => (
                <Typography variant="tableRow">
                  {SecondsFormatter(deltaTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimelastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Occupant
                </Typography>
              ),
              name: "Partner",
              dataIndex: "partner",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempPartnerFilter2,
              //...getColumnSearchProps("partner", 2),
              checkedOptions: [],
              width: 170,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerClosed",
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Lat/Long
                </Typography>
              ),
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimeLatitude",
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  City
                </Typography>
              ),
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityResolved",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Zone
                </Typography>
              ),
              name: "Zone",
              dataIndex: "zone",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "zoneResolved",
              width: 170,
              render: (zone) => (
                <Typography variant="tableRow">{zone}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Status
                </Typography>
              ),
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusResolved",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">{batteryStatus}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  IOT
                </Typography>
              ),
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotResolved",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Pack
                </Typography>
              ),
              name: "Pack",
              dataIndex: "batteryVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "packResolved",
              width: 170,
              render: (batteryVendor) => (
                <Typography variant="tableRow">{batteryVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Alert Type
                </Typography>
              ),
              name: "Alert Type",
              dataIndex: "alertType",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "alertTypeResolved",
              width: 170,
              render: (alertType) => (
                <Typography variant="tableRow">{alertType}</Typography>
              ),
            },

            {
              name: "",
            },
          ],
        },
        {
          name: "Ignored Columns",
          columns: [
            
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Device ID
                </Typography>
              ),
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDIgnored",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Battery ID
                </Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDIgnored",
              render: (assetID) => (
                <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Live"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeLiIgnored",
              align:"center",
              width: 80,
              render: (lastPingedTime) => (
                <CircleRoundedIcon
                sx={{ color: ( new Date().getTime() - new Date(convertUTCtoZone(lastPingedTime)).getTime() ) / (1000 * 60) < 5 ? "green":"#A9A9A9", fontSize: "14px" }}
                />
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempSocFilter2,
              //...getColumnSearchProps("socBMS", 2),
              checkedOptions: [],
              width: 110,
              uniqueKey: "socResolved",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "currentIgnored",
              render: (currentPack) => (
                <Typography variant="tableRow">{(currentPack)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Volt (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 90,
              uniqueKey: "voltagePackIgnored",
              render: (voltagePack) => (
                <Typography variant="tableRow">{(voltagePack/1000).toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "temperature", "Temperature (°C)"),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureIgnored",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Severity
                </Typography>
              ),
              name: "Severity",
              dataIndex: "severity",
              key: "severity",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 130,
              uniqueKey: "severityIgnored",
              render: (severity) => (
                <Typography variant="tableRow">
                  {FindTheSeverity(severity)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "statusChangedTime",
                  "Ignored Time"
                ),
              name: "Ignored Time",
              dataIndex: "statusChangedTime",
              key: "statusChangedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "statusChangedTimeIgnored",
              render: (statusChangedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(statusChangedTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "alertTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "alertTime",
              key: "alertTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "lastPingedTimeActive",
              render: (alertTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(alertTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimelastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Occupant
                </Typography>
              ),
              name: "Partner",
              dataIndex: "partner",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempPartnerFilter2,
              //...getColumnSearchProps("partner", 2),
              checkedOptions: [],
              width: 170,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerClosed",
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Lat/Long
                </Typography>
              ),
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimeLatitude",
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  City
                </Typography>
              ),
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityIgnored",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Zone
                </Typography>
              ),
              name: "Zone",
              dataIndex: "zone",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "zoneIgnored",
              width: 170,
              render: (zone) => (
                <Typography variant="tableRow">{zone}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Status
                </Typography>
              ),
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusIgnored",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">{batteryStatus}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  IOT
                </Typography>
              ),
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotIgnored",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Pack
                </Typography>
              ),
              name: "Pack",
              dataIndex: "batteryVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "packIgnored",
              width: 170,
              render: (batteryVendor) => (
                <Typography variant="tableRow">{batteryVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Alert Type
                </Typography>
              ),
              name: "Alert Type",
              dataIndex: "alertType",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "alertTypeIgnored",
              width: 170,
              render: (alertType) => (
                <Typography variant="tableRow">{alertType}</Typography>
              ),
            },

            {
              name: "",
            },
          ],
        },
        {
          name: "Expired Columns",
          columns: [

            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Device ID
                </Typography>
              ),
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDExpired",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">{renderBatteryIDCopy(batteryID,"Device ID")}</div>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Battery ID
                </Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDExpired",
              render: (assetID) => (
                <div className="renderID">{renderBatteryIDCopy(assetID,"Battery ID")}</div>        
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Live"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeLiExpired",
              align:"center",
              width: 80,
              render: (lastPingedTime) => (
                <CircleRoundedIcon
                sx={{ color: ( new Date().getTime() - new Date(convertUTCtoZone(lastPingedTime)).getTime() ) / (1000 * 60) < 5 ? "green":"#A9A9A9", fontSize: "14px" }}
                />
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempSocFilter2,
              //...getColumnSearchProps("socBMS", 2),
              checkedOptions: [],
              width: 110,
              uniqueKey: "socResolved",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "currentPack", "Current (A)"),
              name: "Current (A)",
              dataIndex: "currentPack",
              key: "currentPack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 130,
              uniqueKey: "currentExpired",
              render: (currentPack) => (
                <Typography variant="tableRow">{(currentPack)?.toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "voltagePack", "Volt (V)"),
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 90,
              uniqueKey: "voltagePackExpired",
              render: (voltagePack) => (
                <Typography variant="tableRow">{(voltagePack/1000).toFixed(1)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "temperature", "Temperature (°C)"),
              name: "Temperature (°C)",
              dataIndex: "temperature",
              key: "temperature",

              align: "center",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,

              //filterSearch: true,
              //filters: tempSocFilter,
              //...getColumnSearchProps("socBMS", 0),
              checkedOptions: [],
              width: 160,
              uniqueKey: "temperatureExpired",
              render: (temperature) => (
                <Typography variant="tableRow">{temperature}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Severity
                </Typography>
              ),
              name: "Severity",
              dataIndex: "severity",
              key: "severity",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 130,
              uniqueKey: "severityExpired",
              render: (severity) => (
                <Typography variant="tableRow">
                  {FindTheSeverity(severity)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "statusChangedTime",
                  "Expired On"
                ),
              name: "Expired On",
              dataIndex: "statusChangedTime",
              key: "statusChangedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "statusChangedTimeExpired",
              render: (statusChangedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(statusChangedTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "alertTime", "Alert First Seen"),
              name: "Alert First Seen",
              dataIndex: "alertTime",
              key: "alertTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "lastPingedTimeActive",
              render: (alertTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(alertTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "deltaTime",
                  "Expiration Time"
                ),
              name: "Expiration Time",
              dataIndex: "deltaTime",
              key: "deltaTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimeExpired",
              render: (deltaTime) => (
                <Typography variant="tableRow">
                  {SecondsFormatter(deltaTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimelastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Occupant
                </Typography>
              ),
              name: "Partner",
              dataIndex: "partner",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              //filterSearch: true,
              //filters: tempPartnerFilter2,
              //...getColumnSearchProps("partner", 2),
              checkedOptions: [],
              width: 170,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerClosed",
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Lat/Long
                </Typography>
              ),
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              width: 170,
              uniqueKey: "deltaTimeLatitude",
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  City
                </Typography>
              ),
              name: "City",
              dataIndex: "location",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "cityExpired",
              width: 170,
              render: (location) => (
                <Typography variant="tableRow">{location}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Zone
                </Typography>
              ),
              name: "Zone",
              dataIndex: "zone",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "zoneExpired",
              width: 170,
              render: (zone) => (
                <Typography variant="tableRow">{zone}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Status
                </Typography>
              ),
              name: "Status",
              dataIndex: "batteryStatus",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "statusExpired",
              width: 150,
              render: (batteryStatus) => (
                <Typography variant="tableRow">{batteryStatus}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  IOT
                </Typography>
              ),
              name: "IOT",
              dataIndex: "iotVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "iotExpired",
              width: 170,
              render: (iotVendor) => (
                <Typography variant="tableRow">{iotVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Pack
                </Typography>
              ),
              name: "Pack",
              dataIndex: "batteryVendor",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "packExpired",
              width: 170,
              render: (batteryVendor) => (
                <Typography variant="tableRow">{batteryVendor}</Typography>
              ),
            },
            {
              title: (
                <Typography
                  variant="tableHeadBold"
                >
                  Alert Type
                </Typography>
              ),
              name: "Alert Type",
              dataIndex: "alertType",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "alertTypeExpired",
              width: 170,
              render: (alertType) => (
                <Typography variant="tableRow">{alertType}</Typography>
              ),
            },

            {
              name: "",
            },
          ],
        },
      ];

      dispatch(SetDashboardColumns(columns));
      dispatch(SetDashboardHiddenColumns([]));
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batteryType]);

  var input = navigator.onLine ? (
    <div>
      {pagesContent.mounted ? (
        <>
          <DashboardMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  ) : (
    <NoInternet />
  );
  return <>{input}</>;
}

export default Dashboard;
