import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { KPIBoxCustomWidth } from "../../Components/CustomBox";
import { Typography, Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
// import classes from "./Clintele.module.css";
// import TagAndHeading from "../TextArea/TagAndHeading";
// import dots from "../../../assets/images/Home/Clintele/Dots.svg";

export default class MultipleItems extends Component {
  state = {
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
  };
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: this.props.Data.length < this.props.slidesToShow ? this.props.Data.length : this.props.slidesToShow,
      slidesToScroll: this.props.Data.length < this.props.slidesToShow ? this.props.Data.length : this.props.slidesToShow,
      initialSlide: 0,
      //   responsive: [
      //     {
      //       breakpoint: 800,
      //       settings: {
      //         slidesToShow: 1,
      //         slidesToScroll: 1,
      //         infinite: true,
      //         dots: true,
      //       },
      //     },
      //   ],
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
      //   autoplay: false,
      //   autoplaySpeed: 2000,
      centerMode: false,
    };
    return (
      <div style={{ margin: "0px 10px 0px 10px" }}>
        <Slider {...settings}>
          {this.props.Data.map((slide, index) => (
            <div key={index}>
              {/* {console.log(this.props.actualDates[index],this.props.startDate)} */}
              <KPIBoxCustomWidth
                onClick={() => {
                  this.props.setTheDate(this.props.actualDates[index]);
                }}
                sx={{
                  bgcolor:
                    this.props.actualDates[index] === this.props.startDate
                      ? ThemeProperties.purple
                      : "white",
                      mt:0,
                      border: `1px solid ${ThemeProperties.purple}`
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box>
                    <Typography
                      variant={
                        this.props.actualDates[index] === this.props.startDate
                          ? "textDescriptionActiveWhite"
                          : "textDescriptionActivePurple"
                      }
                    >
                      {slide}
                    </Typography>
                  </Box>
                </Box>
              </KPIBoxCustomWidth>
            </div>
            // <div
            //   key={index}
            //   className={
            //     index === this.state.activeSlide ? "redyy" : "blueeeyy"
            //   }
            //   style={{ position: "relative" }}
            // >
            //   <div
            //     style={{ position: "absolute", bottom: "0", right: "0" }}
            //   >
            //     <Image src={dots} alt="not found" priority />
            //   </div>
            //   <div
            //     className={classes.slider_content}
            //     style={{ width: "100%" }}
            //   >
            //     {slide.para}
            //   </div>
            //   <div style={{ marginTop: "5rem" }}></div>
            //   <div className={classes.slider_header}>
            //     <div className={classes.slider_header_left}>
            //       <div>
            //         <Image src={slide.image} alt="not found" priority />
            //       </div>
            //       <div className={classes.client_details}>
            //         <p className={classes.client_name}>{slide.name}</p>
            //         <p className={classes.client_position}>
            //           {slide.position}
            //         </p>
            //       </div>
            //     </div>
            //   </div>
            // </div>
          ))}
        </Slider>
      </div>
    );
  }
}
