import axios from "axios";
import CODE from "../../Static/Constants/StatusCodes";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import Logout from "../../Helper/Logout/Logout";

let data = {
  response: {},
  responseStatus: {
    code: 0,
    msg: null,
  },
};

function SetNoData(msg) {
  return {
    response: {},
    responseStatus: {
      code: CODE.NODATA,
      msg: msg,
    },
  };
}

function SetForbidden(msg) {
  return {
    response: {},
    responseStatus: {
      code: CODE.FORBIDDEN,
      msg: msg,
    },
  };
}

function SetSessionExpired() {
  return {
    response: {},
    responseStatus: {
      code: -2,
      msg: "session expired",
    },
  };
}

function callTheAPI(res, allBatteries) {
  if (res && (res.status === CODE.SUCCESS || res.status === CODE.URL_CREATED))
    if (
      res.data.responseStatus.code === CODE.SUCCESS ||
      res.data.responseStatus.code === CODE.URL_CREATED
    ) {
      data.response = res.data;
      data.responseStatus.code = res.data.responseStatus.code;
      data.responseStatus.msg = res.data.responseStatus.msg;
      return data;
    } else if (
      res.data.responseStatus.code === 1999 ||
      res.data.responseStatus.code === 404 ||
      res.data.responseStatus.code === 1998
    ) {
      if (!allBatteries) {
        data = SetNoData(res.data.responseStatus.msg);
        return data;
      } else {
        data.response = res.data;
        data.responseStatus.code = res.data.responseStatus.code;
        data.responseStatus.msg = res.data.responseStatus.msg;
        return data;
      }
    } else if (res.data.responseStatus.code === CODE.UNAUTHORIZED) {
      removeAccessToken();
      return null;
    } else {
      Logout();
      return null;
    }
}

async function callTheAPIRefreshingToken(refresh, config) {
  if (refresh && refresh.status === CODE.SUCCESS) {
    try {
      let res = await axios(config);
      const data = callTheAPI(res);
      return data;
    } catch (error) {
      if (error.response.status === CODE.URL_NODATA) {
        data = SetNoData("No Data Found");
        return data;
      } else if (error.response.status === CODE.UNAUTHORIZED) {
        removeAccessToken();
        return null;
      }
    }
  }
}

async function GetData(config, allBatteries) {
  try {
    let res = await axios(config);
    const data = callTheAPI(res, allBatteries);
    return data;
  } catch (error) {
    if (error.response?.status === CODE.URL_NODATA) {
      data = SetNoData("No Data Found");
      return data;
    } else if (error.response.status === CODE.FORBIDDEN) {
      data = SetForbidden(error.response.data.message);
      return data;
    } else if (error.response.status === CODE.UNAUTHORIZED) {
      try {
        let refresh = await axios.get("/api/refresh");
        const data = callTheAPIRefreshingToken(refresh, config);
        return data;
      } catch (error) {
        console.log(error?.response?.data?.responseStatus?.msg);
        if (
          error?.response?.data?.responseStatus?.code ===
            CODE.EXCEPTION_EXPIRED_REFRESH ||
          error?.response?.data?.responseStatus?.msg === CODE.ERR_MSSG
        ) {
          data = SetSessionExpired();
          return data;
        } else {
          // let res = await axios(config);
          const data = GetData(config, allBatteries);
          return data;
        }
      }
    }
  }
}

export { SetNoData, callTheAPI, callTheAPIRefreshingToken, GetData };
