import { styled } from "@mui/material/styles";
import { ListItemButton } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

const StyledListItemButton = styled((props) => <ListItemButton {...props} />)(
  ({ theme, myColor, border }) => ({
    margin: "10px",
    // borderRadius: "12px",
    padding: "8px 8px 8px 8px",
    backgroundColor: "white",
    border: border ? "1px solid #ececec" : "",
    "&.MuiList-root": {
      paddingTop: "0px",
    },
    "&.Mui-selected": {
      // color: "#fff",
      backgroundColor: border ? myColor : "",
      border: `1px solid ${myColor}`,
      "&:hover": {
        backgroundColor: border ? myColor : "",
        border: border ? `1px solid ${myColor}` : "",
      },
    },
    "&:hover": {
      border: border ? `1px solid ${myColor}` : "",
      backgroundColor: ThemeProperties.white,
      cursor: border ? "pointer" : "default",
    },
  })
);

export { StyledListItemButton };
