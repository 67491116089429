import { useEffect, useMemo, useState, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ConfigProvider, notification } from "antd";
import Cookies from "universal-cookie";
import {
  Box,
  Grid,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Chip,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { LoadingButton, useTabContext } from "@mui/lab";

import {
  addDigitalTwin,
  addOrganization,
  getConfigList,
  getDigitalTwinList,
  updateDigitalTwin,
} from "../../Api/DigitalTwinListApi";
import { getFoundNotFoundBatteries } from "../../Api/MapApi";

import { SecondaryButton } from "../../Components/CustomButton";
import { InnerBox, OuterBox } from "../../Components/CustomBox";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import CODE from "../../Static/Constants/StatusCodes";
import { CustomAutocomplete } from "../../Components/CustomAutocomplete";
import DigitalTwinConfirmDialog from "../../Features/Dialog/DigitalTwin/DigitalTwinConfimDialog";
import { BasicTooltip } from "../../Components/CustomTooltip";
import { CustomTextField } from "../../Components/CustomTextField";
import SimplePopover from "../../Features/Popover/SimplePopover";
import AddBatteryDialog from "../../Features/Dialog/DigitalTwin/AddBatteryConfigDialog";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { SetSessionExpired } from "../../Actions";
import Confirm from "../../Helper/Loaders/Confirm";

const Context = createContext({
  name: "Default",
});

function BattryOnBoardingForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = decryptTheParams();

  const batteryID = queryParams["batteryID"];
  const systemID = queryParams["id"];

  const [isEdit, setIsEdit] = useState(batteryID && systemID ? true : false);
  const [openConfigDialog, setOpenConfigDialog] = useState(false);

  const [batteryIds, setBatteryIds] = useState([]);
  const [foundBatteries, setFoundBatteries] = useState([]);
  const [duplicateBatteries, setDuplicateBatteries] = useState([]);
  const [showExistsElement, setShowExistsElement] = useState(false);
  const [anchorElExistsPopup, setAnchorElExistsPopup] = useState(null);
  const openExistsPopup = Boolean(anchorElExistsPopup);
  const idExistsPopup = openExistsPopup ? "simple-popover-exists" : undefined;

  const [showDuplicatesElement, setShowDuplicatesElement] = useState(false);
  const [anchorElDuplicatePopup, setAnchorElDuplicatePopup] = useState(null);
  const openDuplicatePopup = Boolean(anchorElDuplicatePopup);
  const idDuplicatePopup = openDuplicatePopup
    ? "simple-popover-duplicate"
    : undefined;

  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [orgLoading, setOrgLoading] = useState(false);
  const [form, setForm] = useState({
    configName: "",
    organizationTag: "",
    batteryId: batteryID,
    id: systemID,
  });

  const [formValidated, setFormValidated] = useState({
    batteryId: true,
    configName: true,
    organizationTag: true,
    organizationExists: false,
  });
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  // Notification
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };

  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const handleInputBatteries = (batteries) => {
    // let queryParams = decryptTheParams();
    if (batteries.length > 0) {
      getFoundNotFoundBatteries(batteries, "Device ID").then((res) => {
        if (
          res &&
          (res?.responseStatus.code === 200 ||
            res?.responseStatus.code === 204 ||
            res?.responseStatus.code === 1999 ||
            res?.responseStatus.code === 404)
        ) {
          let found = [];
          res?.response?.mapData?.forEach((item) => {
            found.push(item.batteryID);
            setFoundBatteries(found);
          });
          if (found.length > 0) {
            // setShowExistsElement(true);
          }
          setBatteryIds(batteries.filter((x) => !found.includes(x)));
        } else if (res?.responseStatus.code === -2) {
          setFoundBatteries([]);
          setBatteryIds(batteries);
        } else {
          setBatteryIds(batteries);
        }
      });
    } else {
      setBatteryIds(batteries);
    }
  };

  const handleAddOrganization = async () => {
    if (showAddOrganization && orgFilter?.includes(form.organizationTag)) {
      setFormValidated({
        ...formValidated,
        organizationTag: false,
        organizationExists: true,
      });
    } else {
      setOrgLoading(true);
      let response = await addOrganization({
        fleetName: form.organizationTag,
      });
      if (response?.responseStatus?.code === CODE.URL_CREATED) {
        setShowAddOrganization(false);
        await GetConfigList();
        setOrgLoading(false);
      }
    }
  };

  const [configList, setConfigList] = useState([]);

  const [orgFilter, setOrgFilter] = useState([]);

  const [selected, setSelected] = useState({});

  const GetConfigList = async () => {
    await getConfigList().then((res) => {
      if (res?.responseStatus.code === CODE.SUCCESS) {
        let configNames = res?.response?.filterData?.find(
          (i) => i.type === "configName"
        );
        if (configNames) {
          // cookies.set("configList", configNames.value, {
          //   path: "/",
          //   maxAge: 86400,
          // });
          setConfigList(configNames?.value);
        }
        let orgList = res?.response?.filterData?.find(
          (i) => i.type === "fleetName"
        );
        if (orgList) {
          setOrgFilter(orgList.value);
        }
      }
    });
  };

  const GetSelectedBattery = async () => {
    await getDigitalTwinList({
      pageNumber: 1,
      rowsPerPage: 1,
      sortBy: "batteryID",
      orderBy: "asc",
      batteryID,
    }).then((res) => {
      if (res.responseStatus?.code === 200) {
        const sel = res?.response?.data?.find((x) => x.batteryID === batteryID);
        setSelected(sel);
        setForm({
          ...form,
          configName: sel?.configName,
          organizationTag: sel?.fleetName,
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
  };

  useEffect(() => {
    GetConfigList();
    if (isEdit) {
      GetSelectedBattery();
    }
  }, []);
  const handleSubmit = async () => {
    let response;

    if (showSaveDialog && !isEdit) {
      response = await addDigitalTwin({
        batteryID: batteryIds,
        fleetName: form.organizationTag,
        configName: form.configName,
      });
    } else {
      if (
        !form.configName ||
        !form.organizationTag ||
        (!isEdit ? batteryIds.length <= 0 : !form.batteryId)
      ) {
        setFormValidated({
          ...formValidated,
          configName: !!form.configName,
          organizationTag: !!form.organizationTag,
          batteryId: isEdit ? !!form.batteryId : batteryIds?.length > 0,
        });
      } else if (
        showAddOrganization &&
        orgFilter?.includes(form.organizationTag)
      ) {
        setFormValidated({
          ...formValidated,
          organizationTag: false,
          organizationExists: true,
        });
      } else if (isEdit) {
        response = await updateDigitalTwin({
          id: selected?.id,
          batteryID: form.batteryId,
          fleetName: form.organizationTag,
          configName: form.configName,
        });
      } else {
        setShowSaveDialog(true);
      }
    }

    if (
      response &&
      response?.responseStatus?.code === (isEdit ? CODE.SUCCESS : CODE.URL_CREATED)) {
      setShowSaveDialog(false);
      setShowSuccessDialog(true)
      setTimeout(() => {
        setShowSuccessDialog(false)
        navigate('/DigitalTwin')
      }, 5 * 1000);
    }
  };

  const hasDuplicates = (array) => {
    let duplicateRes = false;
    var valuesSoFar = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
      var value = array[i];
      if (value in valuesSoFar) {
        duplicateBatteries.push(value);
        duplicateRes = true;
      }
      valuesSoFar[value] = true;
    }
    return duplicateRes;
  };

  const removeDuplicates = (arr) => {
    let isHavingDuplicates = hasDuplicates(arr);
    if (isHavingDuplicates) {
      openNotification("bottomLeft", "Duplicates are not allowed");
      // setShowDuplicatesElement(true);
    }
    let myarr = arr.filter((item, index) => arr.indexOf(item) === index);
    return myarr;
  };

  const handleOpenToast = () => {
    openNotification("bottomLeft", "Top 100 batteries are selected");
  };

  const handleCloseConfigDialog = () => {
    setOpenConfigDialog(false);
    setForm({
      ...form,
      configName: "",
    });
  };

  const handleOpenExistsPopup = (event) => {
    setShowExistsElement(false);
    setAnchorElExistsPopup(event.currentTarget);
  };
  const handleCloseExistsPopup = () => {
    setFoundBatteries([]);
    setAnchorElExistsPopup(null);
  };

  const handleOpenDuplicatesPopup = (event) => {
    setShowDuplicatesElement(false);
    setAnchorElDuplicatePopup(event.currentTarget);
  };
  const handleCloseDuplicatesPopup = () => {
    setDuplicateBatteries([]);
    setAnchorElDuplicatePopup(null);
  };

  const handleChangeBatteryID = (value) => {
    let batteryIds = [];
    setFormValidated({ ...formValidated, batteryId: true });
    value.forEach((battery, index) => {
      const str = battery;
      const replaced = str.replaceAll(/[\W_]+/g, " ");
      const finalArr = replaced.split(" ");
      const finalArr2 = finalArr.filter(String);
      if (finalArr2.length > 1) {
        finalArr2.map((v, i) => batteryIds.push(v));
      } else if (finalArr2.length === 1) {
        batteryIds.push(finalArr2[0]);
      }
    });
    let arrAfterDup = removeDuplicates(batteryIds);

    if (arrAfterDup.length > 100) {
      const newBatteries = arrAfterDup.slice(0, 100);
      handleInputBatteries(newBatteries);
      handleOpenToast();
    } else {
      handleInputBatteries(arrAfterDup);
    }
  };

  const handleDiscardConfirm = () => {
    navigate("/DigitalTwin");
  };

  const handleClose = () => {
    setShowSuccessDialog(false)
    navigate('/DigitalTwin')
  }

  return (
    <div>
      <OuterBox>
        <InnerBox>
          <Context.Provider value={contextValue}>
            {contextHolder}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 0.5,
                mb: 4,
              }}
            >
              <Typography variant="textSubtitleBold">
                {"Digital Twin"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "left",
                mt: 0.5,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "flex-row",
                  alignItems: "center",
                  fontSize: "20px",
                }}
              >
                <IconButton
                  onClick={() => setShowDiscardDialog(true)}
                  fontSize="medium"
                >
                  <CloseIcon style={{ fontSize: "18px", paddingRight: 2 }} />
                </IconButton>
                <Typography sx={{ paddingLeft: 1, fontSize: "18px" }}>
                  Battery Onboarding
                </Typography>
              </Box>
            </Box>

            <hr style={{ borderTop: "1px solid #ececec" }}></hr>
            <Box sx={{ mt: 2 }}></Box>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              margin={2}
              sx={{
                "--Grid-borderWidth": "1px",
                borderTop: "var(--Grid-borderWidth) solid",
                borderLeft: "var(--Grid-borderWidth) solid",
                borderColor: "divider",
                "& > div": {
                  borderRight: "var(--Grid-borderWidth) solid",
                  borderBottom: "var(--Grid-borderWidth) solid",
                  borderColor: "divider",
                },
              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: ThemeProperties.purple,
                  },
                }}
              >
                <Grid item xs={12}>
                  {/* FORM */}
                  <FormGroup>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                      justifyContent="space-around"
                      margin={3}
                      rowGap={2}
                    >
                      <FormControl>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap={1}
                          width={600}
                        >
                          <FormLabel
                            sx={{
                              fontSize: 14,
                              paddingTop: 0.7,
                              width: "auto",
                              fontWeight: "bold",
                              color: ThemeProperties.black,
                            }}
                          >
                            Device ID <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          {isEdit ? (
                            <TextField
                              style={{ width: "500px" }}
                              size="small"
                              type="text"
                              variant="outlined"
                              value={form.batteryId}
                              onChange={(e) => {
                                setForm({ ...form, batteryId: e.target.value });
                                setFormValidated({
                                  ...formValidated,
                                  batteryId: true,
                                });
                              }}
                              disabled={isEdit}
                              inputProps={{
                                style: {
                                  fontSize: 14,
                                  padding: 7,
                                  marginLeft: 3,
                                },
                              }}
                            />
                          ) : (
                            <CustomAutocomplete
                              multiple
                              id="tags-filled"
                              size="small"
                              options={[]}
                              defaultValue={""}
                              value={batteryIds}
                              onChange={(e, value) =>
                                handleChangeBatteryID(value)
                              }
                              freeSolo
                              renderTags={(value, getTagProps) => {
                                return value.map((option, index) => {
                                  return (
                                    <Chip
                                      variant="outlined"
                                      color={"primary"}
                                      label={
                                        <Typography
                                          sx={{
                                            fontSize: 13,
                                            fontWeight: 400,
                                            fontFamily: "Roboto",
                                            color: "black",
                                          }}
                                        >
                                          {option}
                                        </Typography>
                                      }
                                      {...getTagProps({ index })}
                                    />
                                  );
                                });
                              }}
                              renderInput={(params) => {
                                const { InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } =
                                  InputProps;
                                return (
                                  <CustomTextField
                                    {...restParams}
                                    placeholder={
                                      "Add single/multiple devices (upto 100) and press enter"
                                    }
                                    InputProps={{
                                      ...restInputProps,
                                      startAdornment: (
                                        <div
                                          style={{
                                            maxHeight: 100,
                                            overflowY: "auto",
                                          }}
                                        >
                                          {startAdornment}
                                        </div>
                                      ),
                                      endAdornment: (
                                        <>
                                          {foundBatteries !== undefined &&
                                            foundBatteries.length > 0 && (
                                              <BasicTooltip
                                                title={
                                                  "Some of the batteries already exists and have been removed. Click this icon to see."
                                                }
                                                arrow
                                                open={showExistsElement}
                                                placement="top"
                                                disableFocusListener
                                                sx={{
                                                  wordWrap: "break-word",
                                                  maxWidth: "200px",
                                                  "& .MuiTooltip-arrow": {
                                                    color: "rgb(28,28,28)",
                                                    border: "none",
                                                  },
                                                  zIndex: 1,
                                                }}
                                              >
                                                <IconButton
                                                  size="small"
                                                  onClick={
                                                    handleOpenExistsPopup
                                                  }
                                                >
                                                  <WarningIcon
                                                    fontSize="medium"
                                                    sx={{
                                                      color:
                                                        "rgba(211, 47, 47, 0.7)",
                                                      fontSize: "18.5px",
                                                    }}
                                                  />
                                                </IconButton>
                                              </BasicTooltip>
                                            )}
                                          {duplicateBatteries !== undefined &&
                                            duplicateBatteries.length > 0 && (
                                              <BasicTooltip
                                                title={
                                                  "Some of the batteries were duplicates and have been removed. Click this icon to see."
                                                }
                                                arrow
                                                open={showDuplicatesElement}
                                                placement="top"
                                                disableFocusListener
                                                sx={{
                                                  wordWrap: "break-word",
                                                  maxWidth: "200px",
                                                  "& .MuiTooltip-arrow": {
                                                    color: "rgb(28,28,28)",
                                                    border: "none",
                                                  },
                                                  zIndex: 1,
                                                }}
                                              >
                                                <IconButton
                                                  size="small"
                                                  onClick={
                                                    handleOpenDuplicatesPopup
                                                  }
                                                >
                                                  <WarningIcon
                                                    fontSize="medium"
                                                    sx={{
                                                      color:
                                                        "rgba(211, 47, 47, 0.7)",
                                                      fontSize: "18.5px",
                                                    }}
                                                  />
                                                </IconButton>
                                              </BasicTooltip>
                                            )}
                                          <IconButton
                                            size="small"
                                            onClick={() => {
                                              handleInputBatteries([]);
                                            }}
                                            sx={{ mr: 0.5 }}
                                          >
                                            <CloseIcon
                                              fontSize="medium"
                                              sx={{
                                                color: "grey",
                                                fontSize: "18.5px",
                                              }}
                                            />
                                          </IconButton>
                                        </>
                                      ),
                                    }}
                                  />
                                );
                              }}
                              sx={{ width: "100%" }}
                              ListboxProps={{
                                sx: {
                                  fontSize: 13,
                                  fontWeight: 500,
                                  fontFamily: "Roboto",
                                  pl: 1,
                                  width: 170,
                                  // display: "none",
                                },
                              }}
                              componentsProps={{
                                paper: {
                                  sx: {
                                    width: 175,
                                  },
                                },
                              }}
                            />
                          )}
                          {openExistsPopup && foundBatteries.length > 0 && (
                            <SimplePopover
                              idProfilePopup={idExistsPopup}
                              openProfilePopup={openExistsPopup}
                              anchorElProfilePopup={anchorElExistsPopup}
                              handleCloseProfilePopup={handleCloseExistsPopup}
                              data={foundBatteries}
                              text={"Batteries already exists"}
                            />
                          )}
                          {openDuplicatePopup &&
                            duplicateBatteries.length > 0 && (
                              <SimplePopover
                                idProfilePopup={idDuplicatePopup}
                                openProfilePopup={openDuplicatePopup}
                                anchorElProfilePopup={anchorElDuplicatePopup}
                                handleCloseProfilePopup={
                                  handleCloseDuplicatesPopup
                                }
                                data={duplicateBatteries}
                                text={"Pasted Duplicated Batteries"}
                              />
                            )}
                          {!formValidated.batteryId && (
                            <Typography color="red" pt={1}>
                              This field is required
                            </Typography>
                          )}
                        </Box>
                      </FormControl>
                      <FormControl>
                        <Box display="flex" flexDirection="column" gap={1}>
                          <FormLabel
                            sx={{
                              fontSize: 14,
                              paddingTop: 0.7,
                              width: "auto",
                              fontWeight: "bold",
                              color: ThemeProperties.black,
                            }}
                          >
                            Select Configuration of battery{" "}
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Select
                            style={{ width: "500px", fontSize: "small" }}
                            size="small"
                            variant="outlined"
                            value={form.configName}
                            onChange={(e) => {
                              if (e.target.value === "%add%") {
                                setOpenConfigDialog(true);
                              } else {
                                setForm({
                                  ...form,
                                  configName: e.target.value,
                                });
                                setFormValidated({
                                  ...formValidated,
                                  configName: true,
                                });
                              }
                            }}
                            placeholder="Select one"
                            required
                            renderInput={(input) => {
                              <CustomTextField>{input}</CustomTextField>;
                            }}
                          >
                            {configList?.map((item) => (
                              <MenuItem sx={{ fontSize: "small" }} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                            <MenuItem
                              value="%add%"
                              sx={{
                                color: ThemeProperties.Purple1,
                                fontSize: "small",
                              }}
                            >
                              Add new
                            </MenuItem>
                          </Select>
                        </Box>
                        {!formValidated.configName && (
                          <Typography color="red" pt={1}>
                            This field is required
                          </Typography>
                        )}
                      </FormControl>
                      <FormControl>
                        <Box display="flex" flexDirection="column" gap={1}>
                          <FormLabel
                            sx={{
                              fontSize: 14,
                              paddingTop: 0.7,
                              width: "auto",
                              fontWeight: "bold",
                              color: ThemeProperties.black,
                            }}
                          >
                            Organization Tag{" "}
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          {!showAddOrganization ? (
                            <Select
                              style={{ width: "500px", fontSize: "small" }}
                              size="small"
                              variant="outlined"
                              value={form.organizationTag}
                              onChange={(e) => {
                                if (e.target.value === "%add%") {
                                  setFormValidated({
                                    ...formValidated,
                                    organizationTag: true,
                                  });
                                  setForm({
                                    ...form,
                                    organizationTag: "",
                                  });
                                  setShowAddOrganization(true);
                                } else {
                                  setForm({
                                    ...form,
                                    organizationTag: e.target.value,
                                  });
                                }
                                setFormValidated({
                                  ...formValidated,
                                  organizationTag: true,
                                });
                              }}
                              placeholder="Select one"
                              helper
                              required
                            >
                              {orgFilter?.map((item) => (
                                <MenuItem
                                  sx={{ fontSize: "small" }}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                              <MenuItem
                                value="%add%"
                                sx={{
                                  color: ThemeProperties.Purple1,
                                  fontSize: "small",
                                }}
                              >
                                Add new
                              </MenuItem>
                            </Select>
                          ) : (
                            <Box display="flex" gap={2}>
                              <TextField
                                style={{ width: "500px" }}
                                size="small"
                                type="text"
                                variant="outlined"
                                value={form.organizationTag}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    organizationTag: e.target.value,
                                  });
                                  setFormValidated({
                                    ...formValidated,
                                    organizationTag: true,
                                    organizationExists: false,
                                  });
                                }}
                                inputProps={{
                                  style: {
                                    fontSize: 14,
                                    padding: 7,
                                    marginLeft: 3,
                                  },
                                }}
                                required
                                autoFocus
                              />
                              <LoadingButton
                                variant="contained"
                                loading={orgLoading}
                                onClick={handleAddOrganization}
                                sx={{ textTransform: "none" }}
                              >
                                <Typography fontSize={13}>Add</Typography>
                              </LoadingButton>
                              <SecondaryButton
                                variant="outlined"
                                onClick={() => {
                                  setShowAddOrganization(false);
                                  setForm({ ...form, organizationTag: "" });
                                }}
                              >
                                <Typography fontSize={13}>Cancel</Typography>
                              </SecondaryButton>
                            </Box>
                          )}
                        </Box>
                        {!formValidated.organizationTag && (
                          <Typography color="red" pt={1}>
                            {formValidated.organizationExists
                              ? "Organization already exists"
                              : "This field is required"}
                          </Typography>
                        )}
                      </FormControl>
                      <SecondaryButton
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={showAddOrganization}
                      >
                        <Typography fontSize={13}>
                          {isEdit ? "Update" : "Save"}
                        </Typography>
                      </SecondaryButton>
                    </Box>
                  </FormGroup>
                </Grid>
              </ConfigProvider>
            </Grid>
          </Context.Provider>
        </InnerBox>
      </OuterBox>
      <DigitalTwinConfirmDialog
        open={showSaveDialog}
        onConfirm={handleSubmit}
        handleCloseDialog={() => setShowSaveDialog(false)}
        message={"Add batteries to " + form.configName + "?"}
        confirmButtonText="Save"
        confirmButtonColor="primary"
        discardButtonText="Cancel"
      />
      <DigitalTwinConfirmDialog
        open={showDiscardDialog}
        onConfirm={handleDiscardConfirm}
        handleCloseDialog={() => setShowDiscardDialog(false)}
        message={"Changes are not saved and will be discarded. Are you sure?"}
        confirmButtonText="Proceed"
        confirmButtonColor="error"
        discardButtonText="Cancel"
      />
      <AddBatteryDialog
        open={openConfigDialog}
        handleCloseDialog={handleCloseConfigDialog}
      />
      <Dialog
        open={showSuccessDialog}
        onClose={handleClose}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            padding: '20px'
          }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </Box>
        <Box
          style={{
            height: "227px",
            width: "500px",
          }}
        >
          <Confirm text={"Saved successfully !"} />
        </Box>
      </Dialog>
    </div>
  );
}

export default BattryOnBoardingForm;
