const initialCycleAge = {
  value: false,
};

const initialToggleTable = {
  value: false,
};
const initialBatteryPartnerSelect = {
  value: "Battery ID"
}
const inititalColumns = {
  value: [],
};
const initialBatteryID = {
  value: "",
};
const initialDeviceID = {
  value: "",
};
const initialPartner = {
  value: "",
};
const initialEqCycle = {
  value:"",
};
const initialSOH = {
  value: "",
};
const initialCell = {
  value: "",
};
const initialVolt = {
  value: "",
};
const initialBusiness = {
  value: "",
};

const initialIOT = {
  value: "Wynncom",
};
const initialPack = {
  value: "",
};

const initialSort = {
  value : "sohEst"
}

const initialOrder = {
  value : "descend"
}

const initialPageNum = {
  value : 1
}

const initialRowsPerPage = {
  value : 20
}

const BatteryHealthToggleTable = (state = initialToggleTable, action) => {
  switch (action.type) {
    case "BatteryHealthToggleTable":
      return {
        value: action.toggleTable,
      };
    default:
      return state;
  }
};
const BatteryHealthBatteryPartnerSelect = (
  state = initialBatteryPartnerSelect,
  action
) => {
  switch (action.type) {
    case "BatteryHealthBatteryPartnerSelect":
      return {
        value: action.select,
      };
    default:
      return state;
  }
};

const BatteryHealthColumns = (state = inititalColumns, action) => {
  switch (action.type) {
    case "BatteryHealthColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const BatteryHealthBatteryID = (state = initialBatteryID, action) => {
  switch (action.type) {
    case "BatteryHealthBatteryID":
      return {
        value: action.batteryID,
      };
    default:
      return state;
  }
};

const BatteryHealthDeviceID = (state = initialDeviceID, action) => {
  switch (action.type) {
    case "BatteryHealthDeviceID":
      return {
        value: action.deviceID,
      };
    default:
      return state;
  }
};

const BatteryHealthPartner = (state = initialPartner, action) => {
  switch (action.type) {
    case "BatteryHealthPartner":
      return {
        value: action.partner,
      };
    default:
      return state;
  }
};

const BatteryHealthCycleAge = (state = initialCycleAge, action) => {
  switch (action.type) {
    case "BatteryHealthCycleAge":
      return {
        value: action.cycleAge,
      };
    default:
      return state;
  }
};

const BatteryHealthEqCycle = (state = initialEqCycle, action) => {
  switch (action.type) {
    case "BatteryHealthEqCycle":
      return {
        value: action.eqcycle,
      };
    default:
      return state;
  }
};

const BatteryHealthSOH = (state = initialSOH, action) => {
  switch (action.type) {
    case "BatteryHealthSOH":
      return {
        value: action.soh,
      };
    default:
      return state;
  }
};
const BatteryHealthVolt = (state = initialVolt, action) => {
  switch (action.type) {
    case "BatteryHealthVolt":
      return {
        value: action.volt,
      };
    default:
      return state;
  }
};
const BatteryHealthCell = (state = initialCell, action) => {
  switch (action.type) {
    case "BatteryHealthCell":
      return {
        value: action.cell,
      };
    default:
      return state;
  }
};
const BatteryHealthBusiness = (state = initialBusiness, action) => {
  switch (action.type) {
    case "BatteryHealthBusiness":
      return {
        value: action.business,
      };
    default:
      return state;
  }
};
const BatteryHealthIOT = (state = initialIOT, action) => {
  switch (action.type) {
    case "BatteryHealthIOT":
      return {
        value: action.iot,
      };
    default:
      return state;
  }
};
const BatteryHealthPack = (state = initialPack, action) => {
  switch (action.type) {
    case "BatteryHealthPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};

const BatteryHealthSort = (state = initialSort, action) => {
  switch (action.type) {
    case "BatteryHealthSort":
      return {
        value: action.sort,
      };
    default:
      return state;
  }
};

const BatteryHealthSortOrder = (state = initialOrder, action) => {
  switch (action.type) {
    case "BatteryHealthSortOrder":
      return {
        value: action.sortOrder,
      };
    default:
      return state;
  }
};

const BatteryHealthPageNumber = (state = initialPageNum, action) => {
  switch (action.type) {
    case "BatteryHealthPageNumber":
      return {
        value: action.pageNumber,
      };
    default:
      return state;
  }
};

const BatteryHealthRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "BatteryHealthRowsPerPage":
      return {
        value: action.rowsPerPage,
      };
    default:
      return state;
  }
};

export {
  BatteryHealthBatteryPartnerSelect,
  BatteryHealthColumns,
  BatteryHealthPartner,
  BatteryHealthBatteryID,
  BatteryHealthDeviceID,
  BatteryHealthCycleAge,
  BatteryHealthEqCycle,
  BatteryHealthSOH,
  BatteryHealthCell,
  BatteryHealthVolt,
  BatteryHealthBusiness,
  BatteryHealthToggleTable,
  BatteryHealthIOT,
  BatteryHealthPack,
  BatteryHealthSort,
  BatteryHealthSortOrder,
  BatteryHealthPageNumber,
  BatteryHealthRowsPerPage
};
