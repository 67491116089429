import React, { useEffect } from "react";
import useState from "react-usestateref";
// import "./table.css";
import { Table } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";

import { Box, Typography, IconButton } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import Events from "../../../Analytics/Events";
import NoData from "../../../Helper/Loaders/NoData";
import CODE from "../../../Static/Constants/StatusCodes";
import { useDispatch, useSelector } from "react-redux";
import { getColumnTitleSort } from "./TableFunctions";
import { Tooltip } from "antd";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import {
  SetBatteryHealthPageNumber,
  SetBatteryHealthRowsPerPage,
  SetBatteryHealthSort,
  SetBatteryHealthSortOrder,
} from "../../../Actions/BatteryHealth";
function renderBatteryIDCopy(item, name) {
  return (
    <>
      {item && (
        <>
          <Typography variant="tableRow">{item}</Typography>
          <Tooltip title={"Click to copy"}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                copy(item);
                Events(`Copied ${name}`);
              }}
            >
              <ContentCopyIcon
                sx={{
                  fontSize: "13px",
                  cursor: "pointer",
                  "&:hover": {
                    color: ThemeProperties.purple,
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
}

export default function BasicTableFleetBatteryHealth(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = decryptTheParams();

  const sortChangedTo = useSelector((state) => state.BatteryHealthSort.value);
  const orderChangedTo = useSelector(
    (state) => state.BatteryHealthSortOrder.value
  );
  console.log("order changed to is now",orderChangedTo)
  const newOrderChangedTo = orderChangedTo;
  const pageNumber = useSelector((state) => state.BatteryHealthPageNumber.value);
  const rowsPerPage =  useSelector((state) => state.BatteryHealthRowsPerPage.value);

  const [columns, setColumns] = useState([
    {
      title: <Typography variant="tableHeadBold">Device ID</Typography>,
      name: "Battery ID",
      dataIndex: "batteryID",
      key: "batteryID",
      fixed: "left",
      sorter: false,
      uniqueKey: "batteryIDActive",
      filterSearch: false,
      width: 200,
      className: "undragableColumn",
      // maxWidth:"18px",
      render: (batteryID) => (
        <div className="renderID">
          {renderBatteryIDCopy(batteryID, "Device ID")}
        </div>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Battery ID</Typography>,
      name: "Asset ID",
      dataIndex: "assetID",
      key: "assetID",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 150,
      uniqueKey: "assetIDActive",
      render: (assetID) => (
        <div className="renderID">
          {renderBatteryIDCopy(assetID, "Battery ID")}
        </div>
      ),
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "sohEst", "SOH (%)"),
      name: "SOH (%)",
      dataIndex: "sohEst",
      key: "sohEst",

      align: "center",

      sorter: (a, b) => a.sohEst - b.sohEst,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: sortChangedTo === "sohEst" ? newOrderChangedTo : "",
      showSorterTooltip: false,

      filterSearch: false,
      // filters: tempSocFilter,
      // ...getColumnSearchProps("sohEst", 0),
      // checkedOptions: [],
      width: 160,
      uniqueKey: "sohEst",
      render: (sohEst) => (
        <Typography variant="tableRow">{Math.round(sohEst)}</Typography>
      ),
    },

    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "age", "Age (months)"),
      name: "age",
      dataIndex: "age",
      key: "age",

      align: "center",

      sorter: (a, b) => a.age - b.age,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: sortChangedTo === "age" ? newOrderChangedTo : "",
      showSorterTooltip: false,

      filterSearch: false,
      // filterMode: "tree",
      // filters: tempPartnerFilter,
      // ...getColumnSearchProps("age", 0),
      // checkedOptions: [],

      width: 180,
      ellipsis: {
        showTitle: false,
      },
      uniqueKey: "age",
      render: (age) => (
        <Tooltip placement="top" title={age}>
          <Typography variant="tableRow">
            {age ? Math.round(age / 30) : ""}
          </Typography>
        </Tooltip>
      ),
    },
    {
      // title: <Typography variant="tableHeadBold">Eq. Cycle</Typography>,
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "eqCycleEst", "Eq. Cycle"),
      name: "eqCycleEst",
      dataIndex: "eqCycleEst",
      key: "eqCycleEst",

      sorter: (a, b) => a.eqCycleEst - b.eqCycleEst,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: sortChangedTo === "eqCycleEst" ? newOrderChangedTo : "",
      showSorterTooltip: false,

      checkedOptions: [],
      align: "center",
      width: 170,
      uniqueKey: "eqCycleEst",
      render: (eqCycleEst) => (
        <Typography variant="tableRow">{Math.round(eqCycleEst)}</Typography>
      ),
    },

    {
      name: "",
    },
  ]);

  const [list, setList] = useState({
    batteries: [],
    responseStatus: {
      code: -1,
      msg: "",
    },
  }); ////vvvvvvImp

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: pageNumber,
      pageSize: rowsPerPage,
      total:
        props.data.data !== undefined && props.data.data.length > 0
          ? props.data.data.length
          : 0,
          showTotal: (total,range) => `${range[0]}-${range[1]}/${total}`,
    },
  });

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newColumns = [...columns];
      const item = props.rowSelection
        ? newColumns.splice(fromIndex - 1, 1)[0]
        : newColumns.splice(fromIndex, 1)[0];
      props.rowSelection
        ? newColumns.splice(toIndex - 1, 0, item)
        : newColumns.splice(toIndex, 0, item);

      setColumns(newColumns);
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  const handleTableChange = (pagination, filters, sorter, extras) => {
    if (sorter.column) {
      dispatch(SetBatteryHealthSort(sorter.columnKey));
      dispatch(SetBatteryHealthSortOrder(sorter.order));
      console.log(sorter.order)
    }
    // console.log(pagination);
    setTableParams({
      pagination: {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        showTotal: (total,range) => `${range[0]}-${range[1]}/${total}`,
      },
    });
    dispatch(SetBatteryHealthPageNumber(pagination.current));
    dispatch(SetBatteryHealthRowsPerPage(pagination.pageSize))
  };

  useEffect(() => {
    setList({
      batteries: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    if (props.data.data !== undefined && props.data.data.length > 0) {
      let newData = props.data.data.map((row) => ({
        ...row,
        tableData: {
          checked: false,
        },
      }));
      setList({
        batteries: newData,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: pageNumber !== 1 ? pageNumber : 1,
          total: props.data.data.length,
          showTotal: (total,range) => `${range[0]}-${range[1]}/${total}`,
        },
      });
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      setList([]);
      props.setBatteryConfigList({
        data: [],
        responseStatus: { code: -1, msg: "" },
      });
    },
    []
  );

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: ThemeProperties.purple,
          },
        }}
      >
        {console.log(columns)}
        <ReactDragListView.DragColumn {...dragProps}>
          {/* {props.data.responseStatus.code === 200 ||  props.data.responseStatus.code === -1? ( */}
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  let temp;
                  if (
                    event.target.classList[1] ===
                      "ant-table-selection-column" ||
                    event.target.classList[0] === "ant-checkbox-inner" ||
                    event.target.classList[2] === "ant-checkbox-checked" ||
                    event.target.classList[0] === "ant-checkbox-wrapper" ||
                    event.target.classList[0] === "ant-checkbox"
                  ) {
                  } else {
                    temp = {
                      ...queryParams,
                      batteryID: record.batteryID,
                      batterySearchedGlobal: "false",
                      tabValue: 0,
                      tabName: "Alerts",
                      prevLink: location.pathname + location.search,
                    };
                    encryptTheParams(temp, navigate, true);
                  }
                }, // click row
              };
            }}
            className="LandingPageTable_FleetBatteryHealth"
            locale={{
              emptyText: (
                <div style={{ minHeight: "500px", height: "500px" }}>
                  {list.responseStatus.code === 1999 ||
                  list.responseStatus.code === 404 ||
                  list.responseStatus.code === -2 ? (
                    <div style={{ height: "100%" }}>
                      <NoData text={"No alerts found"} />
                    </div>
                  ) : (
                    <p style={{ height: "100%", color: "white" }}></p>
                  )}
                </div>
              ),
            }}
            dataSource={
              list.responseStatus.code === CODE.LOADING
                ? []
                : list.responseStatus.code === 1999 ||
                  list.responseStatus.code === 404 ||
                  list.responseStatus.code === -2
                ? []
                : list.batteries
            }
            columns={columns}
            rowSelection={false}
            rowClassName="rowClass"
            loading={
              list.responseStatus.code === CODE.LOADING
                ? true
                : list.responseStatus.code === CODE.NODATA ||
                  list.responseStatus.code === -2
                ? false
                : false
            }
            // dataSource={list}
            pagination={tableParams.pagination}
            // loading={loader}
            onChange={handleTableChange}
            style={{ height: "900px" }}
            scroll={{ y: 700 }}
            bordered
            size="middle"
          />
          {/* ) : (
            <Table
              columns={columns}
              rowSelection={false}
              className="LandingPageTable_FleetBatteryHealth"
              locale={{
                emptyText: (
                  <div style={{ minHeight: "500px", height: "500px" }}>
                    {props.data.responseStatus.code === 404 ? (
                      <div style={{ height: "100%" }}>
                        <NoData text={"No batteries found"} />
                      </div>
                    ) : (
                      <p style={{ height: "100%", color: "white" }}></p>
                    )}
                  </div>
                ),
              }}
              rowClassName="rowClass"
              dataSource={[]}
              pagination={false}
              loading={false}
              style={{ height: "575px" }}
              scroll={{ y: 500 }}
              bordered
              size="middle"
            />
          )} */}
        </ReactDragListView.DragColumn>
      </ConfigProvider>
    </>
  );
}
