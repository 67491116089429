export const SetBatteryHealthToggleTable = (toggleTable) =>
{
    return {
        type: "BatteryHealthToggleTable",
        toggleTable: toggleTable
    }
}

export const SetBatteryHealthBatteryPartnerSelect = (value) =>
{
    return {
        type: "BatteryHealthBatteryPartnerSelect",
        select: value
    }
}
export const SetBatteryHealthBatteryID = (batteryID) =>
{
    return {
        type: "BatteryHealthBatteryID",
        batteryID: batteryID
    }
}

export const SetBatteryHealthDeviceID = (deviceID) =>
{
    return {
        type: "BatteryHealthDeviceID",
        deviceID: deviceID
    }
}

export const SetBatteryHealthPartner = (partner) =>
{
    return {
        type: "BatteryHealthPartner",
        partner: partner
    }
}

export const SetBatteryHealthCycleAge = (cycleAge) =>
{
    return {
        type: "BatteryHealthCycleAge",
        cycleAge: cycleAge
    }
}

export const SetBatteryHealthEqCycle = (eqcycle) =>
{
    return {
        type: "BatteryHealthEqCycle",
        eqcycle: eqcycle
    }
}

export const SetBatteryHealthSOH = (soh) =>
{
    return {
        type: "BatteryHealthSOH",
        soh: soh
    }
}

export const SetBatteryHealthVolt= (volt) =>
{
    return {
        type: "BatteryHealthVolt",
        volt: volt
    }
}


export const SetBatteryHealthCell= (cell) =>
{
    return {
        type: "BatteryHealthCell",
        cell: cell
    }
}

export const SetBatteryHealthBusiness = (business) =>
{
    return {
        type: "BatteryHealthBusiness",
        business: business
    }
}

export const SetBatteryHealthIOT = (iot) =>
{
    return {
        type: "BatteryHealthIOT",
        iot: iot
    }
}

export const SetBatteryHealthPack = (pack) =>
{
    return {
        type: "BatteryHealthPack",
        pack: pack
    }
}

export const SetBatteryHealthColumns = (columns) =>
{
    return {
        type: "BatteryHealthColumns",
        value: columns
    }
}

export const SetBatteryHealthSort = (sort) =>
{
    return {
        type: "BatteryHealthSort",
        sort: sort
    }
}

export const SetBatteryHealthSortOrder = (sortOrder) =>
{
    return {
        type: "BatteryHealthSortOrder",
        sortOrder: sortOrder
    }
}

export const SetBatteryHealthPageNumber = (pageNumber) =>
{
    return {
        type: "BatteryHealthPageNumber",
        pageNumber: pageNumber
    }
}

export const SetBatteryHealthRowsPerPage = (rowsPerPage) =>
{
    return {
        type: "BatteryHealthRowsPerPage",
        rowsPerPage: rowsPerPage
    }
}

