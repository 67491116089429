import React, { useEffect, useState } from "react";
import "./datePicker.css";
import { DatePicker } from "antd";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {formatDateNumeric} from "../DatePicker/DateFormatters";
import Events from "../../Analytics/Events";
import { getDatePickerDate } from "./DateConverter";
import moment from "moment";
import Cookies from "universal-cookie";


const AntDatePicker = (props) => {
  const { RangePicker } = DatePicker;
  const cookies = new Cookies();

  const [dates, setDates] = useState(null);
  const [value,setValue] = useState(null);

  dayjs.extend(utc);
  dayjs.extend(timezone)
  dayjs.tz.setDefault(cookies.get("timeZone"))

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().tz(), dayjs().tz()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().tz().add(-6, "d"), dayjs().tz()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().tz().add(-13, "d"), dayjs().tz()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().tz().add(-29, "d"), dayjs().tz()],
    },
    {
      label: "Last 45 Days",
      value: [dayjs().tz().add(-44, "d"), dayjs().tz()],
    },
  ];

  const onOpenChange = (open) => {
    if(dates)
    {
        if(dates[0] && !dates[1])
        {
            setDates([dates[0],dates[0]])
        }
        else if(!dates[0] && dates[1])
        {
            setDates([dates[1],dates[1]])
        }
    }

  };

useEffect(() => {
     const start = props.date[0] === undefined || props.date[0] === "" ? null : dayjs(props.date[0],'YYYY-MM-DD');
     const end = props.date[1] === undefined || props.date[1] === "" ? null : dayjs(props.date[1],'YYYY-MM-DD');
     setValue([start,end])
     if(start===null && end ===null)
     {
        setDates(null)
     }
     else
     {
      setDates([start,end])
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date[0],props.date[1]]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4F44E0",
        },
      }}
    >
        <RangePicker
        
        //   defaultValue={[dayjs('2015-01-01', dateFormat), dayjs('2015-01-01', dateFormat)]}
          format={"DD MMM, YYYY"}
          value={dates || value}
          presets={rangePresets}
          separator={false}
          // status={status}
          inputReadOnly={true}
          allowClear = {props.allowClear}
          onCalendarChange={(val) => {

            setDates(val);

            // console.log(val[0]["$d"],val[1]["$d"])
            if(val)
            {
                if(val[0] && val[1])
                {
                    // console.log("both exists",val[0]["$d"],val[1]["$d"])
                    props.changeTheDate(formatDateNumeric(val[0]["$d"]),formatDateNumeric(val[1]["$d"]))
                }
                else if(!val[0] && val[1]){
                    // console.log("end exists",val[1]["$d"])
                    props.changeTheDate(formatDateNumeric(val[1]["$d"]),formatDateNumeric(val[1]["$d"]))

                }
                else if(val[0] && !val[1])
                {
                    props.changeTheDate(formatDateNumeric(val[0]["$d"]),formatDateNumeric(val[0]["$d"]))
                }
                else
                {
                    props.changeTheDate("","")
                }
            }
            else
            {
                props.changeTheDate("", "");
                setValue(null)
                setDates(null)
            }
          }}
          // onChange={(val) => setValue(val)}
          onOpenChange={onOpenChange}
          disabledDate={(current) => {

            var date = getDatePickerDate()
            // console.log(date)
            if(props.date[0] === "" && props.date[1] === "")
            {
              return current && current > dayjs.tz();
            } else if (props.page === "digitalTwin") {
              return (current && current < dayjs().tz().add(-182, "d")) ||
              current > moment(date, "YYYY-MM-DD");
            }
            else
            {
            return current && current > moment(date, "YYYY-MM-DD");
            }
          }}
        />
    </ConfigProvider>
  );
};

export default AntDatePicker;
