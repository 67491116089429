const colors = [
  "#7856FF",
  "#FF7557",
  "#80E1D9",
  "#F8BC3B",
  "#B2596E",
  "#3BA974",
];

const metrics = [
  {
    key: "Current",
    value: "current",
    yAxisTitle: "Current (A)",
    ymin: -20,
    ymax: 20,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "A",
  },
  {
    key: "Voltage",
    value: "voltage",
    yAxisTitle: "Voltage (V)",
    ymin: 20,
    ymax: 35,
    decimalPlaces: 3,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "V",
  },
  {
    key: "SOC",
    value: "soc",
    yAxisTitle: "SOC (%)",
    ymin: 0,
    ymax: 100,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "%",
  },
  {
    key: "Temperature",
    value: "temperature",
    yAxisTitle: "Temperature (°C)",
    ymin: -20,
    ymax: 80,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "°C",
  },
  {
    key: "Capacity",
    value: "capacity",
    yAxisTitle: "Capacity (Ah)",
    ymin: 0,
    ymax: 125,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "Ah",
  },
  {
    key: "Cell Imbalance",
    value: "balanceState",
    yAxisTitle: "Cell Imbalance (%)",
    ymin: 0,
    ymax: 20,
    decimalPlaces: 3,
    multiplyBy: 100,
    yAxisDecimalPlaces: 0,
    unit: "%",
  },
  {
    key: "Energy",
    value: "energy",
    yAxisTitle: "Energy (kWh)",
    ymin: 0,
    ymax: 4,
    decimalPlaces: 3,
    multiplyBy: 0.001,
    yAxisDecimalPlaces: 1,
    unit: "kWh",
  },
];

const thresholds = {
  pack: {
    Voltage: {
      min: 20,
      max: 35,
    },
    Current: {
      min: -20,
      max: 20,
    },
    SOC: {
      min: 0,
      max: 100,
    },
    Temperature: {
      min: -20,
      max: 80,
    },
  },
  module: {
    Voltage: {
      min: 0,
      max: 10,
    },
    Current: {
      min: -20,
      max: 20,
    },
    SOC: {
      min: 0,
      max: 100,
    },
    Temperature: {
      min: -20,
      max: 80,
    },
  },
  cell: {
    Voltage: {
      min: 0,
      max: 5,
    },
    Current: {
      min: -20,
      max: 20,
    },
    SOC: {
      min: 0,
      max: 100,
    },
    Temperature: {
      min: -20,
      max: 80,
    },
  },
};

const opertaions = [
  {
    key: "Min",
    value: "min",
  },
  {
    key: "Max",
    value: "max",
  },
  {
    key: "Average",
    value: "avg",
  },
];

export { colors, metrics, thresholds, opertaions };
