import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { InnerBox, OuterBox } from "../../Components/CustomBox";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import ConfigurationForm from "./ConfigurationForm";
import DigitalTwinConfirmDialog from "../../Features/Dialog/DigitalTwin/DigitalTwinConfimDialog";
import { getConfigFilterValues } from "../../Api/DigitalTwinListApi";
import CODE from "../../Static/Constants/StatusCodes";
import { SetDigitalTwinsConfigFilters } from "../../Actions/DigitalTwinsList";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";

export default function AddConfiguration(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = decryptTheParams();
  const isView = queryParams["isView"];

  console.log(props);

  const configurationType = useSelector(
    (state) => state.DigitalTwinsConfigurationType
  );

  const configFilters = useSelector(
    (state) => state.DigitalTwinsConfigFilters.value
  );

  const [showDiscardDialog, setShowDiscardDialog] = useState(false);

  const handleDiscardConfirm = () => {
    navigate("/DigitalTwin");
  };

  const onCloseIconClick = () => {
    if (isView) {
      handleDiscardConfirm();
    } else {
      setShowDiscardDialog(true);
    }
  };

  const GetConfigFilters = async () => {
    if (configFilters?.length === 0) {
      await getConfigFilterValues().then((res) => {
        if (res?.responseStatus?.code === CODE.SUCCESS) {
          dispatch(SetDigitalTwinsConfigFilters(res?.response?.filterData));
        }
      });
    }
  };

  useEffect(() => {
    GetConfigFilters();
  }, []);

  return (
    <div>
      <OuterBox>
        <InnerBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 0.5,
              mb: 4,
            }}
          >
            <Typography variant="textSubtitleBold">{"Digital Twin"}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 0.5,
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "flex-row",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <IconButton
                onClick={onCloseIconClick}
                fontSize="medium"
                sx={{ marginRight: 1 }}
              >
                <CloseIcon style={{ fontSize: "18px" }} />
              </IconButton>
              <img width={19} src={configurationType.icon} />
              <Typography sx={{ paddingLeft: 1, fontSize: "18px" }}>
                {configurationType.name}
              </Typography>
            </Box>
          </Box>

          <hr style={{ borderTop: "1px solid #ececec" }}></hr>
          <Box sx={{ mt: 2 }}></Box>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} margin={2}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: ThemeProperties.purple,
                },
              }}
            >
              <ConfigurationForm permissions={props?.permissions} />
            </ConfigProvider>
          </Grid>
        </InnerBox>
      </OuterBox>

      <DigitalTwinConfirmDialog
        open={showDiscardDialog}
        onConfirm={handleDiscardConfirm}
        handleCloseDialog={() => setShowDiscardDialog(false)}
        message={"Changes are not saved and will be discarded. Are you sure?"}
        confirmButtonText="Proceed"
        confirmButtonColor="error"
        discardButtonText="Cancel"
      />
    </div>
  );
}
