const timezones = [
  {
      "offset": "GMT-12:00",
      "name": "Etc/GMT-12",
      "offsetNumeric": 720
  },
  {
      "offset": "GMT-11:00",
      "name": "Etc/GMT-11",
      "offsetNumeric": 660
  },
  {
      "offset": "GMT-11:00",
      "name": "Pacific/Midway",
      "offsetNumeric": 660
  },
  {
      "offset": "GMT-10:00",
      "name": "America/Adak",
      "offsetNumeric": 600
  },
  {
      "offset": "GMT-09:00",
      "name": "America/Anchorage",
      "offsetNumeric": 540
  },
  {
      "offset": "GMT-09:00",
      "name": "Pacific/Gambier",
      "offsetNumeric": 540
  },
  {
      "offset": "GMT-08:00",
      "name": "America/Dawson_Creek",
      "offsetNumeric": 480
  },
  {
      "offset": "GMT-08:00",
      "name": "America/Ensenada",
      "offsetNumeric": 480
  },
  {
      "offset": "GMT-08:00",
      "name": "America/Los_Angeles",
      "offsetNumeric": 480
  },
  {
      "offset": "GMT-07:00",
      "name": "America/Chihuahua",
      "offsetNumeric": 420
  },
  {
      "offset": "GMT-07:00",
      "name": "America/Denver",
      "offsetNumeric": 420
  },
  {
      "offset": "GMT-06:00",
      "name": "America/Belize",
      "offsetNumeric": 360
  },
  {
      "offset": "GMT-06:00",
      "name": "America/Cancun",
      "offsetNumeric": 360
  },
  {
      "offset": "GMT-06:00",
      "name": "America/Chicago",
      "offsetNumeric": 360
  },
  {
      "offset": "GMT-06:00",
      "name": "Chile/EasterIsland",
      "offsetNumeric": 360
  },
  {
      "offset": "GMT-05:00",
      "name": "America/Bogota",
      "offsetNumeric": 300
  },
  {
      "offset": "GMT-05:00",
      "name": "America/Havana",
      "offsetNumeric": 300
  },
  {
      "offset": "GMT-05:00",
      "name": "America/New_York",
      "offsetNumeric": 300
  },
  {
      "offset": "GMT-04:30",
      "name": "America/Caracas",
      "offsetNumeric": 270
  },
  {
      "offset": "GMT-04:00",
      "name": "America/Campo_Grande",
      "offsetNumeric": 240
  },
  {
      "offset": "GMT-04:00",
      "name": "America/Glace_Bay",
      "offsetNumeric": 240
  },
  {
      "offset": "GMT-04:00",
      "name": "America/Goose_Bay",
      "offsetNumeric": 240
  },
  {
      "offset": "GMT-04:00",
      "name": "America/Santiago",
      "offsetNumeric": 240
  },
  {
      "offset": "GMT-04:00",
      "name": "America/La_Paz",
      "offsetNumeric": 240
  },
  {
      "offset": "GMT-03:00",
      "name": "America/Argentina/Buenos_Aires",
      "offsetNumeric": 180
  },
  {
      "offset": "GMT-03:00",
      "name": "America/Montevideo",
      "offsetNumeric": 180
  },
  {
      "offset": "GMT-03:00",
      "name": "America/Araguaina",
      "offsetNumeric": 180
  },
  {
      "offset": "GMT-03:00",
      "name": "America/Godthab",
      "offsetNumeric": 180
  },
  {
      "offset": "GMT-03:00",
      "name": "America/Miquelon",
      "offsetNumeric": 180
  },
  {
      "offset": "GMT-03:00",
      "name": "America/Sao_Paulo",
      "offsetNumeric": 180
  },
  {
      "offset": "GMT-03:30",
      "name": "America/St_Johns",
      "offsetNumeric": 210
  },
  {
      "offset": "GMT-02:00",
      "name": "America/Noronha",
      "offsetNumeric": 120
  },
  {
      "offset": "GMT-01:00",
      "name": "Atlantic/Cape_Verde",
      "offsetNumeric": 60
  },
  {
      "offset": "GMT",
      "name": "Europe/Belfast",
      "offsetNumeric": 0
  },
  {
      "offset": "GMT",
      "name": "Africa/Abidjan",
      "offsetNumeric": 0
  },
  {
      "offset": "GMT",
      "name": "Europe/Dublin",
      "offsetNumeric": 0
  },
  {
      "offset": "GMT",
      "name": "Europe/Lisbon",
      "offsetNumeric": 0
  },
  {
      "offset": "GMT",
      "name": "Europe/London",
      "offsetNumeric": 0
  },
  {
      "offset": "UTC",
      "name": "UTC",
      "offsetNumeric": 0
  },
  {
      "offset": "GMT+01:00",
      "name": "Africa/Algiers",
      "offsetNumeric": -60
  },
  {
      "offset": "GMT+01:00",
      "name": "Africa/Windhoek",
      "offsetNumeric": -60
  },
  {
      "offset": "GMT+01:00",
      "name": "Atlantic/Azores",
      "offsetNumeric": -60
  },
  {
      "offset": "GMT+01:00",
      "name": "Atlantic/Stanley",
      "offsetNumeric": -60
  },
  {
      "offset": "GMT+01:00",
      "name": "Europe/Amsterdam",
      "offsetNumeric": -60
  },
  {
      "offset": "GMT+01:00",
      "name": "Europe/Belgrade",
      "offsetNumeric": -60
  },
  {
      "offset": "GMT+01:00",
      "name": "Europe/Brussels",
      "offsetNumeric": -60
  },
  {
      "offset": "GMT+02:00",
      "name": "Africa/Cairo",
      "offsetNumeric": -120
  },
  {
      "offset": "GMT+02:00",
      "name": "Africa/Blantyre",
      "offsetNumeric": -120
  },
  {
      "offset": "GMT+02:00",
      "name": "Asia/Beirut",
      "offsetNumeric": -120
  },
  {
      "offset": "GMT+02:00",
      "name": "Asia/Damascus",
      "offsetNumeric": -120
  },
  {
      "offset": "GMT+02:00",
      "name": "Asia/Gaza",
      "offsetNumeric": -120
  },
  {
      "offset": "GMT+02:00",
      "name": "Asia/Jerusalem",
      "offsetNumeric": -120
  },
  {
      "offset": "GMT+03:00",
      "name": "Africa/Addis_Ababa",
      "offsetNumeric": -180
  },
  {
      "offset": "GMT+03:00",
      "name": "Asia/Riyadh89",
      "offsetNumeric": -180
  },
  {
      "offset": "GMT+03:00",
      "name": "Europe/Minsk",
      "offsetNumeric": -180
  },
  {
      "offset": "GMT+03:30",
      "name": "Asia/Tehran",
      "offsetNumeric": -210
  },
  {
      "offset": "GMT+04:00",
      "name": "Asia/Dubai",
      "offsetNumeric": -240
  },
  {
      "offset": "GMT+04:00",
      "name": "Asia/Yerevan",
      "offsetNumeric": -240
  },
  {
      "offset": "GMT+04:00",
      "name": "Europe/Moscow",
      "offsetNumeric": -240
  },
  {
      "offset": "GMT+04:30",
      "name": "Asia/Kabul",
      "offsetNumeric": -270
  },
  {
      "offset": "GMT+05:00",
      "name": "Asia/Tashkent",
      "offsetNumeric": -300
  },
  {
      "offset": "GMT+05:30",
      "name": "Asia/Kolkata",
      "offsetNumeric": -330
  },
  {
      "offset": "GMT+05:45",
      "name": "Asia/Katmandu",
      "offsetNumeric": -345
  },
  {
      "offset": "GMT+06:00",
      "name": "Asia/Dhaka",
      "offsetNumeric": -360
  },
  {
      "offset": "GMT+06:00",
      "name": "Asia/Yekaterinburg",
      "offsetNumeric": -360
  },
  {
      "offset": "GMT+06:30",
      "name": "Asia/Rangoon",
      "offsetNumeric": -390
  },
  {
      "offset": "GMT+07:00",
      "name": "Asia/Bangkok",
      "offsetNumeric": -420
  },
  {
      "offset": "GMT+07:00",
      "name": "Asia/Novosibirsk",
      "offsetNumeric": -420
  },
  {
      "offset": "GMT+08:00",
      "name": "Etc/GMT+8",
      "offsetNumeric": -480
  },
  {
      "offset": "GMT+08:00",
      "name": "Asia/Hong_Kong",
      "offsetNumeric": -480
  },
  {
      "offset": "GMT+08:00",
      "name": "Asia/Krasnoyarsk",
      "offsetNumeric": -480
  },
  {
      "offset": "GMT+08:00",
      "name": "Australia/Perth",
      "offsetNumeric": -480
  },
  {
      "offset": "GMT+08:45",
      "name": "Australia/Eucla",
      "offsetNumeric": -525
  },
  {
      "offset": "GMT+09:00",
      "name": "Asia/Irkutsk",
      "offsetNumeric": -540
  },
  {
      "offset": "GMT+09:00",
      "name": "Asia/Seoul",
      "offsetNumeric": -540
  },
  {
      "offset": "GMT+09:00",
      "name": "Asia/Tokyo",
      "offsetNumeric": -540
  },
  {
      "offset": "GMT+09:30",
      "name": "Australia/Adelaide",
      "offsetNumeric": -570
  },
  {
      "offset": "GMT+09:30",
      "name": "Australia/Darwin",
      "offsetNumeric": -570
  },
  {
      "offset": "GMT+09:30",
      "name": "Pacific/Marquesas",
      "offsetNumeric": -570
  },
  {
      "offset": "GMT+10:00",
      "name": "Etc/GMT+10",
      "offsetNumeric": -600
  },
  {
      "offset": "GMT+10:00",
      "name": "Australia/Brisbane",
      "offsetNumeric": -600
  },
  {
      "offset": "GMT+10:00",
      "name": "Australia/Hobart",
      "offsetNumeric": -600
  },
  {
      "offset": "GMT+10:00",
      "name": "Asia/Yakutsk",
      "offsetNumeric": -600
  },
  {
      "offset": "GMT+10:30",
      "name": "Australia/Lord_Howe",
      "offsetNumeric": -630
  },
  {
      "offset": "GMT+11:00",
      "name": "Asia/Vladivostok",
      "offsetNumeric": -660
  },
  {
      "offset": "GMT+11:30",
      "name": "Pacific/Norfolk",
      "offsetNumeric": -690
  },
  {
      "offset": "GMT+12:00",
      "name": "Etc/GMT+12",
      "offsetNumeric": -720
  },
  {
      "offset": "GMT+12:00",
      "name": "Asia/Anadyr",
      "offsetNumeric": -720
  },
  {
      "offset": "GMT+12:00",
      "name": "Asia/Magadan",
      "offsetNumeric": -720
  },
  {
      "offset": "GMT+12:00",
      "name": "Pacific/Auckland",
      "offsetNumeric": -720
  },
  {
      "offset": "GMT+12:45",
      "name": "Pacific/Chatham",
      "offsetNumeric": -765
  },
  {
      "offset": "GMT+13:00",
      "name": "Pacific/Tongatapu",
      "offsetNumeric": -780
  },
  {
      "offset": "GMT+14:00",
      "name": "Pacific/Kiritimati",
      "offsetNumeric": -840
  }
]
  export {timezones}