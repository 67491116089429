import qs from "qs"
import { GetData } from "./Functions/SettingData";
import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../Helper/DatePicker/DateConverter";
import { newCity, newIOT, newPack, newSeverity, newStatus, newZone } from "../Static/Data/Dashboard/DashboardFilters";
import {areArraysEqual} from "../Helper/Operations/ArrayOps"


async function getAlertTypeList(
  tabvalue,
  volt,
  category,
  city,
  zone,
  batterystatus,
  severity,
  iotVendor,
  batteryVendor,
  priority,
  batteryID,
  partner,
  startDate,
  endDate,
  batteryType,
  queryParams
) {
  let alertState =
  tabvalue === 0
  ? "Active"
  : tabvalue === 1
  ? "Open"
  : tabvalue === 2
  ? "Resolved"
  : tabvalue === 3
  ? "Ignored"
  : "Expired";

  let newDeviceID,newBatteryID;
  if(queryParams && queryParams["type"]==="Device")
  {
    newDeviceID = queryParams["deviceID"]
    newBatteryID = ""
  }
  else if(queryParams && queryParams["type"]==="Battery")
  {
    newDeviceID = ""
    newBatteryID = batteryID
  }
  else
  {
    newDeviceID = ""
    newBatteryID = ""
  }
  var rawJson = {
    location: areArraysEqual(city,newCity) ? [] : city,
    zone: areArraysEqual(zone,newZone) ? [] : zone,
    voltConf: volt,
    alertCode: "",
    batteryStatus: areArraysEqual(batterystatus,newStatus) ? [] : batterystatus,
    severity: areArraysEqual(severity,newSeverity) ? [] : severity,
    iotVendor: areArraysEqual(iotVendor,newIOT) ? [] : iotVendor,
    batteryVendor: areArraysEqual(batteryVendor,newPack) ? [] : batteryVendor,
    priority: priority,
    alertState: alertState,
    batteryState: "",
    alertType: category,
    startDate: startDate === "" ? startDate : convertZonetoUTCStart(startDate),
    endDate: endDate === "" ? endDate : convertZonetoUTCEnd(endDate),
    assetID: newBatteryID,
    batteryID: newDeviceID,
    partner: partner,
    batteryType: batteryType,
    batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/alert_group_count/v2`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getTableBatteries(
  tabvalue,
  alertCode,
  volt,
  category,
  city,
  zone,
  batterystatus,
  severity,
  iotVendor,
  batteryVendor,
  priority,
  batteryID,
  partner,
  startDate,
  endDate,
  currentPage,
  sortBy,
  orderBy,
  rowsPerPage,
  batteryType,
  queryParams
) {
  let alertState =
  tabvalue === 0
  ? "Active"
  : tabvalue === 1
  ? "Open"
  : tabvalue === 2
  ? "Resolved"
  : tabvalue === 3
  ? "Ignored"
  : "Expired";

  let newDeviceID,newBatteryID;
  if(queryParams && queryParams["type"]==="Device")
  {
    newDeviceID = queryParams["deviceID"]
    newBatteryID = ""
  }
  else if(queryParams && queryParams["type"]==="Battery")
  {
    newDeviceID = ""
    newBatteryID = batteryID
  }
  else
  {
    newDeviceID = ""
    newBatteryID = ""
  }

  var rawJson = {
    location: areArraysEqual(city,newCity) ? [] : city,
    zone: areArraysEqual(zone,newZone) ? [] : zone,
    voltConf: volt,
    alertCode: alertCode,
    batteryStatus: areArraysEqual(batterystatus,newStatus) ? [] : batterystatus,
    severity: areArraysEqual(severity,newSeverity) ? [] : severity,
    iotVendor: areArraysEqual(iotVendor,newIOT) ? [] : iotVendor,
    batteryVendor: areArraysEqual(batteryVendor,newPack) ? [] : batteryVendor,
    priority: priority,
    alertState: alertState,
    batteryState: "",
    alertType: category,
    startDate: startDate === "" ? startDate : convertZonetoUTCStart(startDate),
    endDate: endDate === "" ? endDate : convertZonetoUTCEnd(endDate),
    assetID: newBatteryID,
    batteryID: newDeviceID,
    partner: partner,
    batteryType: batteryType,
    pageCount: rowsPerPage,
    sortBy: sortBy,
    order: orderBy,
    batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/alert_group/v2/${currentPage}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getDashboardKPI(batteryType) {
  var config = {
    method: "get",
    url: "/api/battery_count",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      batteryType: batteryType,
    },
  };
  let data = GetData(config);
  return data;
}

async function getBatteryPartnerList(columnName, search, limit, tabvalue) {
  let newColumnName = columnName === "Battery ID" ? "assetID" : "partner";
  let alertState =
  tabvalue === 0
  ? "Active"
  : tabvalue === 1
  ? "Open"
  : tabvalue === 2
  ? "Resolved"
  : tabvalue === 3
  ? "Ignored"
  : "Expired";

  var rawJson = {
    columnName: newColumnName,
    columnSearch: search,
    columnLimit: limit,
    alertState: alertState,
    batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/filter_alert_dashboard`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getSelectAllBatteries(
  city,
  zone,
  volt,
  category,
  alertCode,

  batterystatus,
  severity,
  iotVendor,
  batteryVendor,
  tabvalue,
  priority,
  batteryState,
  date,
  batteryID,
  partner,
  batteryType
) {
  let alertState =
    tabvalue === 0
      ? "Active"
      : tabvalue === 1
      ? "Open"
      : tabvalue === 2
      ? "Resolved"
      : tabvalue === 3
      ? "Ignored"
      : "Expired";

  var rawJson = {
    location: areArraysEqual(city,newCity) ? [] : city,
    zone: areArraysEqual(zone,newZone) ? [] : zone,
    voltConf: volt,
    alertCode: alertCode,
    batteryStatus: areArraysEqual(batterystatus,newStatus) ? [] : batterystatus,
    severity: areArraysEqual(severity,newSeverity) ? [] : severity,
    iotVendor: areArraysEqual(iotVendor,newIOT) ? [] : iotVendor,
    batteryVendor: areArraysEqual(batteryVendor,newPack) ? [] : batteryVendor,
    priority: priority,
    alertState: alertState,
    batteryState: "",
    alertType: category,
    startDate: date[0] === "" ? date[0] : convertZonetoUTCStart(date[0]),
    endDate: date[1] === "" ? date[1] : convertZonetoUTCEnd(date[1]),
    assetID: batteryID,
    batteryID: "",
    partner: partner,
    batteryType: batteryType,
    batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/global_selection_alert/v2`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

export {
  getTableBatteries,
  getAlertTypeList,
  getDashboardKPI,
  getBatteryPartnerList,
  getSelectAllBatteries,
};
