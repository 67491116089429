import React, { useEffect, useMemo } from "react";
import { Box, Paper, Typography, Button, Grid } from "@mui/material";
import "../../Features/Table/AntDesign/table.css";
import CircularProgress from "@mui/material/CircularProgress";
import { OuterBox, InnerBox } from "../../Components/CustomBox";

import TabWithColorDiagnostics from "../../Features/Tabs/TabWithColorDiagnostics";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import ListWithSelectionDiagnostics from "../../Features/Lists/ListWithSelectionDiagnostics";

// import DiagnosticsContainer from "../../Features/Table/TableContainer/DiagnosticsContainer";
import GenericSelectRedux from "../../Features/Menu/GenericSelectRedux";

import BasicTableDiagnostics from "../../Features/Table/AntDesign/BasicTableDiagnostics";
import DiagnosticsTabs from "../../Static/Data/Diagnostics/DiagnosticsTabs";
import Events from "../../Analytics/Events";

import { notification } from "antd";

import { useDispatch, useSelector } from "react-redux";

import {
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../../Static/Data/Dashboard/DashboardFilters";

import {
  getErrorTypeList,
  getTableBatteries,
  getCSVOfBatteries,
} from "../../Api/DiagnosticsApi";

import {
  SetDiagnosticsColumns,
  SetDiagnosticsPageNumber,
  SetDiagnosticsCity,
  SetDiagnosticsZone,
  SetDiagnosticsStatus,
  SetDiagnosticsTabValue,
  SetDiagnosticsErrorTypeIndex,
  SetDiagnosticsErrorTypeCode,
  SetDiagnosticsTotal,
  SetDiagnosticsStartDate,
  SetDiagnosticsEndDate,
  SetDiagnosticsStack,
  SetDiagnosticsIOT,
  SetDiagnosticsPack,
  SetDiagnosticsBatteryPartnerSelect,
  SetDiagnosticsBatteryID,
  SetDiagnosticsDeviceID,
  SetDiagnosticsPartner,
  SetDiagnosticsOpenAddSelectedBatteries,
} from "../../Actions/Diagnostics";
import { SetOrgFilter } from "../../Actions/Map";
import { SetSessionExpired } from "../../Actions";

import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV";
import { dashboardNonClickablekpis } from "../../Static/Data/Dashboard/DashboardKPIS";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import {
  decryptTheParams,
  encryptTheParams,
  resetTheFilterValues,
  setTheFilterValues,
} from "../../Helper/QueryParams/EncryptDecrypt";
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker";
import GenericMultipleSelect from "../../Features/Menu/GenericMultipleSelect";
import { areArraysEqual } from "../../Helper/Operations/ArrayOps";
import AutocompleteDashboard from "../../Features/Autocomplete/AutocompleteDashboard";
import RemoveSelectedBatteriesWarranty from "../../Features/Dialog/RemoveSelectedBatteriesWarranty";
import {
  getChartTimeStamp,
  getDatePickerDate,
} from "../../Helper/DatePicker/DateConverter";
import SecondsFormatter from "../../Helper/Formatters/SecondsFormatter";
import { getConnectedBatteries } from "../../Api/Api";
import { OutlinedButton } from "../../Components/CustomButton";
import moment from "moment";
import FindTheStatus from "../../Helper/Operations/FindTheStatus";

const Context = React.createContext({
  name: "Default",
});
// const { useToken } = theme;

function DiagnosticsMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newCity = localStorage.getItem("LocationFilter")?.split(",");
  const newOrg = localStorage.getItem("OrgFilter")?.split(",");
  //   const { token } = useToken();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
  const universal = useSelector((state) => state.DiagnosticsStack);
  const orderChangedTo = useSelector((state) => state.CustomOrder.orderBy);
  const sortChangedTo = useSelector((state) => state.DiagnosticsSort.arr);

  const [resetButton, setResetButton] = useState(false);

  const tabvalue = useSelector((state) => state.DiagnosticsTabValue.value);
  const errorCode = useSelector(
    (state) => state.DiagnosticsErrorTypeCode.value
  );
  const total = useSelector((state) => state.DiagnosticsTotal.value);
  const indexDiagType = useSelector(
    (state) => state.DiagnosticsErrorTypeIndex.value
  );
  const [connectedBatteries, setConnectedBatteries] = useState(0);
  const [errorTypeList, setErrorTypeList] = useState({
    list: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [listOfBatteries, setListOfBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });

  const batteryPartnerSelect = useSelector(
    (state) => state.DiagnosticsBatteryPartnerSelect.value
  );
  const batteryID = useSelector((state) => state.DiagnosticsBatteryID.value);
  const deviceID = useSelector((state) => state.DiagnosticsDeviceID.value);
  const partner = useSelector((state) => state.DiagnosticsPartner.value);
  const [mounted, setMounted] = useState(false);
  const [mountedCSVLoader, setMountedCSVLoader] = useState(false);

  const city = useSelector((state) => state.DiagnosticsCity.value);
  const org = useSelector((state) => state.OrgFilter.value);

  const zone = useSelector((state) => state.DiagnosticsZone.value);
  const batterystatus = useSelector((state) => state.DiagnosticsStatus.value);
  const iotVendor = useSelector((state) => state.DiagnosticsIOT.value);
  const packVendor = useSelector((state) => state.DiagnosticsPack.value);
  const reduxStartDate = useSelector(
    (state) => state.DiagnosticsStartDate.value
  );
  const reduxEndDate = useSelector((state) => state.DiagnosticsEndDate.value);
  const newColumns = useSelector((state) => state.DiagnosticsColumns.value);

  const filters2 = {
    Location: city,
    Organization: org,
    Status: batterystatus,
    IOT: iotVendor,
    "Product ID": packVendor,
  };
  const pageNumber = useSelector((state) => state.DiagnosticsPageNumber.value);
  const rowsPerPage = useSelector(
    (state) => state.DiagnosticsRowsPerPage.value
  );

  const openExportCSVConfirm = useSelector(
    (state) => state.DiagnosticsOpenAddSelectedBatteries.value
  );
  const handleOpenExportCSVConfirm = (event) => {
    dispatch(SetDiagnosticsOpenAddSelectedBatteries(true));
  };
  const handleCloseExportCSVConfirm = () => {
    dispatch(SetDiagnosticsOpenAddSelectedBatteries(false));
  };

  const ChangeTheTab = (newValue) => {
    if (universal.arr.length > 0) {
      dispatch(SetDiagnosticsOpenAddSelectedBatteries(true));
    } else {
      let temp;
      setMounted(false);
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
      setErrorTypeList({
        list: [],
        responseStatus: { code: -1, msg: "" },
      });
      dispatch(SetDiagnosticsTabValue(newValue));
      dispatch(SetDiagnosticsPageNumber(1));

      if (newValue === 0) {
        Events(`clicked ${props.pagesContent.pageHeader} Active`);
      } else if (newValue === 1) {
        Events(`clicked ${props.pagesContent.pageHeader} Resolved`);
      }
      const queryParams = decryptTheParams();
      temp = {
        ...queryParams,
        tabValue: newValue,
      };
      encryptTheParams(temp, navigate);
    }
  };

  function handleSetSelect(name, value) {
    let temp;
    const queryParams = decryptTheParams();

    temp = {
      ...queryParams,
      batteryPartnerSelect: value,
      batteryID: "",
      deviceID: "",
      partner: "",
    };
    dispatch(SetDiagnosticsBatteryPartnerSelect(value));
    dispatch(SetDiagnosticsBatteryID(""));
    dispatch(SetDiagnosticsDeviceID(""));
    dispatch(SetDiagnosticsPartner(""));
    if (batteryID !== "" || partner !== "" || deviceID !== "")
      resetTheFilters();

    encryptTheParams(temp, navigate);
  }

  function handleSetFilter(filterName, value) {
    Events("changed Alerts " + filterName);
    setTheFilterValues(filterName, value, navigate);
    dispatch(SetDiagnosticsPageNumber(1));

    if (filterName === "Location") {
      dispatch(SetDiagnosticsCity(value));
    } else if (filterName === "Status") {
      dispatch(SetDiagnosticsStatus(value));
    } else if (filterName === "Organization") {
      dispatch(SetOrgFilter(value));
    } else if (filterName === "IOT") {
      dispatch(SetDiagnosticsIOT(value));
    } else if (filterName === "Product ID") {
      dispatch(SetDiagnosticsPack(value));
    }
  }

  function resetTheFilters() {
    dispatch(SetDiagnosticsCity(newCity));
    dispatch(SetOrgFilter(newOrg));
    dispatch(SetDiagnosticsZone(newZone));
    dispatch(SetDiagnosticsStatus(newStatus));
    dispatch(SetDiagnosticsIOT(newIOT));
    dispatch(SetDiagnosticsPack(newPack));

    dispatch(
      SetDiagnosticsStartDate(
        getDatePickerDate(
          new Date(new Date().setDate(new Date().getDate() - 45))
        )
      )
    );
    dispatch(SetDiagnosticsEndDate(getDatePickerDate()));
    dispatch(SetDiagnosticsPageNumber(1));
    resetTheFilterValues(props.pagesContent.filteredArray, navigate);
  }

  function changeTheDate(startDate, endDate) {
    Events("changed Alerts date");
    let temp;
    const queryParams = decryptTheParams();
    dispatch(SetDiagnosticsPageNumber(1));

    if (startDate === null && endDate === null) {
      dispatch(SetDiagnosticsStartDate(""));
      dispatch(SetDiagnosticsEndDate(""));
      temp = {
        ...queryParams,
        startDate: "",
        endDate: "",
      };
    } else if (endDate === null) {
      dispatch(SetDiagnosticsStartDate(startDate));
      dispatch(SetDiagnosticsEndDate(startDate));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: startDate,
      };
    } else if (startDate === null) {
      dispatch(SetDiagnosticsStartDate(endDate));
      dispatch(SetDiagnosticsEndDate(endDate));
      temp = {
        ...queryParams,
        startDate: endDate,
        endDate: endDate,
      };
    } else {
      dispatch(SetDiagnosticsStartDate(startDate));
      dispatch(SetDiagnosticsEndDate(endDate));
      temp = {
        ...queryParams,
        startDate: startDate,
        endDate: endDate,
      };
    }
    encryptTheParams(temp, navigate);
  }

  function getErrorType() {
    errorTypeList.responseStatus.code !== -1 &&
      setErrorTypeList({
        list: [],
        responseStatus: { code: -1, msg: "" },
      });
    getErrorTypeList(
      tabvalue,
      org,
      city, //here
      zone,
      batterystatus,
      packVendor,
      iotVendor,

      reduxStartDate,
      reduxEndDate,
      batteryID,
      deviceID,
      partner,
      batteryPartnerSelect
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        let tempAll = [],
          totalCount = 0;
        res.response.errorList.map((item) => {
          totalCount = totalCount + item["errorCount"];
        });

        tempAll.push(...res.response.errorList);
        setErrorTypeList({
          list: tempAll,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        if (errorCode !== "") {
          if (indexDiagType === 0) {
            if (tempAll[0]["errorCode"] !== errorCode) {
              dispatch(SetDiagnosticsErrorTypeCode(tempAll[0]["errorCode"]));
              dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
            } else {
              dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
            }
          } else {
            var result = tempAll.find((item) => item.errorCode === errorCode);
            var index = tempAll.findIndex(
              (item) => item.errorCode === errorCode
            );
            if (result === undefined) {
              dispatch(SetDiagnosticsErrorTypeIndex(0));
              dispatch(SetDiagnosticsErrorTypeCode(tempAll[0]["errorCode"]));
              dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
            } else {
              dispatch(SetDiagnosticsTotal(result["warrantyCount"]));
              dispatch(SetDiagnosticsErrorTypeIndex(index));
            }
          }
        } else {
          dispatch(SetDiagnosticsErrorTypeCode(tempAll[0]["errorCode"]));
          dispatch(SetDiagnosticsTotal(tempAll[0]["errorCount"]));
        }
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setErrorTypeList({
          list: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        dispatch(SetDiagnosticsErrorTypeCode(""));
        dispatch(SetDiagnosticsErrorTypeIndex(0));
        dispatch(SetDiagnosticsTotal(0));
      }
    });
  }

  function getListOfBatteries() {
    let queryParams = decryptTheParams();
    listOfBatteries.responseStatus.code !== -1 &&
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });

    errorTypeList.responseStatus.code !== -1 &&
      getTableBatteries(
        tabvalue,
        org,
        city, //here
        zone,
        batterystatus,
        packVendor,
        iotVendor,
        [],

        errorCode,
        reduxStartDate,
        reduxEndDate,
        batteryID,
        deviceID,
        partner,
        batteryPartnerSelect,

        pageNumber,
        sortChangedTo[tabvalue]["sortBy"],
        orderChangedTo,
        rowsPerPage
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          setListOfBatteries({
            batteries: res.response.errorList,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });

          if (batteryID !== "") {
            let temp;
            temp = {
              ...queryParams,
              deviceID: res.response.errorList[0]["batteryID"],
              type: "Device",
            };
            encryptTheParams(temp, navigate);
          }
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setListOfBatteries({
            batteries: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    //}
    setMounted(true);
  }

  function FilterBox() {
    return (
      <>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GenericSelectRedux
                filter={props.pagesContent.searchTypeFilter}
                handleSetFilter={handleSetSelect}
                selected={batteryPartnerSelect}
                maxWidth={"max-content"}
                showClearButton={false}
                id="batteryState"
              />
              <AutocompleteDashboard
                width={300}
                resetTheFilters={resetTheFilters}
                tabValue={tabvalue}
                alertCode={""}
                page={"Diagnostics"}
                openNotification={openNotification}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-end",
                xl: "flex-end",
              },
            }}
          >
            <Box className="filterDateRange">
              <Box sx={{ display: "flex" }}>
                {resetButton && (
                  <Button
                    sx={{ fontSize: "12px", height: "30px", mt: 1.5, mr: 0.5 }}
                    onClick={() => resetTheFilters()}
                  >
                    Reset
                  </Button>
                )}

                {props.pagesContent.filtersWithData.map(
                  ({ itemName, items }, index) => {
                    return (
                      <Box key={index}>
                        <GenericMultipleSelect
                          label={itemName}
                          options={items}
                          maxWidth={"100px"}
                          optionValue={filters2[itemName]}
                          handleSetFilter={handleSetFilter}
                          id="overflow-text-3"
                        />
                      </Box>
                    );
                  }
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  function showResetButton() {
    if (
      !areArraysEqual(org, newOrg) ||
      !areArraysEqual(city, newCity) ||
      !areArraysEqual(batterystatus, newStatus) ||
      !areArraysEqual(iotVendor, newIOT) ||
      !areArraysEqual(packVendor, newPack)
    ) {
      setResetButton(true);
    } else {
      setResetButton(false);
    }
  }

  function CreateCSV() {
    const newBatteries = [];
    let newInsertionTime, newLastPingedTime, newResolvedOn, tab;
    setMountedCSVLoader(true);
    getCSVOfBatteries(universal.arr).then((res) => {
      if (res.responseStatus.code === 200) {
        res.response.errorList.map((row, index) => {
          if ("errorTime" in row)
            newInsertionTime = row.errorTime
              ? moment(getChartTimeStamp(row.errorTime)).format(
                  "DD MMM'YY hh:mm A"
                )
              : "";
          if ("lastPingedTime" in row)
            newLastPingedTime = row.lastPingedTime
              ? moment(getChartTimeStamp(row.lastPingedTime)).format(
                  "DD MMM'YY hh:mm A"
                )
              : "";
          if ("resolutionTime" in row)
            newResolvedOn = row.resolutionTime
              ? moment(getChartTimeStamp(row.resolutionTime)).format(
                  "DD MMM'YY hh:mm A"
                )
              : "";

          if (tabvalue === 0) {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Pack ID": row.packID,
              "SOC (%)": row.socBMS,
              "Current (A)": row.currentPack?.toFixed(1),
              "Voltage (V)": row.voltagePack?.toFixed(1),
              "Temperature (°C)": row.temperature,
              "Alert First Seen": newInsertionTime,
              "Last Pinged": newLastPingedTime,
              "Lat/Long":
                row.latitude?.toFixed(4) + "/" + row.longitude?.toFixed(4),
              Location: row.location,
              Status: FindTheStatus(row.batteryStatus),
              IOT: row.iotVendor,
            });
          } else if (tabvalue === 1) {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Pack ID": row.packID,
              "SOC (%)": row.socBMS,
              "Current (A)": row.currentPack?.toFixed(1),
              "Voltage (V)": row.voltagePack?.toFixed(1),
              "Temperature (°C)": row.temperature,
              "Resolved On": newResolvedOn,
              "Alert First Seen": newInsertionTime,
              "Resolution Time": SecondsFormatter(row.deltaTime),
              "Last Pinged": newLastPingedTime,
              "Lat/Long":
                row.latitude?.toFixed(4) + "/" + row.longitude?.toFixed(4),
              Location: row.location,
              Status: FindTheStatus(row.batteryStatus),
              IOT: row.iotVendor,
            });
          }
        });
        if (tabvalue === 0) {
          tab = "Active";
        } else {
          tab = "Resolved";
        }
        DownloadCSV(NameTheCSV("Diagnostics", tab), newBatteries);
        setMountedCSVLoader(false);
        dispatch(SetDiagnosticsStack([]));
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setMountedCSVLoader(false);
        dispatch(SetDiagnosticsStack([]));
      }
    });
  }

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneChanged]);

  useEffect(() => {
    showResetButton();
    if (
      org.length > 0 &&
      batterystatus.length > 0 &&
      city.length > 0 &&
      iotVendor.length > 0 &&
      packVendor.length > 0
    )
      getErrorType();
    else {
      setErrorTypeList({
        list: [],
        responseStatus: {
          code: 404,
          msg: "No data found",
        },
      });
    }
  }, [
    timezoneChanged,
    tabvalue,
    org,
    city,
    zone,
    batterystatus,
    iotVendor,
    packVendor,
    reduxStartDate,
    reduxEndDate,
    batteryID,
    deviceID,
    partner,
  ]);

  useEffect(() => {
    setMounted(false);
    const tempColumns = [...newColumns];
    const newOrderChangedTo = orderChangedTo === "desc" ? "descend" : "ascend";
    tempColumns[tabvalue]["columns"].forEach((column, i) => {
      if (
        column.key === sortChangedTo[tabvalue]["sortBy"] &&
        column.sorter === true
      ) {
        column.defaultSortOrder = newOrderChangedTo;
      } else {
        column.defaultSortOrder = "";
      }
    });

    dispatch(SetDiagnosticsColumns(tempColumns));
    // errorCode !=="" cant be done; since all type code has errorCode = ""
    if (
      org.length > 0 &&
      batterystatus.length > 0 &&
      city.length > 0 &&
      zone.length > 0 &&
      iotVendor.length > 0 &&
      packVendor.length > 0
    )
      getListOfBatteries();
    else {
      setMounted(true);
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: 404, msg: "" },
      });
    }
  }, [
    timezoneChanged,
    tabvalue,
    errorTypeList,
    errorCode,
    org,
    city,
    zone,
    batterystatus,
    iotVendor,
    packVendor,
    reduxStartDate,
    reduxEndDate,
    sortChangedTo[tabvalue]["sortBy"],
    orderChangedTo,
    batteryID,
    deviceID,
    partner,
    pageNumber,
    rowsPerPage,
  ]);

  return (
    <div>
      <OuterBox>
        <InnerBox>
          <Context.Provider value={contextValue}>
            {contextHolder}
            <PageHeaderKPI
              name={"Alerts"} //Diagnostics
              nonClickableKpis={dashboardNonClickablekpis}
              connectedBatteries={connectedBatteries}
            />

            <Box sx={{ mt: 1.5, position: "relative" }}>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{ position: "absolute", right: 0, top: 13, zIndex: 1 }}
                >
                  {universal.arr.length > 0 ? (
                    <>
                      {mountedCSVLoader ? (
                        <OutlinedButton
                          variant="outlined"
                          color="primary"
                          sx={{ ml: 2 }}
                        >
                          <CircularProgress size="1rem" />

                          <Typography
                            variant={"textSubtitlePurple"}
                            sx={{ width: "138px" }}
                          >
                            Loading...
                          </Typography>
                        </OutlinedButton>
                      ) : (
                        <OutlinedButton
                          variant="outlined"
                          color="primary"
                          sx={{ ml: 2 }}
                          onClick={() => {
                            Events("Alerts csv downloaded");
                            CreateCSV();
                          }}
                        >
                          <Typography variant={"textSubtitlePurple"}>
                            Export csv : <b>{universal.arr.length}</b> batteries
                          </Typography>
                        </OutlinedButton>
                      )}
                    </>
                  ) : (
                    <OutlinedButton
                      variant="outlined"
                      color="primary"
                      sx={{ ml: 2 }}
                      disabled
                    >
                      <Typography variant={"textSubtitleGrey"}>
                        Export csv : <b>{universal.arr.length}</b> batteries
                      </Typography>
                    </OutlinedButton>
                  )}
                </Box>

                <Box
                  sx={{ position: "absolute", right: 200, zIndex: 1, top: 13 }}
                >
                  <AntDatePicker
                    page="alerts"
                    changeTheDate={changeTheDate}
                    date={[reduxStartDate, reduxEndDate]}
                    allowClear={false}
                  />
                </Box>

                <TabWithColorDiagnostics
                  ChangeTheTab={ChangeTheTab}
                  tabs={DiagnosticsTabs}
                />
                <br></br>

                {DiagnosticsTabs.map((item, index) => (
                  <BasicTabPanel value={1} index={index} key={index}>
                    <Paper
                      elevation={0}
                      sx={{
                        height: "100%",
                        borderRadius: "12px",
                        p: 1,
                        border: "1px solid #ececec",
                      }}
                    >
                      {FilterBox()}
                      <hr style={{ borderTop: "1px solid #ececec" }}></hr>
                      <Box sx={{ mt: 1.5 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                            <ListWithSelectionDiagnostics
                              data={errorTypeList}
                            />
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                            {mounted && (
                              <BasicTableDiagnostics
                                data={listOfBatteries}
                                total={total}
                                columns={newColumns}
                                rowSelection={true}
                                city={city}
                                zone={zone}
                                org={org}
                                batterystatus={batterystatus}
                                iot={iotVendor}
                                pack={packVendor}
                                tabvalue={tabvalue}
                                errorCode={errorCode}
                                batteryState={""}
                                date={[reduxStartDate, reduxEndDate]}
                                deviceID={deviceID}
                                batteryID={batteryID}
                                partner={partner}
                                batteryPartnerSelect={batteryPartnerSelect}
                                existingBatteries={[]}
                                openNotification={openNotification}
                                handleOpenExportCSVConfirm={
                                  handleOpenExportCSVConfirm
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </BasicTabPanel>
                ))}
              </Box>
            </Box>
          </Context.Provider>
        </InnerBox>
      </OuterBox>
      <RemoveSelectedBatteriesWarranty
        openExportCSVConfirm={openExportCSVConfirm}
        handleCloseExportCSVConfirm={handleCloseExportCSVConfirm}
        CreateCSV={CreateCSV}
        page={"Diagnostics"}
        heading={"Attention! Moving to different Tab/Page"}
      />
    </div>
  );
}

export default React.memo(DiagnosticsMain);

//   const columnHide = useSelector(
//     (state) => state.DiagnosticsHiddenColumns.value
//   );

//   const contentStyle = {
//     backgroundColor: token.colorBgElevated,
//     borderRadius: token.borderRadiusLG,
//     boxShadow: token.boxShadowSecondary,
//   };
//   const menuStyle = {
//     boxShadow: "none",
//   };
//   console.log("hidden columns going into items ", columnHide);
//   const items = [
//     ...newColumns[tabvalue]["columns"].map((d, i) => {
//       if (d.name !== "")
//         if (d.dataIndex === "batteryID" || d.dataIndex === "soc") {
//           return {
//             key: i + 1,
//             label: (
//               <AntCheckbox
//                 // color="primary"
//                 key={i + 1}
//                 checked={true}
//                 disabled
//                 // onChange={() => onFilterCheckboxClick(d.uniqueKey)}
//               >
//                 {d.name}
//               </AntCheckbox>
//             ),
//           };
//         } else {
//           return {
//             key: i + 1,
//             label: (
//               <AntCheckbox
//                 // color="primary"
//                 key={i + 1}
//                 checked={!columnHide.includes(d.uniqueKey)}
//                 onChange={() => onFilterCheckboxClick(d.uniqueKey)}
//               >
//                 {d.name}
//               </AntCheckbox>
//             ),
//           };
//         }
//     }),
//   ];
//   const onClickApply = () => {
//     const tempColumns = [...newColumns];
//     const tempHiddenColumns = [...columnHide];

//     tempColumns[tabvalue]["columns"].forEach((column, i) => {
//       if (column.name !== "") {
//         if (columnHide.includes(column.uniqueKey)) {
//           column.hidden = true;
//           // tempHiddenColumns.push(column.uniqueKey)
//         } else {
//           column.hidden = false;
//         }
//       }
//     });
//     console.log("onClickApply called");
//     console.log("columns are ", tempColumns);
//     dispatch(SetDiagnosticsColumns([...tempColumns]));
//     // dispatch(SetDiagnosticsHiddenColumns([...tempHiddenColumns]));
//     setOpen(false);
//   };

//   const onClickReset = () => {
//     const tempColumns = [...newColumns];
//     const tempHiddenColumns = [...columnHide];

//     tempColumns[tabvalue]["columns"].forEach((column, i) => {
//       if (column.name !== "") {
//         column.hidden = false;
//         const index = tempHiddenColumns.indexOf(column.uniqueKey);
//         if (index > -1) {
//           // only splice array when item is found
//           tempHiddenColumns.splice(index, 1); // 2nd parameter means remove one item only
//         }
//       }
//     });
//     // const arr = newColumns[tabvalue]["columns"];
//     // setColumns([...arr]);
//     console.log("onClickReset called");
//     console.log("columns are ", tempColumns);
//     console.log("hiden columns are ", tempHiddenColumns);
//     dispatch(SetDiagnosticsColumns([...tempColumns]));

//     dispatch(SetDiagnosticsHiddenColumns([...tempHiddenColumns]));
//     setOpen(false);
//   };

//   const handleOpenChange = () => {
//     setOpen(!open);
//   };

//   const onFilterCheckboxClick = (colName) => {
//     if (columnHide.includes(colName)) {
//       // setColumnHide([...columnHide.filter((ele) => ele != colName)]);
//       dispatch(
//         SetDiagnosticsHiddenColumns([
//           ...columnHide.filter((ele) => ele != colName),
//         ])
//       );
//     } else {
//       // setColumnHide([...columnHide, colName]);
//       dispatch(SetDiagnosticsHiddenColumns([...columnHide, colName]));
//     }
//   };

//   const handleOnOpenChange = () => {
//     console.log("handleOnOpenChange called");
//     console.log("column Hide includes ", columnHide);

//     const arr = [];

//     newColumns[tabvalue]["columns"].forEach((col) => {
//       if (col.hidden === true) {
//         arr.push(col.uniqueKey);
//       }
//     });
//     // setColumnHide([...arr])
//     dispatch(SetDiagnosticsHiddenColumns([...arr]));

//     setOpen(!open);
//   };

// function handleRequestPage(pageNumber) {
//   setPageNumber(pageNumber + 1);
// }
{
  /* <ConfigProvider
            theme={{
              token: {
                colorPrimary: ThemeProperties.purple,
              },
            }}
          >
            <Dropdown
              menu={{
                items,
              }}
              className="dropDowndDiv"
              // disabled
              open={open}
              onOpenChange={handleOnOpenChange}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div>
                  <div className="dropDown" style={contentStyle}>
                    <div className="dropDownTop">
                      <Typography variant="tableHead">
                        Select columns to show
                      </Typography>
                    </div>

                    <div className="dropDownMiddle">
                      {React.cloneElement(menu, {
                        style: menuStyle,
                      })}
                    </div>

                    <div className="dropDownBottom">
                      <AntButton onClick={() => onClickReset()}>
                        Reset
                      </AntButton>
                      <AntButton type="primary" onClick={() => onClickApply()}>
                        Apply
                      </AntButton>
                    </div>
                  </div>
                </div>
              )}
            >
              <Space>
                <Avatar
                  variant="square"
                  sx={{
                    bgcolor: "white",
                    border: "1px solid #cecece",
                    borderRadius: "4px",
                    width: 35,
                    height: 35,
                    mt: 0.5,
                    ml: 1,
                  }}
                >
                  <ViewWeekOutlinedIcon
                    onClick={handleOpenChange}
                    sx={{
                      cursor: "pointer",
                      fontSize: "25px",
                      color:
                        newColumns[tabvalue]["columns"].filter(
                          (item) => item.hidden === true
                        ).length > 0
                          ? ThemeProperties.purple
                          : ThemeProperties.greyTextColor,
                    }}
                  />
                </Avatar>
              </Space>
            </Dropdown>
          </ConfigProvider> */
}
