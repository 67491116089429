import React, { useEffect } from "react";
import useState from "react-usestateref";

import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import SlickCarousel from "../../../../Features/Carousel/SlickCarousel";
import GenericSelectKeyValue from "../../../../Features/Menu/GenericSelectKeyValue";

import {
  formatDateNumeric,
  formatDate,
  DateDiffInDays,
  getWeekNumber,
  getArrayOfNumericDates,
  getArrayOfFormattedDates,
} from "../../../../Helper/DatePicker/DateFormatters";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../../Helper/QueryParams/EncryptDecrypt";
import Loader from "../../../../Helper/Loaders/Loader";

import KeyMetrics from "./KeyMetrics";
import { opertaions } from "../BatteryVitals/_Constants";

import { useNavigate } from "react-router-dom";
import { convertToZoneDate } from "../../../../Helper/DatePicker/DateConverter";

function lastSunday(year, month) {
  var date = new Date(year, month, 1, 12);
  let weekday = date.getDay();
  let dayDiff = weekday === 0 ? 7 : weekday;
  date.setDate(date.getDate() - dayDiff);
  return date;
}

const ChargerVitalsMain = (props) => {
  const navigate = useNavigate();
  const queryParams = decryptTheParams();

  let granularity = queryParams["granularity"]
    ? queryParams["granularity"]
    : "Raw Data";

  const [mountTheSlick, setMountTheSlick] = useState(false);
  const [arrayOfDates, setArrayOfDatesFormatted] = useState([]);
  const [arrayOfDatesNumeric, setArrayOfDatesNumeric] = useState([]);
  const [startDate, setStartDate, refStartDate] = useState("");
  const [granular, setGranular] = useState(granularity);
  const [operationType, setOperationType] = useState("avg");

  // const level = hierarchy.level;
  const hierarchy = props.hierarchy;

  const setTheDate = (date) => {
    setStartDate(date);
    // console.log(date)
    let temp;
    temp = {
      ...queryParams,
      batteryPackMetrics: date,
    };
    encryptTheParams(temp, navigate, true);
  };

  const setTheQuery = (date) => {
    let temp;
    temp = {
      ...queryParams,
      batteryPackMetrics: date,
    };
    encryptTheParams(temp, navigate, true);
  };

  function handleSetFinalStartDate(value) {
    setTheQuery(value);

    let queryParams = decryptTheParams();
    if (queryParams && queryParams["batteryPackMetrics"]) {
      setStartDate(queryParams["batteryPackMetrics"]);
    } else {
      setStartDate(value);
    }
  }

  const handleSetFilter = (name, value) => {
    setGranular(value);
    let temp;
    let queryParams = decryptTheParams();
    delete queryParams.batteryPackMetrics;

    temp = {
      ...queryParams,
      granularity: value,
    };
    encryptTheParams(temp, navigate, true);
  };

  const handleClickOperation = (value) => {
    setOperationType(value);
  };

  useEffect(()=>{
    let dateDiff =
    props.dateString[0] && props.dateString[1]
        ? DateDiffInDays(props.dateString[0],props.dateString[1])
        : "Invalid";

    let temp;
    if (dateDiff < 7)
    {
      setGranular("Raw Data");
      setOperationType("avg");
      temp = {
        ...queryParams,
        granularity: "Raw Data",
      }
    }
    encryptTheParams(temp, navigate, true);
  },[props.dateString[0],props.dateString[1]])

  useEffect(() => {
    setMountTheSlick(false);

    if (granular === "Raw Data") {
      if (props.dateString[0] !== "" || props.dateString[1] !== "") {
        var tempFormatted = getArrayOfFormattedDates(
          props.dateString[0],
          props.dateString[1]
        );
        var tempNumeric = getArrayOfNumericDates(
          props.dateString[0],
          props.dateString[1]
        );

        setArrayOfDatesFormatted(tempFormatted);
        setArrayOfDatesNumeric(tempNumeric);
        handleSetFinalStartDate(tempNumeric[0]);
      }
    }

    if (granular !== "Raw Data") {
      if (granular === "Daily") {
        var tempFormatted = getArrayOfFormattedDates(
          props.dateString[0],
          props.dateString[1]
        );
        var tempNumeric = getArrayOfNumericDates(
          props.dateString[0],
          props.dateString[1]
        );

        var buckets = [],
          tempDatesToShow = [],
          tempDatesToSave = [];
        for (var i = 0; i < tempFormatted.length; i = i + 15) {
          var tempStart = tempFormatted[i],
            tempEnd =
              tempFormatted[i + 14] === undefined
                ? tempFormatted[tempFormatted.length - 1]
                : tempFormatted[i + 14],
            tempStartSave = tempNumeric[i],
            tempEndSave =
              tempNumeric[i + 14] === undefined
                ? tempNumeric[tempNumeric.length - 1]
                : tempNumeric[i + 14];

          buckets.push({
            start: tempStart,
            end: tempEnd,
            textToShow: tempStart + " to " + tempEnd,
            textToSave: tempStartSave + ":" + tempEndSave,
          });
          tempDatesToShow.push(tempStart + " to " + tempEnd);
          tempDatesToSave.push(tempStartSave + ":" + tempEndSave);
        }

        setArrayOfDatesFormatted(tempDatesToShow);
        setArrayOfDatesNumeric(tempDatesToSave);
        handleSetFinalStartDate(tempDatesToSave[0]);
      } else if (granular === "Weekly") {
        let diffInDays = DateDiffInDays(
          props.dateString[0],
          props.dateString[1]
        );
        if (diffInDays > 105) {
          var myStart = formatDate(convertToZoneDate(props.dateString[0]));
          var myEnd = formatDate(convertToZoneDate(props.dateString[1]));

          var tempDateRanges = [];

          var tempStart = new Date(myStart);
          let tempEnd = new Date(tempStart.setDate(tempStart.getDate() + 105));

          tempDateRanges.push({
            start: new Date(myStart),
            end: lastSunday(
              tempEnd.getUTCFullYear(),
              tempEnd.getUTCMonth() + 1
            ),
            startWeek: getWeekNumber(new Date(myStart)),
            endWeek: getWeekNumber(
              lastSunday(tempEnd.getUTCFullYear(), tempEnd.getUTCMonth() + 1)
            ),
          });

          var chi = new Date(tempDateRanges[0]["end"]);
          var nextStartDate = new Date(chi.setDate(chi.getDate() + 1));

          var i = 1;
          while (nextStartDate < new Date(myEnd)) {
            let confusious = new Date(
              nextStartDate.setDate(nextStartDate.getDate() + 105)
            );

            tempDateRanges.push({
              start: new Date(
                tempDateRanges[i - 1]["end"].getTime() + 24 * 60 * 60 * 1000
              ),
              end: lastSunday(
                confusious.getUTCFullYear(),
                confusious.getUTCMonth() + 1
              ),
            });

            i++;
          }

          tempDateRanges[tempDateRanges.length - 1]["end"] = new Date(myEnd);

          let finalRanges = [];
          tempDateRanges.map((item) => {
            let tempStart = getWeekNumber(new Date(item.start)),
              tempEnd = getWeekNumber(new Date(item.end));
            finalRanges.push({
              start: formatDateNumeric(item.start),
              end: formatDateNumeric(item.end),
              startWeek: tempStart,
              endWeek: tempEnd,
              textToShow: "week " + tempStart + " to week " + tempEnd,
              textToSave:
                formatDateNumeric(item.start) +
                ":" +
                formatDateNumeric(item.end),
            });
          });
          let tempDatesToShow = [],
            tempDatesToSave = [];

          finalRanges.map((item) => {
            tempDatesToShow.push(item.textToShow);
            tempDatesToSave.push(item.textToSave);
          });

          setArrayOfDatesFormatted(tempDatesToShow);
          setArrayOfDatesNumeric(tempDatesToSave);
          handleSetFinalStartDate(tempDatesToSave[0]);
        } else {
          
          let tempStartWeek = getWeekNumber(convertToZoneDate(props.dateString[0])),
            tempEndWeek = getWeekNumber(convertToZoneDate(props.dateString[1]));

          setArrayOfDatesFormatted([
            "week " + tempStartWeek + " to week " + tempEndWeek,
          ]);
          setArrayOfDatesNumeric([
            formatDateNumeric(convertToZoneDate(props.dateString[0])) +
              ":" +
              formatDateNumeric(convertToZoneDate(props.dateString[1])),
          ]);
          handleSetFinalStartDate(
            formatDateNumeric(convertToZoneDate(props.dateString[0])) +
              ":" +
              formatDateNumeric(convertToZoneDate(props.dateString[1]))
          );

          // console.log(tempStartWeek, tempEndWeek);
        }
      } else if (granular === "Monthly") {
        let tempStartYear = convertToZoneDate(props.dateString[0]).getFullYear();
        let tempEndYear = convertToZoneDate(props.dateString[1]).getFullYear();
        let tempYears = [];
        for (var i = tempStartYear; i <= tempEndYear; i++) tempYears.push(i);

        let tempDateRanges = [];
        tempYears.map((item) => {
          var theFirst = convertToZoneDate(item, 0, 1);
          var theLast = convertToZoneDate(item, 11, 31);
          tempDateRanges.push({
            start: theFirst,
            end: theLast,
            year: item,
          });
        });

        tempDateRanges[0]["start"] = convertToZoneDate(props.dateString[0]);
        tempDateRanges[tempDateRanges.length - 1]["end"] = convertToZoneDate(
          props.dateString[1]
        );

        let finalRanges = [],
          tempDatesToShow = [],
          tempDatesToSave = [];
        tempDateRanges.map((item) => {
          var tempYear = item.year,
            tempShow =
              formatDateNumeric(new Date(item.start)) +
              ":" +
              formatDateNumeric(new Date(item.end));

          finalRanges.push({
            start: item.start,
            end: item.end,
            textToShow: tempYear,
            textToSave: tempShow,
          });

          tempDatesToShow.push(tempYear);
          tempDatesToSave.push(tempShow);
        });

        setArrayOfDatesFormatted(tempDatesToShow);
        setArrayOfDatesNumeric(tempDatesToSave);
        handleSetFinalStartDate(tempDatesToSave[0]);
      }
    }
    setTimeout(() => {
      setMountTheSlick(true);
    }, 10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateString[0], props.dateString[1], granular]);

  useEffect(
    () => () => {
      setMountTheSlick(false);

      if (props.dateString[0] !== "" || props.dateString[1] !== "") {
        setMountTheSlick(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [refStartDate.current, props.dateString]
  );

  return (
    <>
      <div style={{ marginTop: "-10px" }}>
        {mountTheSlick ? (
          <>
            <div className="section">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  {opertaions.map((item, index) => {
                    return (
                      <Button
                        sx={{ textTransform: "none" }}
                        key={index}
                        onClick={() => handleClickOperation(item.value)}
                        disabled={granular === "Raw Data"}
                      >
                        <Typography
                          variant={
                            granular === "Raw Data"
                              ? "textSubtitleGrey"
                              : operationType === item.value
                              ? "textSubtitlePurple"
                              : "textSubtitle"
                          }
                        >
                          {item.key}
                        </Typography>
                      </Button>
                    );
                  })}
                </ButtonGroup>

                <GenericSelectKeyValue
                  filter={props.filter}
                  handleSetFilter={handleSetFilter}
                  selected={granular} // All,48V,60V
                  maxWidth={"90px"}
                  date={[props.date[0], props.date[1]]}
                  showClearButton={false}
                  pageName={"DataQuality"}
                />
              </Box>
              <Box sx={{ width: "100%", mt: 1.5 }}>
                <SlickCarousel
                  Data={arrayOfDates}
                  actualDates={arrayOfDatesNumeric}
                  setTheDate={setTheDate}
                  startDate={refStartDate.current}
                  slidesToShow={granular === "Daily" ? 5 : 10}
                />
              </Box>
            </div>

            <>
              <KeyMetrics
                hierarchy={hierarchy}
                queryParams={queryParams}
                batteryID={props.batteryID}
                startDate={refStartDate.current}
                dateString={[props.dateString[0], props.dateString[1]]}
                granular={granular}
                operationType={operationType}
              />
            </>
          </>
        ) : (
          <Box sx={{ height: "400px" }}>
            <Loader />
          </Box>
        )}
      </div>
    </>
  );
};
export default ChargerVitalsMain;
