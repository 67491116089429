import React, { useState, useEffect } from "react";
import { CustomAutocomplete } from "../../Components/CustomAutocomplete";
import { CustomTextField } from "../../Components/CustomTextField";
import Events from "../../Analytics/Events";
import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useSelector, useDispatch } from "react-redux";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/material";

import { SetSessionExpired } from "../../Actions";
import {
  SetDashboardBatteryID,
  SetDashboardPartner,
  SetIsBatteryIsPartnerIsNull,
  SetDashboardAlertTypeCode,
  SetDashboardAlertTypeIndex,
  SetDashboardCategory,
} from "../../Actions/Dashboard";

import {
  SetBatteryHealthBatteryID,
  SetBatteryHealthIOT,
  SetBatteryHealthPartner,
  SetBatteryHealthDeviceID,
} from "../../Actions/BatteryHealth";

import { getBatteryPartnerList } from "../../Api/DashboardApi";
import { getDiagnosticsBatteryPartnerList } from "../../Api/DiagnosticsApi";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { getBatteryHealthBatteryPartner } from "../../Api/BatteryHealthApi";
import {
  SetDiagnosticsBatteryID,
  SetDiagnosticsDeviceID,
  SetDiagnosticsErrorTypeIndex,
  SetDiagnosticsPartner,
  SetIsBatteryIsPartnerIsNullDiagnostics,
} from "../../Actions/Diagnostics";
import { getDigitalTwinListSearch } from "../../Api/DigitalTwinListApi";
import { SetDigitalTwinsBatteryID } from "../../Actions/DigitalTwinsList";
import { PropaneSharp } from "@mui/icons-material";

export default function AutocompleteDashboard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [batteryList, setBatteryList] = useState([]);

  const batteryPartnerSelect = useSelector(
    (state) => state.DashboardBatteryPartnerSelect.value
  );
  const batteryID = useSelector((state) => state.DashboardBatteryID.value);
  const partner = useSelector((state) => state.DashboardPartner.value);

  const digitalTwinBatteryID = useSelector(
    (state) => state.DigitalTwinsBatteryID.value
  );

  const batteryPartnerSelectHealth = useSelector(
    (state) => state.BatteryHealthBatteryPartnerSelect.value
  );
  const batteryIDHealth = useSelector(
    (state) => state.BatteryHealthBatteryID.value
  );
  const deviceIDHealth = useSelector(
    (state) => state.BatteryHealthDeviceID.value
  );
  const partnerHealth = useSelector(
    (state) => state.BatteryHealthPartner.value
  );

  const batteryPartnerSelectDiagnostics = useSelector(
    (state) => state.DiagnosticsBatteryPartnerSelect.value
  );
  const batteryIDDiagnostics = useSelector(
    (state) => state.DiagnosticsBatteryID.value
  );
  const deviceIDDiagnostics = useSelector(
    (state) => state.DiagnosticsDeviceID.value
  );
  const partnerDiagnostics = useSelector(
    (state) => state.DiagnosticsPartner.value
  );

  const [typedValue, setTypedValue] = useState("");
  const [errorTextfield, setErrorTextField] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const limit = typedValue.length > 0;

    if (props.page === "BatteryHealth") {
      if (limit) {
        getBatteryHealthBatteryPartner(
          batteryPartnerSelectHealth,
          typedValue,
          10
        ).then((res) => {
          if (res.responseStatus.code === 200) {
            setBatteryList(res.response.data);
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));
            setBatteryList([]);
          }
        });
      } else {
        setOpen(false);
      }
    } else if (props.page === "Diagnostics") {
      if (limit) {
        getDiagnosticsBatteryPartnerList(
          batteryPartnerSelectDiagnostics,
          typedValue,
          10,
          props.tabValue
        ).then((res) => {
          if (res.responseStatus.code === 200) {
            setBatteryList(res.response.data);
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setBatteryList([]);
          }
        });
      } else {
        setOpen(false);
      }
    } else if (props.page === "DigitalTwins") {
      if (limit) {
        getDigitalTwinListSearch(typedValue, 5).then((res) => {
          if (res.responseStatus.code === 200) {
            setBatteryList(res.response.data);
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setBatteryList([]);
          }
        });
      } else {
        setOpen(false);
      }
    } else {
      if (limit) {
        getBatteryPartnerList(
          batteryPartnerSelect,
          typedValue,
          10,
          props.tabValue
        ).then((res) => {
          if (res.responseStatus.code === 200) {
            setBatteryList(res.response.data);
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setBatteryList([]);
          }
        });
      } else {
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedValue]);

  console.log(digitalTwinBatteryID);

  useEffect(() => {
    if (props.page === "BatteryHealth") {
      if (
        batteryIDHealth === "" &&
        partnerHealth === "" &&
        deviceIDHealth === ""
      ) {
        setTypedValue("");
        setErrorTextField(false);
      }
    } else if (props.page === "Diagnostics") {
      if (
        batteryIDDiagnostics === "" &&
        partnerDiagnostics === "" &&
        deviceIDDiagnostics === ""
      ) {
        setTypedValue("");
        setErrorTextField(false);
      }
    } else if (props.page === "DigitalTwins") {
      if (digitalTwinBatteryID === "") {
        setTypedValue("");
        setErrorTextField(false);
      }
    } else {
      if (batteryID === "" && partner === "") {
        setTypedValue("");
        setErrorTextField(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batteryID,
    partner,
    batteryPartnerSelect,

    batteryIDHealth,
    deviceIDHealth,
    partnerHealth,
    batteryPartnerSelectHealth,

    batteryIDDiagnostics,
    deviceIDDiagnostics,
    partnerDiagnostics,
    batteryPartnerSelectDiagnostics,
  ]);

  useEffect(
    () => () => {
      setBatteryList([]);
      setTypedValue("");
      setErrorTextField(false);
    },
    []
  );

  const handleOpen = (value) => {
    if (value.length > 2) {
      setOpen(true);
    }
  };

  const onTagsChange = (event, value) => {
    let temp;

    // handleOpen();
    if (value !== null && batteryList.includes(value)) {
      props.resetTheFilters();

      let queryParams = decryptTheParams();
      if (props.page === "BatteryHealth") {
        if (batteryPartnerSelectHealth === "Battery ID") {
          dispatch(SetBatteryHealthBatteryID(value));
          dispatch(SetBatteryHealthDeviceID(""));
          dispatch(SetBatteryHealthPartner(""));
          Events("searched Battery");
          temp = {
            ...queryParams,
            batteryID: value,
            deviceID: "",
            partner: "",
            type: "Battery",
            IOTRemoved: true,
          };
        } else if (batteryPartnerSelectHealth === "Device ID") {
          dispatch(SetBatteryHealthBatteryID(""));
          dispatch(SetBatteryHealthDeviceID(value));
          dispatch(SetBatteryHealthPartner(""));
          Events("searched Device");
          temp = {
            ...queryParams,
            batteryID: "",
            deviceID: value,
            partner: "",
            IOTRemoved: true,
          };
        } else {
          dispatch(SetBatteryHealthBatteryID(""));
          dispatch(SetBatteryHealthDeviceID(""));
          dispatch(SetBatteryHealthPartner(value));
          Events("searched Partner");
          temp = {
            ...queryParams,
            batteryID: "",
            deviceID: "",
            partner: value,
            IOTRemoved: true,
          };
        }
      } else if (props.page === "Diagnostics") {
        // dispatch(SetDashboardAlertTypeCode(props.alertCode.items[0]["alertCode"]));
        dispatch(SetDiagnosticsErrorTypeIndex(0));

        if (batteryPartnerSelectDiagnostics === "Battery ID") {
          dispatch(SetDiagnosticsBatteryID(value));
          dispatch(SetDiagnosticsPartner(""));
          dispatch(SetDiagnosticsDeviceID(""));
          dispatch(SetIsBatteryIsPartnerIsNullDiagnostics("Battery"));
          Events("searched Battery");
          localStorage.setItem("bpSelectDiag", "Battery");
          localStorage.setItem("bpValueDiag", value);
          // temp = {
          //   ...queryParams,
          //   batteryID: value,
          //   deviceID: "",
          //   partner: "",
          //   IsBatteryIsPartnerIsNull: "Battery",
          //   type: "Battery",
          //   // "Alert Type": "",
          // };
        } else if (batteryPartnerSelectDiagnostics === "Device ID") {
          dispatch(SetDiagnosticsBatteryID(""));
          dispatch(SetDiagnosticsPartner(""));
          dispatch(SetDiagnosticsDeviceID(value));
          dispatch(SetIsBatteryIsPartnerIsNullDiagnostics("Device"));
          Events("searched Device");
          localStorage.setItem("bpSelectDiag", "Device");
          localStorage.setItem("bpValueDiag", value);
          // temp = {
          //   ...queryParams,
          //   batteryID: "",
          //   deviceID: value,
          //   partner: "",
          //   IsBatteryIsPartnerIsNull: "Device",
          //   type: "Device",
          //   // "Alert Type": "",
          // };
        } else {
          dispatch(SetDiagnosticsPartner(value));
          dispatch(SetDiagnosticsBatteryID(""));
          dispatch(SetIsBatteryIsPartnerIsNullDiagnostics("Partner"));
          Events("searched Partner");
          localStorage.setItem("bpSelectDiag", "Partner");
          localStorage.setItem("bpValueDiag", value);
          // temp = {
          //   ...queryParams,
          //   batteryID: "",
          //   deviceID:"",
          //   partner: value,
          //   IsBatteryIsPartnerIsNull: "Partner",
          //   // "Alert Type": "",
          // };
        }
      } else if (props.page === "DigitalTwins") {
        dispatch(SetDigitalTwinsBatteryID(value));
        temp = {
          ...queryParams,
          batteryID: value,
        };
      } else {
        dispatch(SetDashboardCategory(""));
        dispatch(
          SetDashboardAlertTypeCode(props.alertCode.items[0]["alertCode"])
        );
        dispatch(SetDashboardAlertTypeIndex(0));

        if (batteryPartnerSelect === "Battery ID") {
          dispatch(SetDashboardBatteryID(value));
          dispatch(SetDashboardPartner(""));
          dispatch(SetIsBatteryIsPartnerIsNull("Battery"));
          Events("searched Battery");
          temp = {
            ...queryParams,
            batteryID: value,
            partner: "",
            IsBatteryIsPartnerIsNull: "Battery",
            type: "Battery",
            "Alert Type": "",
          };
        } else {
          dispatch(SetDashboardPartner(value));
          dispatch(SetDashboardBatteryID(""));
          dispatch(SetIsBatteryIsPartnerIsNull("Partner"));
          Events("searched Partner");
          temp = {
            ...queryParams,
            batteryID: "",
            partner: value,
            IsBatteryIsPartnerIsNull: "Partner",
            "Alert Type": "",
          };
        }
      }
      encryptTheParams(temp, navigate);
      setErrorTextField(false);
      setOpen(false);
    }
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      if (batteryList.includes(e.target.value)) {
        onTagsChange(undefined, e.target.value);
        setErrorTextField(false);
      } else {
        if (props.page === "BatteryHealth") {
          if (batteryPartnerSelectHealth === "Battery ID")
            props.openNotification(
              "bottomLeft",
              `Battery ${e.target.value} couldn't be found`
            );
          else if (batteryPartnerSelectHealth === "Device ID")
            props.openNotification(
              "bottomLeft",
              `Device ${e.target.value} couldn't be found`
            );
          else
            props.openNotification(
              "bottomLeft",
              `Occupant ${e.target.value} couldn't be found`
            );
        } else if (props.page === "Diagnostics") {
          if (batteryPartnerSelectDiagnostics === "Battery ID")
            props.openNotification(
              "bottomLeft",
              `Battery ${e.target.value} couldn't be found`
            );
          else if (batteryPartnerSelectDiagnostics === "Device ID")
            props.openNotification(
              "bottomLeft",
              `Device ${e.target.value} couldn't be found`
            );
          else
            props.openNotification(
              "bottomLeft",
              `Occupant ${e.target.value} couldn't be found`
            );
        } else if (props.page === "DigitalTwinsList") {
          props.openNotification(
            "bottomLeft",
            `BatteryID ${e.target.value} couldn't be found`
          );
        } else {
          if (batteryPartnerSelect === "Battery ID")
            props.openNotification(
              "bottomLeft",
              `Battery ${e.target.value} couldn't be found`
            );
          else
            props.openNotification(
              "bottomLeft",
              `Occupant ${e.target.value} couldn't be found`
            );
        }
        setErrorTextField(true);
      }
    }
  }

  return (
    <>
      <CustomAutocomplete
        filterSelectedOptions
        freeSolo
        ListboxProps={{
          sx: {
            fontSize: 13,
            fontWeight: 500,
            textAlign: "left",
            // pl: 2,
            // pr: 8.5,
          },
        }}
        disablePortal
        id="combo-box-demo"
        options={batteryList.filter((item, i, ar) => ar.indexOf(item) === i)}
        // sx={{ width: props.width }}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        clearOnBlur={true}
        value={
          props.page === "BatteryHealth"
            ? batteryPartnerSelectHealth === "Battery ID"
              ? batteryIDHealth
              : batteryPartnerSelectHealth === "Device ID"
              ? deviceIDHealth
              : partnerHealth
            : props.page === "Diagnostics"
            ? batteryPartnerSelectDiagnostics === "Battery ID"
              ? batteryIDDiagnostics
              : batteryPartnerSelectDiagnostics === "Device ID"
              ? deviceIDDiagnostics
              : partnerDiagnostics
            : props.page === "DigitalTwins"
            ? digitalTwinBatteryID
            : batteryPartnerSelect === "Battery ID"
            ? batteryID
            : partner
        }
        inputValue={typedValue}
        onChange={onTagsChange}
        onKeyDown={keyPress}
        onInputChange={(event, value) => {
          setTypedValue(value);
          setErrorTextField(false);
          handleOpen(value);
        }}
        getOptionLabel={(option) => option}
        forcePopupIcon={false}
        size="small"
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={errorTextfield}
            InputProps={{
              ...params.InputProps,
              sx: {
                "& input": {
                  textAlign: "center",
                  ml: 1,
                  color: errorTextfield ? "#c62828" : "black",
                },
              },
              endAdornment: (
                <InputAdornment position="end" sx={{ mr: 0.5 }}>
                  <Box>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setTypedValue("");
                        setErrorTextField(false);
                        if (
                          batteryID ||
                          partner ||
                          batteryIDHealth ||
                          deviceIDHealth ||
                          partnerHealth ||
                          batteryIDDiagnostics ||
                          deviceIDDiagnostics ||
                          partnerDiagnostics ||
                          digitalTwinBatteryID
                        ) {
                          let temp = "";

                          if (props.page === "BatteryHealth") {
                            dispatch(SetBatteryHealthBatteryID(""));
                            dispatch(SetBatteryHealthDeviceID(""));
                            dispatch(SetBatteryHealthPartner(""));
                            dispatch(SetBatteryHealthIOT("Wynncom"));

                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              batteryID: "",
                              deviceID: "",
                              partner: "",
                              IsBatteryIsPartnerIsNull: null,
                              type: "",
                              IOT: "Wynncom",
                            };

                            props.openNotification(
                              "bottomLeft",
                              `Setting IOT filter to Wynncom`
                            );
                          } else if (props.page === "Diagnostics") {
                            props.resetTheFilters();

                            dispatch(SetDiagnosticsBatteryID(""));
                            dispatch(SetDiagnosticsDeviceID(""));
                            dispatch(
                              SetIsBatteryIsPartnerIsNullDiagnostics(null)
                            );
                            dispatch(SetDiagnosticsPartner(""));

                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              batteryID: "",
                              deviceID: "",
                              partner: "",
                              type: "",
                              IsBatteryIsPartnerIsNull: null,
                            };
                            props.openNotification(
                              "bottomLeft",
                              `Cleared all filters if selected`
                            );
                          } else if (props.page === "DigitalTwins") {
                            // If page is Digital Twins
                            props.resetTheFilters();

                            dispatch(SetDigitalTwinsBatteryID(""));

                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              batteryID: "",
                            };
                            props.openNotification(
                              "bottomLeft",
                              `Cleared Battery Search Filter`
                            );
                          } else {
                            props.resetTheFilters();

                            dispatch(SetDashboardBatteryID(""));
                            dispatch(SetIsBatteryIsPartnerIsNull(null));
                            dispatch(SetDashboardPartner(""));

                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              batteryID: "",
                              partner: "",
                              type: "",
                              IsBatteryIsPartnerIsNull: null,
                            };
                            props.openNotification(
                              "bottomLeft",
                              `Cleared all filters if selected`
                            );
                          }

                          encryptTheParams(temp, navigate);
                        }
                      }}
                    >
                      <HighlightOffIcon sx={{ fontSize: "19px", mt: "-1px" }} />
                    </IconButton>
                  </Box>
                </InputAdornment>
              ),
            }}
            placeholder={
              props.page === "BatteryHealth"
                ? batteryPartnerSelectHealth === "Battery ID"
                  ? "Search single battery"
                  : batteryPartnerSelectHealth === "Device ID"
                  ? "Search single device"
                  : "Search single occupant"
                : props.page === "Diagnostics"
                ? batteryPartnerSelectDiagnostics === "Battery ID"
                  ? "Search single battery"
                  : batteryPartnerSelectDiagnostics === "Device ID"
                  ? "Search single device"
                  : "Search single occupant"
                : props.page === "DigitalTwins"
                ? "Search single device"
                : batteryPartnerSelect === "Battery ID"
                ? "Search single battery"
                : "Search single occupant"
            }
            sx={{
              fontSize: 17,
              fontWeight: 500,
              fontFamily: "Roboto",
              bgcolor: ThemeProperties.white,
              color: ThemeProperties.red_100,
              borderRadius: "30px",
              width: "212px",
              "& label": {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                "&.Mui-focused": {
                  width: "10%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                },
              },
            }}
          />
        )}
      />
    </>
  );
}
