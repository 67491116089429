import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import PoweredBy from "../../Assets/Images/Sidebar/PoweredBy.svg";
import PoweredByCircle from "../../Assets/Images/Sidebar/PoweredByCircle.svg";
import MyNavLink from "./Links/MyNavLink";

import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import { Backdrop } from "@mui/material";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: open ? theme.spacing(0, "30%") : theme.spacing(0, "19%"),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function MiniDrawer(props) {
  const theme = useTheme();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const handleDrawerClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const cookies = new Cookies();

  return (
    <>
      <Drawer
        variant="permanent"
        open={sidebarOpen}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "5px 0px 25px 0px rgba(17, 6, 38, 0.08)",
            border: "none",
          },
        }}
        ModalProps={{ onBackdropClick: () => setSidebarOpen(false) }}
        theme={theme}
      >
        <List style={{ paddingTop: "58px" }}>
          {props.appbarLinks.map((item, index) => (
            <>
              {cookies.get("pages") !== undefined ? (
                <>
                  <MyNavLink
                    to={item.to}
                    mykey={item.mykey}
                    textPrimary={item.textPrimary}
                    icon={
                      <img
                        src={item.icon}
                        alt="not found"
                        height={"20px"}
                        style={{ marginLeft: sidebarOpen ? 7 : -10 }}
                      />
                    }
                    icon_white={
                      <img
                        src={item.icon_white}
                        alt="not found"
                        height={"20px"}
                        style={{ marginLeft: sidebarOpen ? -3 : -10 }}
                      />
                    }
                    key={index}
                    open={sidebarOpen}
                  />
                </>
              ) : (
                removeAccessToken()
              )}
            </>
          ))}
          <IconButton
            onClick={handleDrawerClick}
            sx={{ mt: 2, ml: sidebarOpen ? "80%" : "20%" }}
          >
            {sidebarOpen ? (
              <ChevronLeftIcon fontSize="large" />
            ) : (
              <ChevronRightIcon fontSize="large" />
            )}
          </IconButton>
        </List>
        <DrawerHeader open={sidebarOpen}>
          {sidebarOpen ? (
            <img src={PoweredBy} alt="not found" />
          ) : (
            <img src={PoweredByCircle} alt="not found" />
          )}
        </DrawerHeader>
      </Drawer>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer - 1,
          color: "#fff",
        }}
        open={sidebarOpen}
        onClick={() => setSidebarOpen(false)}
      ></Backdrop>
    </>
  );
}

export default MiniDrawer;
