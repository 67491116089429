export const GetFilterKeyValue = (data) => {
  let MapLocationFilter = data.split(",");
  let mapFilterItems = [];

  MapLocationFilter.forEach((item) => {
    mapFilterItems.push({
      value: item,
      id: item,
    });
  });

  return mapFilterItems;
}