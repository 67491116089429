import { getDatePickerDate } from "../Helper/DatePicker/DateConverter";
import TwoWheeler from "../Assets/Icons/DigitalTwinList/TwoWheeler.svg";
import { batteryConfigurations } from "../Static/Data/DigitalTwinList/BatteryConfigurations";
const inititalColumns = {
  value: [],
};

const inititalHiddenColumns = {
  value: [],
};

const initialBatteryID = {
  value: "",
};

const initialTab = {
  value: 0,
};

const initialTotal = {
  value: 0,
};
const initialStartDate = {
  value: getDatePickerDate(
    new Date(new Date().setDate(new Date().getDate() - 45))
  ),
};
const initialEndDate = {
  value: getDatePickerDate(),
};

const initialDigitalTwinsListOfBatteries = {
  value: [],
};

const initialUserState = {
  arr: [],
};

const initialDigitalTwinsColumnSort = {
  arr: [
    {
      sortBy: "batteryID",
      orderBy: "asc",
    },
    {
      sortBy: "id",
      orderBy: "asc",
    },
  ],
};

const initialPageNumber = {
  value: 1,
};

const initialRowsPerPage = {
  value: 20,
};

const initialOpenSelectedBatteries = {
  value: false,
};

const initialIot = {
  value: [],
};

const initialApp = {
  value: [],
};

const initialBMS = {
  value: [],
};

const initialOrg = {
  value: [],
};

const initalColumnsSelect = {
  value: "Static",
};

const initialConfigurationType = {
  value: "2w",
  icon: TwoWheeler,
  name: "2 Wheeler",
};

const initialConfigFilters = {
  value: [],
};

const DigitalTwinsColumns = (state = inititalColumns, action) => {
  switch (action.type) {
    case "DigitalTwinsColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DigitalTwinsConfigurationType = (
  state = initialConfigurationType,
  action
) => {
  switch (action.type) {
    case "DigitalTwinsConfigurationType":
      const config = batteryConfigurations.find(
        (x) => x.value === action.value
      );
      return {
        value: action.value,
        icon: config?.icon,
        name: config?.configName,
      };
    default:
      return state;
  }
};

const DigitalTwinsColumnsSelect = (state = initalColumnsSelect, action) => {
  switch (action.type) {
    case "DigitalTwinsColumnsSelect":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DigitalTwinsHiddenColumns = (state = inititalHiddenColumns, action) => {
  switch (action.type) {
    case "DigitalTwinsHiddenColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DigitalTwinsBatteryID = (state = initialBatteryID, action) => {
  switch (action.type) {
    case "DigitalTwinsBatteryID":
      return {
        value: action.batteryID,
      };
    default:
      return state;
  }
};

const DigitalTwinsOrg = (state = initialOrg, action) => {
  switch (action.type) {
    case "DigitalTwinsOrg":
      return {
        value: action.org,
      };
    default:
      return state;
  }
};

const DigitalTwinsIOT = (state = initialIot, action) => {
  switch (action.type) {
    case "DigitalTwinsIOT":
      return {
        value: action.iot,
      };
    default:
      return state;
  }
};

const DigitalTwinsApp = (state = initialApp, action) => {
  switch (action.type) {
    case "DigitalTwinsApp":
      return {
        value: action.app,
      };
    default:
      return state;
  }
};

const DigitalTwinsBMS = (state = initialBMS, action) => {
  switch (action.type) {
    case "DigitalTwinsBMS":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};

const DigitalTwinsTabValue = (state = initialTab, action) => {
  switch (action.type) {
    case "DigitalTwinsTabValue":
      return {
        value: action.tab,
      };
    default:
      return state;
  }
};

const DigitalTwinsTotal = (state = initialTotal, action) => {
  switch (action.type) {
    case "DigitalTwinsTotal":
      return {
        value: action.total,
      };
    default:
      return state;
  }
};
const DigitalTwinsStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "DigitalTwinsStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const DigitalTwinsEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "DigitalTwinsEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};

const DigitalTwinsListOfBatteries = (
  state = initialDigitalTwinsListOfBatteries,
  action
) => {
  switch (action.type) {
    case "DigitalTwinsListOfBatteries":
      return {
        value: action.list,
      };
    default:
      return state;
  }
};

const DigitalTwinsStack = (state = initialUserState, action) => {
  switch (action.type) {
    case "DigitalTwinsStack":
      return {
        ...state,
        arr: action.batteries,
      };
    default:
      return state;
  }
};

const DigitalTwinsColumnSort = (
  state = initialDigitalTwinsColumnSort,
  action
) => {
  switch (action.type) {
    case "DigitalTwinsColumnSort":
      return {
        ...state,
        arr: action.sort,
      };
    default:
      return state;
  }
};

const DigitalTwinsPageNumber = (state = initialPageNumber, action) => {
  switch (action.type) {
    case "DigitalTwinsPageNumber":
      return {
        value: action.page,
      };
    default:
      return state;
  }
};

const DigitalTwinsRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "DigitalTwinsRowsPerPage":
      return {
        value: action.rows,
      };
    default:
      return state;
  }
};

const DigitalTwinsConfigFilters = (state = initialConfigFilters, action) => {
  switch (action.type) {
    case "DigitalTwinsConfigFilters":
      return {
        value: action.filters,
        packOEM: action.filters.find((x) => x.type === "packOEM")?.value ?? [],
        iotOEM: action.filters.find((x) => x.type === "iotOEM")?.value ?? [],
        bmsOEM: action.filters.find((x) => x.type === "bmsOEM")?.value ?? [],
        cellOEM: action.filters.find((x) => x.type === "cellOEM")?.value ?? [],
        cellModelNumber:
          action.filters.find((x) => x.type === "cellModelNumber")?.value ?? [],
        cellChemistry:
          action.filters.find((x) => x.type === "cellChemistry")?.value ?? [],
        formFactorCell:
          action.filters.find((x) => x.type === "formFactorCell")?.value ?? [],
        fleetName:
          action.filters.find((x) => x.type === "fleetName")?.value ?? [],
        configName:
          action.filters.find((x) => x.type === "configName")?.value ?? [],
        buildingBlockType:
          action.filters.find((x) => x.type === "buildingBlockType")?.value ??
          [],
        appOEM:
          action.filters.find((x) => x.type === "applicationOEM")?.value ?? [],
      };
    default:
      return state;
  }
};

export {
  DigitalTwinsColumns,
  DigitalTwinsHiddenColumns,
  DigitalTwinsBatteryID,
  DigitalTwinsIOT,
  DigitalTwinsBMS,
  DigitalTwinsApp,
  DigitalTwinsTabValue,
  DigitalTwinsTotal,
  DigitalTwinsStartDate,
  DigitalTwinsEndDate,
  DigitalTwinsListOfBatteries,
  DigitalTwinsStack,
  DigitalTwinsColumnSort,
  DigitalTwinsPageNumber,
  DigitalTwinsRowsPerPage,
  DigitalTwinsColumnsSelect,
  DigitalTwinsConfigurationType,
  DigitalTwinsConfigFilters,
  DigitalTwinsOrg,
};
