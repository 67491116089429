import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItemText,
  Grid,
  Avatar,
} from "@mui/material";
import { StyledListItemButton } from "../../Components/CustomListItemButton";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { AlertIcons } from "../../Static/Data/_Common/AlertIcons";
import Battery20 from "@mui/icons-material/Battery20";
import Events from "../../Analytics/Events";
import {
  SetDiagnosticsErrorTypeCode,
  SetDiagnosticsErrorTypeIndex,
  SetDiagnosticsTotal,
  SetDiagnosticsVersion,
  SetDiagnosticsPageNumber,
} from "../../Actions/Diagnostics";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Helper/Loaders/Loader";
import NoData from "../../Helper/Loaders/NoData";

import CODE from "../../Static/Constants/StatusCodes";

export default function ListWithSelectionDiagnostics(props) {
  const dispatch = useDispatch();

  const tabvalue = useSelector((state) => state.DiagnosticsTabValue.value);
  const errorTypeIndex = useSelector(
    (state) => state.DiagnosticsErrorTypeIndex.value
  );

  const handleListItemClick = (errorCode, version, errorCount, index) => {
    console.log("index is", index);
    dispatch(SetDiagnosticsErrorTypeIndex(index));
    dispatch(SetDiagnosticsErrorTypeCode(errorCode));
    dispatch(SetDiagnosticsTotal(errorCount));
    dispatch(SetDiagnosticsVersion(version));
    dispatch(SetDiagnosticsPageNumber(1));
    Events("clicked alert type");
  };

  const [list, setList] = useState({
    type: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  useEffect(() => {
    if (props.data && props.data.list.length > 0) {
      // props.data.responseStatus.code !== 1999/404

      console.log(props.data.list);

      setList({
        type: props.data.list,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        type: [],
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
  }, [props.data]);

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#FBFBFA",
        borderRadius: "8px",
        border: "1px solid #f0f0f0",
      }}
    >
      <Box
        sx={{
          height: "32px",
          lineHeight: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #ececec",
          textAlign: "center",
          p: 0.9,
        }}
      >
        <Typography variant="tableHeadBold">BMS Alerts</Typography>
      </Box>
      <Box
        sx={{
          height: 565,
          maxHeight: 565,
          overflowY: "scroll",
          overflowX: "hidden",
          marginRight: "-10px",
        }}
      >
        {list.responseStatus.code === CODE.SUCCESS ? (
          <List
            sx={{
              pt: 0,
            }}
          >
            {list.type.map((item, index) => (
              <div style={{ position: "relative" }} key={index}>
                <StyledListItemButton
                  selected={errorTypeIndex === index}
                  onClick={(event) =>
                    handleListItemClick(
                      item["errorCode"],
                      item["fwVersionFilterCode"],
                      item["errorCount"],
                      index
                    )
                  }
                  key={index}
                  sx={{ borderBottom: "1px solid #ececec" }}
                  myColor={
                    tabvalue === 0
                      ? ThemeProperties.red_100
                      : ThemeProperties.green_100
                  }
                  border={true}
                >
                  <Grid container spacing={1} sx={{ alignItems: "center" }}>
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                      <ListItemText sx={{ ml: 1 }}>
                        <Typography variant="tableRow">
                          {item["errorDescription"]}
                        </Typography>
                      </ListItemText>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        variant={"tableRow"}
                        sx={{
                          // ml: -1,
                          bgcolor: ThemeProperties.light_yellow,
                          p: 0.5,
                          borderRadius: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {item["errorCount"]}
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledListItemButton>
                <Box
                  sx={{
                    position: "absolute",
                    top: {
                      xs: 0,
                      sm: -13,
                      md: -10,
                      lg: -10,
                      xl: -10,
                    },
                    right: 40,
                  }}
                >
                  {errorTypeIndex === index && (
                    <ArrowRightIcon
                      sx={{
                        color:
                          tabvalue === 0
                            ? ThemeProperties.red_100
                            : ThemeProperties.green_100,
                        fontSize: "65px",
                        pl: 0,
                        position: "absolute",
                      }}
                    />
                  )}
                </Box>
              </div>
            ))}
          </List>
        ) : (
          <>
            {list.responseStatus.code === CODE.LOADING ? (
              <Loader />
            ) : (
              <NoData text="No alerts found" />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
