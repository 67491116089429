import React from "react";
import { NavLink as NavLinkBase } from "react-router-dom";
import { ListItem, ListItemIcon, Typography } from "@mui/material";
import Events from "../../../Analytics/Events";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { SetDashboardOpenAddSelectedBatteries } from "../../../Actions/Dashboard";

const NavLink = React.forwardRef((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={props.activeClassName}
    exact="true"
  />
));

const MyNavLink = (props) => {
  const dispatch = useDispatch();
  const universal = useSelector((state) => state.DashboardStack.arr);

  return (
    <ListItem
      component={NavLink}
      activeClassName={({ isActive }) =>
        isActive ? "activeClass" : "inactiveClass"
      }
      to={props.to}
      onClick={(event) => {
        let itemIDS = [];
        universal.map((item) => itemIDS.push(...item.itemIDs));

        if (itemIDS.length > 0) {
          event.preventDefault();
          dispatch(SetDashboardOpenAddSelectedBatteries(true));
        }
        Events("clicked " + props.textPrimary);
      }}
      key={props.mykey}
    >
      <Tooltip
        placement="right"
        title={!props.open ? props.textPrimary : ""}
        zIndex={2000}
      >
        <ListItem
          key={props.mykey}
          sx={{
            borderRadius: "8px",
            "&:hover": {
              backgroundColor:
                window.location.pathname === props.to
                  ? "white"
                  : "rgba(208, 210, 255, 1)",
            },
            marginLeft: props.open ? -2 : 0,
          }}
        >
          <ListItemIcon>{props?.icon}</ListItemIcon>
          {props.open && (
            <Typography
              sx={{
                fontSize: "1.6rem",
                fontFamily: "Roboto",
                color:
                  window.location.pathname === props.to ? "#1A1A1A" : "#666",
              }}
            >
              {props.textPrimary}
            </Typography>
          )}
        </ListItem>
      </Tooltip>
    </ListItem>
  );
};

export default MyNavLink;
