import { styled } from "@mui/material/styles";
import { Toolbar } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";

const CustomToolbar = styled(Toolbar)({
    backgroundColor: "rgba(238, 239, 255, 1)",
    zIndex: "9998",
    height:"55px",
    position: "fixed",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `2px solid ${ThemeProperties.lightGrey}`,
});

export { CustomToolbar };
