import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import CODE from "../../Static/Constants/StatusCodes";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";

import {
  SetDigitalTwinsColumns,
  SetDigitalTwinsConfigFilters,
  SetDigitalTwinsConfigurationType,
  SetDigitalTwinsEndDate,
  SetDigitalTwinsHiddenColumns,
  SetDigitalTwinsStartDate,
  SetDigitalTwinsTabValue,
  SetDigitalTwinsIOT,
  SetDigitalTwinsBMS,
  SetDigitalTwinsApp,
  SetDigitalTwinsOrg,
} from "../../Actions/DigitalTwinsList";

import Loader from "../../Helper/Loaders/Loader";
import {
  getDatePickerDate,
  getMetricTimeStamp,
} from "../../Helper/DatePicker/DateConverter";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import TextWithCopyIcon from "../../Helper/Operations/TextWithCopyIcon";
import TextHyperLinkWithCopyIcon from "../../Helper/Operations/TextHyperLinkWithCopyIcon";

import { ThemeProperties } from "../../Theme/ThemeProperties";

import DigitalTwinsListMain from "./DigitalTwinListMain";
import { getConfigFilterValues } from "../../Api/DigitalTwinListApi";
import info from "../../Assets/Images/Common/info.png";

function getActualArrayValues(data) {
  let arr = data?.length === 0 ? [] : data?.split(",");
  return arr;
}

export default function DigitalTwinsList(props) {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();

  const permissions = props?.permissions;

  const queryParams = decryptTheParams();

  const columnsSelect = useSelector(
    (state) => state.DigitalTwinsColumnsSelect?.value
  );

  const [pagesContent, setPagesContent] = useState({
    batteryID: "",
    partner: "",
    tabValue: 0,
    pageHeader: "",
    mounted: false,
  });

  const [configFilters, setConfigFilters] = useState({
    applicationOEM: [],
    iotOEM: [],
    bmsOEM: [],
    organization: [],
  });

  useEffect(() => {
    if (configFilters) {
      dispatch(SetDigitalTwinsOrg(configFilters?.fleetName));
      dispatch(SetDigitalTwinsIOT(configFilters?.iotOEM));
      dispatch(SetDigitalTwinsBMS(configFilters?.bmsOEM));
      dispatch(SetDigitalTwinsApp(configFilters?.applicationOEM));
      dispatch(
        SetDigitalTwinsStartDate(
          getDatePickerDate(
            new Date(new Date().setDate(new Date().getDate() - 45))
          )
        )
      );
      dispatch(SetDigitalTwinsEndDate(getDatePickerDate()));
      SetTheFilters(
        configFilters.organization?.map((item) => {
          return {
            id: item,
            value: item,
          };
        }),
        configFilters.applicationOEM?.map((item) => {
          return {
            id: item,
            value: item,
          };
        }),
        configFilters.iotOEM?.map((item) => {
          return {
            id: item,
            value: item,
          };
        }),
        configFilters.bmsOEM?.map((item) => {
          return {
            id: item,
            value: item,
          };
        })
        // true
      );
    }
  }, [configFilters]);

  useEffect(() => {
    GetConfigFilters();
  }, []);

  async function GetConfigFilters() {
    setPagesContent({ ...pagesContent, mounted: false });
    await getConfigFilterValues().then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS) {
        setConfigFilters({
          applicationOEM: res.response.filterData.find(
            (i) => i.type === "applicationOEM"
          )?.value,
          iotOEM: res.response.filterData.find((i) => i.type === "iotOEM")
            ?.value,
          bmsOEM: res.response.filterData.find((i) => i.type === "bmsOEM")
            ?.value,
          organization: res.response.filterData.find(
            (i) => i.type === "fleetName"
          )?.value,
        });
        dispatch(SetDigitalTwinsConfigFilters(res.response.filterData));
        // setPagesContent({ ...pagesContent, mounted: true });
      }
    });
  }

  function SetTheFilters(orgFilter, applicationOEM, iotOEM, bmsOEM) {
    setTimeout(() => {
      const filters = [
        {
          itemName: "Organization",
          items: orgFilter,
          tabValue: 0,
        },
        {
          itemName: "Application",
          items: applicationOEM,
          tabValue: 1,
        },
        {
          itemName: "IOT",
          items: iotOEM,
          tabValue: 1,
        },
        {
          itemName: "BMS",
          items: bmsOEM,
          tabValue: 1,
        },
      ];
      let newFilterWithData = [];
      filters.map(
        (
          item //res.response.response.filter.filtersWithData
        ) => newFilterWithData?.push(item["itemName"])
      );
      const filteredArray = newFilterWithData;

      for (var value of Object.keys(queryParams)) {
        if (filteredArray.includes(value)) {
          if (value === "Organization") {
            dispatch(
              SetDigitalTwinsOrg(getActualArrayValues(queryParams[value]))
            );
          }
          if (value === "Application") {
            dispatch(
              SetDigitalTwinsApp(getActualArrayValues(queryParams[value]))
            );
          }
          if (value === "IOT") {
            dispatch(
              SetDigitalTwinsIOT(getActualArrayValues(queryParams[value]))
            );
          }
          if (value === "BMS") {
            dispatch(
              SetDigitalTwinsBMS(getActualArrayValues(queryParams[value]))
            );
          }
        } else {
          if (value === "startDate") {
            dispatch(SetDigitalTwinsStartDate(queryParams[value]));
          } else if (value === "endDate") {
            dispatch(SetDigitalTwinsEndDate(queryParams[value]));
          } else if (value === "tabValue") {
            dispatch(SetDigitalTwinsTabValue(+queryParams[value]));
          }
        }
      }

      setPagesContent({
        ...pagesContent,
        pageHeader: "Digital Twin",
        filtersWithData: filters, //res.response.response.filter.filtersWithData
        filteredArray: filteredArray,
        mounted: true,
      });
    }, 2);
  }

  function onBatteryIDClick(record) {
    let temp;
    temp = {
      ...queryParams,
      batteryID: record.batteryID,
      batterySearchedGlobal: "false",
      tabValue: 0,
      tabName: "Battery Vitals",
      prevLink: location.pathname + location.search,
      navigateFromTab: true,
    };
    encryptTheParams(temp, navigate, true);
  }

  function onConfigIDClick(record) {
    if (props?.permissions?.includes("view-config")) {
      let temp = {
        ...queryParams,
        configID: record?.id,
        isEdit: true,
        isView: true,
        configName: record?.configName,
      };
      dispatch(SetDigitalTwinsConfigurationType(record?.application));
      encryptTheParams(temp, navigate, false, "/DigitalTwin/add-config");
    }
  }

  function updateColumnWidth() {}

  const columns = [
    {
      name: "Digital Twins Columns",
      columns: [
        {
          title: <Typography variant="tableHeadBold">Device ID</Typography>,
          name: "Device ID",
          fixed: "left",
          sorter: false,
          uniqueKey: "batteryIDActive",
          filterSearch: false,
          // width: "17%",
          className: "undragableColumn",
          width: 160,
          render: (data) => (
            <div className="renderID">
              {TextHyperLinkWithCopyIcon(
                data?.batteryID,
                "Device ID",
                onBatteryIDClick,
                data
              )}
            </div>
          ),
          show: ["Diagnostic", "Static", "Real Time"],
        },
        {
          title: (
            <Typography variant="tableHeadBold">Configuration ID</Typography>
          ),
          name: "Configuration ID",
          dataIndex: "configName",
          key: "configName",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "16%",
          width: 150,
          uniqueKey: "configNameActive",
          render: (configName) => (
            <div className="renderID">
              {TextWithCopyIcon(configName, "Config Name")}
            </div>
          ),
          show: ["Static", "Real Time"],
          // onHeaderCell: (column) => {
          //   debugger;
          //   let colw = "16%";
          //   return {
          //     onMouseDown: (e) => {
          //       this.mouseDownX = e.clientX;
          //       this.beginDrag = true;
          //     },
          //     onMouseUp: () => {
          //       this.beginDrag = false;
          //     },
          //     onMouseMove: (e) => {
          //       if (this.beginDrag === true) {
          //         this.updateColumnWidth(
          //           colw + Math.round((e.clientX - this.mouseDownX) * 0.05)
          //         );
          //       }
          //     },
          //   };
          // },
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(titleProps, "packCount", "No. of Packs"),
          name: "No. of Packs",
          dataIndex: "packCount",
          key: "packCount",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          // width: "12%",
          width: 120,
          uniqueKey: "packCountActive",
          render: (packCount) => (
            <Typography variant="tableRow">{packCount?.toFixed(0)}</Typography>
          ),
          show: ["Static", "Real Time"],
        },
        {
          title: (
            <Typography variant="tableHeadBold">Series / Parallel</Typography>
          ),
          name: "Series/Parallel",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "15%",
          width: 150,
          uniqueKey: "seriesParallelActive",
          render: (data) => (
            <Typography variant="tableRow">
              {`${data?.seriesPacks}S / ${data?.parallelPacks}P`}
            </Typography>
          ),
          show: ["Static", "Real Time"],
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(
              titleProps,
              "ratedCapacitySystem",
              "Rated Capacity (Ah)"
            ),
          name: "Rated Capacity (Ah)",
          dataIndex: "ratedCapacitySystem",
          key: "ratedCapacitySystem",
          align: "center",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          // width: "13%",
          width: 170,
          uniqueKey: "ratedCapacitySystemActive",
          render: (ratedCapacitySystem) => (
            <Typography variant="tableRow">{ratedCapacitySystem}</Typography>
          ),
          show: ["Static", "Real Time"],
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(
              titleProps,
              "ratedVoltageSystem",
              "Rated Voltage (V)"
            ),
          name: "Rated Voltage (V)",
          dataIndex: "ratedVoltageSystem",
          key: "ratedVoltageSystem",
          align: "center",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          // width: "12%",
          width: 150,
          uniqueKey: "ratedVoltageSystemActive",
          render: (ratedVoltageSystem) => (
            <Typography variant="tableRow">{ratedVoltageSystem}</Typography>
          ),
          show: ["Static", "Real Time"],
        },
        {
          title: <Typography variant="tableHeadBold">Organization</Typography>,
          name: "Organization",
          dataIndex: "fleetName",
          key: "fleetName",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "10%",
          width: 150,
          uniqueKey: "fleetNameActive",
          render: (fleetName) => (
            <Typography variant="tableRow">{fleetName}</Typography>
          ),
          show: ["Static", "Real Time"],
        },
        {
          title: <Typography variant="tableHeadBold">Added By</Typography>,
          name: "Added By",
          dataIndex: "addedBy",
          key: "addedBy",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "10%",
          width: 150,
          uniqueKey: "addedByActive",
          render: (addedBy) => (
            <Typography variant="tableRow">{addedBy}</Typography>
          ),
          show: ["Static", "Real Time"],
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(titleProps, "addedOn", "Added On"),
          name: "Added On",
          dataIndex: "addedOn",
          key: "addedOn",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          filterMode: "tree",
          filterSearch: false,
          uniqueKey: "addedOnActive",
          // width: "15%",
          width: 150,
          render: (addedOn) => (
            <Typography variant="tableRow">
              {getMetricTimeStamp(addedOn)}
            </Typography>
          ),
          show: ["Static", "Real Time"],
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(titleProps, "lastPinged", "Last Pinged"),
          name: "Last Pinged",
          dataIndex: "lastGPSPing",
          key: "lastGPSPing",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          filterMode: "tree",
          filterSearch: false,
          uniqueKey: "lastGPSPingActive",
          // width: "18%",
          width: 150,
          render: (lastGPSPing) => (
            <Typography variant="tableRow">
              {getMetricTimeStamp(lastGPSPing)}
            </Typography>
          ),
          show: ["Diagnostic"],
        },
        {
          title: <Typography variant="tableHeadBold">Location</Typography>,
          name: "Location",
          dataIndex: "location",
          key: "location",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "12%",
          width: 80,
          uniqueKey: "locationActive",
          render: (location) => (
            <Typography variant="tableRow">{location}</Typography>
          ),
          show: ["Diagnostic"],
        },
        {
          title: (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="tableHeadBold">Ping Status</Typography>
              <Tooltip
                placement="right"
                title={
                  <Box display="flex" flexDirection="column">
                    <h2>Active: Online since last 10 min</h2>
                    <h2>InActive: Offline since last 10 min</h2>
                  </Box>
                }
                zIndex={2000}
              >
                <IconButton>
                  <img src={info} height={15} width={15} />
                </IconButton>
              </Tooltip>
            </Box>
          ),
          name: "Ping Status",
          dataIndex: "pingStatus",
          key: "pingStatus",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "12%",
          width: 120,
          uniqueKey: "pingStatusActive",
          render: (pingStatus) => (
            <Typography variant="tableRow">{pingStatus}</Typography>
          ),
          show: ["Diagnostic"],
        },
        {
          title: (
            <Typography variant="tableHeadBold">Past Current (A)</Typography>
          ),
          name: "Past Current (A)",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "17%",
          width: 140,
          uniqueKey: "pastCurrentActive",
          render: (data) => (
            <Typography variant="tableRow">
              {data?.minCurrentPack !== null && data?.maxCurrentPack !== null
                ? `${data?.minCurrentPack} min / ${data?.maxCurrentPack} max`
                : ""}
            </Typography>
          ),
          show: ["Diagnostic"],
        },
        {
          title: (
            <Typography variant="tableHeadBold">Past Voltage (V)</Typography>
          ),
          name: "Past Voltage (V)",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "17%",
          width: 140,
          uniqueKey: "pastVoltageActive",
          render: (data) => (
            <Typography variant="tableRow">
              {data?.minVoltageCell !== null && data?.maxVoltageCell !== null
                ? `${data?.minVoltageCell} min / ${data?.maxVoltageCell} max`
                : ""}
            </Typography>
          ),
          show: ["Diagnostic"],
        },
        {
          title: (
            <Typography variant="tableHeadBold">
              Past Temperature (°C)
            </Typography>
          ),
          name: "Past Temperature (°C)",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "17%",
          width: 140,
          uniqueKey: "pastTemperatureActive",
          render: (data) => (
            <Typography variant="tableRow">
              {data?.minTemperaturePack !== null &&
              data?.maxTemperaturePack !== null
                ? `${data?.minTemperaturePack} min / ${data?.maxTemperaturePack} max`
                : ""}
            </Typography>
          ),
          show: ["Diagnostic"],
        },
        {
          title: <Typography variant="tableHeadBold"></Typography>,
          name: "edit",
          sorter: false,
          uniqueKey: "editActive",
          filterSearch: false,
          // width: "4%",
          width: 45,
          className: "undragableColumn",
          render: (data) => (
            <div className="renderID">
              {props?.permissions?.includes("edit-twin") && (
                <IconButton
                  onClick={() => {
                    let temp = {
                      ...queryParams,
                      id: data?.id,
                      isEdit: true,
                      batteryID: data?.batteryID,
                    };
                    encryptTheParams(
                      temp,
                      navigate,
                      false,
                      "/DigitalTwin/add-battery"
                    );
                    cookies.set("batteryID", data?.batteryID);
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
              )}
            </div>
          ),
          show: ["Diagnostic", "Static", "Real Time"],
        },
      ],
    },

    {
      name: "Battery Configurations Columns",
      columns: [
        {
          title: (
            <Typography variant="tableHeadBold">Configuration ID</Typography>
          ),
          name: "Configuration ID",
          fixed: "left",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "17%",
          width: 150,
          uniqueKey: "configNameActive",
          className: "undragableColumn",
          render: (record) => (
            <div className="renderID">
              {TextHyperLinkWithCopyIcon(
                record?.configName,
                "Config Name",
                onConfigIDClick,
                record
              )}
            </div>
          ),
        },
        {
          title: (
            <Typography variant="tableHeadBold">Series / Parallel</Typography>
          ),
          name: "Series/Parallel",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "7%",
          width: 130,
          uniqueKey: "seriesParallelActive",
          render: (data) => (
            <Typography variant="tableRow">{`${data?.seriesPacks}S / ${data?.parallelPacks}P`}</Typography>
          ),
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(titleProps, "packCount", "No. of Packs"),
          name: "No. of Packs",
          dataIndex: "packCount",
          key: "packCount",
          // fixed: "left",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          // width: "10%",
          width: 120,
          uniqueKey: "packCountActive",
          render: (packCount) => (
            <Typography variant="tableRow">{packCount?.toFixed(0)}</Typography>
          ),
        },
        {
          title: (
            <Typography variant="tableHeadBold">
              Application Manufacturer
            </Typography>
          ),
          name: "Application Manufacturer",
          dataIndex: "applicationOEM",
          key: "applicationOEM",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "12%",
          width: 190,
          uniqueKey: "configIdActive",
          render: (applicationOEM) => (
            <Typography variant="tableRow">{applicationOEM}</Typography>
          ),
        },
        {
          title: (
            <Typography variant="tableHeadBold">IOT Manufacturer</Typography>
          ),
          name: "IOT Manufacturer",
          dataIndex: "iotOEM",
          key: "iotOEM",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "12%",
          width: 140,
          uniqueKey: "configIdActive",
          render: (iotOEM) => (
            <Typography variant="tableRow">{iotOEM}</Typography>
          ),
        },
        {
          title: (
            <Typography variant="tableHeadBold">BMS Manufacturer</Typography>
          ),
          name: "BMS Manufacturer",
          dataIndex: "bmsOEM",
          key: "bmsOEM",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "12%",
          width: 150,
          uniqueKey: "configIdActive",
          render: (bmsOEM) => (
            <Typography variant="tableRow">{bmsOEM}</Typography>
          ),
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(
              titleProps,
              "ratedCapacitySystem",
              "Rated Capacity (Ah)"
            ),
          name: "Rated Capacity (Ah)",
          dataIndex: "ratedCapacitySystem",
          key: "ratedCapacitySystem",
          align: "center",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          // width: "13%",
          width: 170,
          uniqueKey: "ratedCapacitySystemActive",
          render: (ratedCapacitySystem) => (
            <Typography variant="tableRow">{ratedCapacitySystem}</Typography>
          ),
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(
              titleProps,
              "ratedVoltageSystem",
              "Rated Voltage (V)"
            ),
          name: "Rated Voltage (V)",
          dataIndex: "ratedVoltageSystem",
          key: "ratedVoltageSystem",
          align: "center",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          // width: "12%",
          width: 150,
          uniqueKey: "ratedVoltageSystemActive",
          render: (ratedVoltageSystem) => (
            <Typography variant="tableRow">{ratedVoltageSystem}</Typography>
          ),
        },
        {
          title: <Typography variant="tableHeadBold">Added By</Typography>,
          name: "Added By",
          dataIndex: "addedBy",
          key: "addedBy",
          sorter: false,
          showSorterTooltip: false,
          filters: false,
          // width: "12%",
          width: 150,
          uniqueKey: "addedByActive",
          render: (addedBy) => (
            <Typography variant="tableRow">{addedBy}</Typography>
          ),
        },
        {
          title: (titleProps) =>
            getColumnTitleSort(titleProps, "addedOn", "Added On"),
          name: "Added On",
          dataIndex: "addedOn",
          key: "addedOn",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: "",
          showSorterTooltip: false,
          filterMode: "tree",
          filterSearch: false,
          uniqueKey: "addedOnActive",
          // width: "14%",
          width: 150,
          minWidth: 140,
          render: (addedOn) => (
            <Typography variant="tableRow">
              {getMetricTimeStamp(addedOn)}
            </Typography>
          ),
        },
        {
          title: <Typography variant="tableHeadBold"></Typography>,
          name: "edit",
          sorter: false,
          uniqueKey: "editActive",
          filterSearch: false,
          // width: "4%",
          width: 45,
          render: (data) => (
            <>
              {props?.permissions?.includes("edit-config") && (
                <div className="renderID">
                  <IconButton
                    onClick={() => {
                      let temp = {
                        ...queryParams,
                        configID: data?.id,
                        isEdit: true,
                        configName: data?.configName,
                      };
                      encryptTheParams(
                        temp,
                        navigate,
                        false,
                        "/DigitalTwin/add-config"
                      );
                      cookies.set("configID", data?.configID);
                      dispatch(
                        SetDigitalTwinsConfigurationType(data?.application)
                      );
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </div>
              )}
            </>
          ),
        },
      ],
    },
  ];

  dispatch(SetDigitalTwinsColumns(columns));
  dispatch(SetDigitalTwinsHiddenColumns([]));

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <DigitalTwinsListMain
            pagesContent={pagesContent}
            permissions={permissions}
          />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}
