import React from "react";
import { Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { Typography, IconButton } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Events from "../../Analytics/Events";

function TextWithCopyIcon(text, type) {
  return (
    <>
      <Typography variant="tableRow">{text}</Typography>
      <Tooltip title={"Click to copy"}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            copy(text);
            Events(`Copied ${type}`);
          }}
        >
          <ContentCopyIcon
            sx={{
              fontSize: "13px",
              cursor: "pointer",
              "&:hover": {
                color: ThemeProperties.purple,
              },
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default TextWithCopyIcon;
