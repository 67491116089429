import React, { useState, useEffect, useRef } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { PaperHighlight } from "../../../Components/CustomPaper";
import Header from "../../../Features/Header/Header";
import ApexScatterPlusLine from "../../../Charts/Mixed/ApexScatterPlusLine";
import { ThemeProperties } from "../../../Theme/ThemeProperties";

import {
  getBatteryHealth1,
  getBatteryHealthDistribution,
} from "../../../Api/BatteryHealthApi";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { SetSessionExpired } from "../../../Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Helper/Loaders/Loader";
import Events from "../../../Analytics/Events";

import ApexBasicDonut from "../../../Charts/Donut/ApexBasicDonut";
import {
  SetBatteryHealthCycleAge,
  SetBatteryHealthSOH,
} from "../../../Actions/BatteryHealth";
import ScrollToTheElement from "../../../Helper/Operations/ScrollToTheElement";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import FleetBatteryHealthTabular from "../FleetBatteryHealthTabular";


function getMinX(eqCycle) {
  return eqCycle === "All"
    ? 0
    : eqCycle === "0 to 100"
    ? 0
    : eqCycle === "100 to 500"
    ? 100
    : eqCycle === "500 to 1200"
    ? 500
    : eqCycle === "1200 and above"
    ? 1200
    : 0;
}
// function getMaxX(eqCycle) {
//   return eqCycle === "All"
//     ? 9999
//     : eqCycle === "0 to 100"
//     ? 100
//     : eqCycle === "100 to 500"
//     ? 500
//     : eqCycle === "500 to 1200"
//     ? 1200
//     : eqCycle === "1200 and above"
//     ? 9999
//     : 0;
// }
function getMinY(sohLevel) {
  return sohLevel === ""
    ? 0
    : sohLevel === "SOH >98%"
    ? 99
    : sohLevel === "SOH 95% to 98%"
    ? 95
    : sohLevel === "SOH 90% to 95%"
    ? 90
    : sohLevel === "SOH 85% to 90%"
    ? 85
    : sohLevel === "SOH 80% to 85%"
    ? 80
    : sohLevel === "SOH <80%"
    ? 0
    : 0;
}
function getMaxY(sohLevel) {
  return sohLevel === ""
    ? 120
    : sohLevel === "SOH >98%"
    ? 120
    : sohLevel === "SOH 95% to 98%"
    ? 98
    : sohLevel === "SOH 90% to 95%"
    ? 95
    : sohLevel === "SOH 85% to 90%"
    ? 90
    : sohLevel === "SOH 80% to 85%"
    ? 85
    : sohLevel === "SOH <80%"
    ? 80
    : 120;
}

function getSOHLevels(sohLevels) {
  let levels = [];
  sohLevels.forEach((item) => {
    let level = "";
    if (item.includes("-")) {
      let temp = item.split("-");
      level = "SOH " + temp[0] + "% to " + temp[1] + "%";
      levels.push(level);
    } else if (item.includes(">") || item.includes("<")) {
      level = "SOH " + item + "%";

      levels.push(level);
    }
  });
  return levels;
}

function getMarkerColor(soh)
{
  if(soh !== undefined)
  if(soh>=98)
  {
    return "#00B159"
  }
  else if(soh<98 && soh>=95)
  {
    return "#7AC36A"
  }
  else if(soh<95 && soh>=90)
  {
    return "#B9E769"
  }
  else if(soh<90 && soh>=85)
  {
    return "#F2A54B"
  }
  else if(soh<85 && soh>=80)
  {
    return "#F9724C"
  }
  else
  {
    return "#D22B2B"
  }
}


function LandingOverview(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sohValue, setSohValue] = useState([]);
  const previousValue = useRef(null);

  const cycleAge = useSelector((state) => state.BatteryHealthCycleAge.value);
  const sohLevel = useSelector((state) => state.BatteryHealthSOH.value);

  const [voltData, setVoltData] = useState({
    scatter: [],
    batteryId: [],
    data: [],
    csvData:[],
    xmax: 0,
    xmin: 0,
    ymax: 0,
    ymin: 0,
    responseStatus: { code: -1, msg: "" },
  });
  const [alertsOverview, setAlertsOverview] = useState({
    series: [],
    labels: [],
    totalLabel: "Total Batteries",
    responseStatus: { code: -1, msg: "" },
  });

  const handleToggleCycleAge = (event) => {
    Events("Toggled Show Eq. Cycle Fleet battery Health");
    dispatch(SetBatteryHealthCycleAge(event.target.checked));
  };

  function findTotalBatteries()
{
  let label = 0;
  if(alertsOverview.series.length>0)
  if(sohLevel === "")
  {
    alertsOverview.series.forEach((item)=>{
      label = label + item
    })
  }
  else
  {
    label = alertsOverview.series[alertsOverview.labels.indexOf(sohLevel)]
  }

  return label
}



  const handleSohValue = (value) => {
    const queryParams = decryptTheParams();
    let temp;
    if (previousValue.current === value) {
      dispatch(SetBatteryHealthSOH(""));
      temp = {
        ...queryParams,
        SOH: "",
      };
    } else {
      dispatch(SetBatteryHealthSOH(value));
      temp = {
        ...queryParams,
        SOH: value,
      };
    }
    encryptTheParams(temp, navigate);
    setSohValue(value);
  };

  // useEffect(()=>{
  //   setVoltData({
  //     scatter: [],
  //     batteryId: [],
  //     data: [],
  //     xmax: 0,
  //     xmin: 0,
  //     ymax: 0,
  //     ymin: 0,
  //     responseStatus: { code: -1, msg: "" },
  //   });
  //   setAlertsOverview({
  //     series: [],
  //     labels: [],
  //     totalLabel: "Total Batteries",
  //     responseStatus: { code: -1, msg: "" },
  //   });
  //   dispatch(SetBatteryHealthSOH(""));
  // },[props.iotVendor, props.packVendor])
  
  useEffect(() => {
    previousValue.current = sohValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sohValue]);

  useEffect(() => {
    ScrollToTheElement();
    // props.showResetButton();
    setVoltData({
      scatter: [],
      batteryId: [],
      data: [],
      csvData:[],
      xmax: 0,
      xmin: 0,
      ymax: 0,
      ymin: 0,
      responseStatus: { code: -1, msg: "" },
    });

    getBatteryHealth1(
      props.volt,
      props.sohLevel,
      props.business,
      props.partner,
      props.eqCycle,
      props.cell,
      props.iotVendor,
      props.packVendor
    ).then((res) => {
      let newData = [],csvData=[],
        batteries = [];
      let Xmax = 0;
      let Ymax = getMaxY(props.sohLevel),
        Ymin = getMinY(props.sohLevel),
        // XmaxSohFilter = getMaxX(props.eqCycle),
        XminSohFilter = getMinX(props.eqCycle);

      if (res.responseStatus.code === 200) {
        res.response.batteryHealth.forEach((item) => {
          csvData.push({
            "Battery ID":item.assetID,
            "Device ID":item.batteryID,
            "Age (months)" : item.age !== null ? (item.age / 30).toFixed(2) : "",
            "Eq. cycle":(item.eqCycleEst).toFixed(2),
            "SOH (%)":(item.sohEst).toFixed(2)
          })
        })
        if (cycleAge) {
          res.response.batteryHealth.forEach((item) => {
            let newAge = item.age !== null ? item.age / 30 : 0;
            if (
              item.eqCycleEst !== null &&
              item.sohEst !== null &&
              item.age !== null
            ) {
              newData.push({
                x: item.eqCycleEst,
                y: item.sohEst,
                batteryID: item.assetID,
                deviceID: item.batteryID,
                secondaryX: newAge,
                // sohBMS: item.sohBMS,
                // eqCycleBMS: item.eqCycleBMS,
                fillColor:getMarkerColor(item.sohEst)
              });
              batteries.push(item.assetID);
            }
            if (Math.ceil(item.eqCycleEst) > Xmax)
              Xmax = Math.ceil(item.eqCycleEst);
          });
        } else {
          res.response.batteryHealth.forEach((item) => {
            let newAge = item.age !== null ? item.age / 30 : 0;
            if (item.age !== null && item.sohEst !== null) {
              newData.push({
                x: newAge,
                y: item.sohEst,
                batteryID: item.assetID,
                deviceID: item.batteryID,
                secondaryX: item.eqCycleEst,
                // sohBMS: item.sohBMS,
                // eqCycleBMS: item.eqCycleBMS,
                fillColor:getMarkerColor(item.sohEst)
              });
              batteries.push(item.assetID);
            }
            if (Math.ceil(newAge) > Xmax) Xmax = Math.ceil(newAge);
          });
        }
        setVoltData({
          scatter: newData,
          batteryId: batteries,
          data: res.response.batteryHealth,
          csvData: csvData,
          xmax: Xmax,
          xmin: cycleAge ? XminSohFilter : 0,
          ymin: Ymin,
          ymax: Ymax,
          responseStatus: {
            code: 200,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        let newVoltData = {
          scatter: [],
          batteryId: [],
          data: [],
          xmax: 0,
          xmin: 0,
          ymax: 0,
          ymin: 0,
          responseStatus: { code: 400, msg: "" },
        };
        setVoltData(newVoltData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sohLevel, props.iotVendor, props.packVendor, props.partner, cycleAge]);

  useEffect(() => {
    setAlertsOverview({
      series: [],
      labels: [],
      totalLabel: "Total Batteries",
      responseStatus: { code: -1, msg: "" },
    });
    getBatteryHealthDistribution(
      props.volt,
      props.sohLevel,
      props.business,
      props.partner,
      props.eqCycle,
      props.cell,
      props.iotVendor,
      props.packVendor
    ).then((res) => {
      if (res.responseStatus.code === 200) {

        setAlertsOverview({
          series: Object.values(res.response.batteryHealth[0]),
          labels: getSOHLevels(Object.keys(res.response.batteryHealth[0])),
          totalLabel: "Total Batteries",
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.iotVendor, props.packVendor, props.partner]);

  useEffect(
    () => () => {
      setVoltData({
        scatter: [],
        batteryId: [],
        data: [],
        xmax: 0,
        xmin: 0,
        ymax: 0,
        ymin: 0,
        responseStatus: { code: -1, msg: "" },
      });
      setAlertsOverview({
        series: [],
        labels: [],
        totalLabel: "Total Batteries",
        responseStatus: { code: -1, msg: "" },
      });
    },
    []
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
          <PaperHighlight
            elevation={0}
            sx={{
              height: "500px",
              pointerEvents:
                voltData.responseStatus.code === -1 ? "none" : "auto",
              opacity: voltData.responseStatus.code === -1 ? 0.7 : 1,
            }}
            onMouseEnter={() => Events("Hovered Fleet Battery Health Distribution")}
          >
            <Header
              heading="Fleet Battery Health Distribution"
              description="Health Quality Distribution"
              showIButton={true}
              showThreeDots={false}
              id="Health_Quality_Distribution"
              page="BatteryHealth"
            />
            <div
              className="section"
              id="Health_Quality_Distribution"
              style={{ height: "400px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="textSubtitle">
                  <b>Selected : </b>
                  {sohLevel !== "" ? sohLevel : "All Batteries"}
                </Typography>
                {sohLevel !== "" && (
                  <Button
                    sx={{ fontSize: "12px", height: "30px", mt: 0, mr: 0.5 }}
                    onClick={() => {
                      handleSohValue("");
                    }}
                  >
                    Reset
                  </Button>
                )}
              </div>
              {alertsOverview.responseStatus.code === 200 ? (
                <div style={{position:"relative",height:"400px",display:"block"}}>
                  <div style={{position:"absolute",top:"36%",display:"flex",justifyContent:"center",width:"100%"}}>
                  <Typography variant="mediumNumberBlack"><b>{findTotalBatteries()}</b></Typography>
                    </div>
                  <div style={{position:"absolute",top:"44%",display:"flex",justifyContent:"center",width:"100%"}}><Typography variant="textSubtitle">{sohLevel !== "" ? sohLevel : "All Batteries"}</Typography></div>
                <ApexBasicDonut
                  totalLabel={""}
                  colors={[
                    ThemeProperties.Purple1,
                    ThemeProperties.Purple2,
                    ThemeProperties.Purple3,
                    ThemeProperties.Purple4,
                    ThemeProperties.Purple5,
                    ThemeProperties.Purple6,
                  ]}
                  showLegend={true}
                  data={alertsOverview}
                  sohLevel={sohLevel}
                  setData={setAlertsOverview}
                  handleSohValue={handleSohValue}
                />
                </div>
              ) : (
                <>
                  {alertsOverview.responseStatus.code === -1 ? (
                    <Loader />
                  ) : (
                    <h1>no data</h1>
                  )}
                </>
              )}
            </div>
          </PaperHighlight>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={8} xl={8}>
          <>
            <PaperHighlight
              elevation={0}
              sx={{ height: "500px" }}
              onMouseEnter={() => Events(`Hovered Batteries SOH across fleet over time`)}
            >
              <Header
                heading="Batteries SOH across fleet over time"
                description="Alerts log over time during the selected calendar period"
                showIButton={true}
                showThreeDots={false}
                showCSVDownload={true}
                csvData ={voltData.csvData}
                id="Distribution_of_batteries"
                page="BatteryHealth"
              />
              <div
                className="section"
                id="Distribution_of_batteries"
                style={{ height: "400px", position: "relative" }}
              >
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      // ml: -1.4,
                      mr: 0,
                      mt: -0.4,
                    }}
                    control={
                      <Switch
                        checked={cycleAge}
                        onChange={handleToggleCycleAge}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="textSubtitle">
                        Show Eq. Cycle
                      </Typography>
                    }
                  />
                </FormGroup>
                {voltData.responseStatus.code === 200 ? (
                  <ApexScatterPlusLine
                    line={[]}
                    scatter={voltData.scatter}
                    batteryID={voltData.batteryId}
                    xmax={voltData.xmax}
                    xmin={voltData.xmin}
                    ymax={voltData.ymax}
                    ymin={voltData.ymin}
                    xAxisTitle={cycleAge ? "Eq. Cycle" : "Age (months)"}
                    xAxisLabelColor={ThemeProperties.c_axis_labels_color}
                    cycleAge={cycleAge}
                    getMarkerColor={getMarkerColor}
                  />
                ) : (
                  <>
                    {voltData.responseStatus.code === -1 ? (
                      <Loader />
                    ) : (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            top: "43%",
                            zIndex: 9,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            //   marginLeft: "12px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#ececec",
                              padding: "10px",
                              borderRadius: "20px",
                            }}
                          >
                            <Typography variant="textSubtitle">
                              No batteries found
                            </Typography>
                          </div>
                        </div>
                        <ApexScatterPlusLine
                          line={[]}
                          scatter={[]}
                          batteryID={[]}
                          xmax={voltData.xmax}
                          xmin={voltData.xmin}
                          ymax={voltData.ymax}
                          ymin={voltData.ymin}
                          xAxisTitle={cycleAge ? "Eq. Cycle" : "Age (months)"}
                          xAxisLabelColor={ThemeProperties.c_axis_labels_color}
                          cycleAge={cycleAge}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </PaperHighlight>
          </>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FleetBatteryHealthTabular
            tableData={voltData}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LandingOverview;
